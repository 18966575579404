import React, { useCallback, useEffect, useState } from 'react';
import { BiEdit, BiTrash } from 'react-icons/bi';
import { toast } from 'react-toastify';
import Loading from '../../../../../../components/Loading';
import checkEmptyString from '../../../../../../helpers/check-empty-string';
import Annotation from '../../../../../../models/annotation';
import {
  getAnnotations as getAllAnnotationsService,
  createAnnotation as createAnnotationService,
  deleteAnnotation as deleteAnnotationService,
  updateAnnotation as updateAnnotationService,
} from '../../../../../../services/annotations';
import {
  Container,
  Form,
  AnnotationList,
  FormTextArea,
  FormTitle,
  Label,
  SubmitButton,
  Annotation as Note,
  AnnotationHeader,
  AnnotationTitle,
  AnnotationDate,
  AnnotationContent,
  AnnotationButtonContainer,
  AnnotationButton,
} from './style';

interface AnnotationsProps {
  contentId: string;
  trailId?: string;
}

const Annotations: React.FC<AnnotationsProps> = ({ contentId, trailId }) => {
  const [newAnnotationTitle, setNewAnnotationTitle] = useState('');
  const [newAnnotationContent, setNewAnnotationContent] = useState('');
  const [annotationsToBeShown, setAnnotationsToBeShown] = useState<
    Annotation[]
  >([]);
  const [idEditingAnnotation, setIdEditingAnnotation] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const clearNewAnnotationData = () => {
    setIdEditingAnnotation('');
    setNewAnnotationTitle('');
    setNewAnnotationContent('');
  };

  const getAllAnnotations = useCallback(async () => {
    setAnnotationsToBeShown([]);
    const annotations = await getAllAnnotationsService({
      content_id: contentId,
    });

    if (annotations && annotations.length) {
      setAnnotationsToBeShown(annotations);
    }
    setIsLoading(false);
  }, [contentId]);

  const createNewAnnotation = async (event: React.FormEvent) => {
    event.preventDefault();

    const newAnnotation = {
      annotation_id: '',
      title: newAnnotationTitle,
      description: newAnnotationContent,
    };

    try {
      if (checkEmptyString(newAnnotationTitle)) {
        throw new Error('Informe o título da anotação');
      }

      if (checkEmptyString(newAnnotationContent)) {
        throw new Error('Informe o título da anotação');
      }
      await createAnnotationService(newAnnotation, contentId);

      toast.success('Anotação criada com sucesso!');

      clearNewAnnotationData();
      await getAllAnnotations();
    } catch (error) {
      toast.error('Não foi possível criar a anotação!');
    }
  };

  const removeAnnotation = async (annotationId: string) => {
    try {
      await deleteAnnotationService(`${annotationId}`);
      setAnnotationsToBeShown(
        annotationsToBeShown.filter(
          annotation => annotationId !== annotation.id,
        ),
      );

      toast.success('Anotação removida com sucesso!');

      clearNewAnnotationData();
      await getAllAnnotations();
    } catch (e) {
      toast.error('Erro ao remover anotação.');
    }
  };

  const editAnnotation = (annotationId: string) => {
    setIdEditingAnnotation(annotationId);

    const annotation = annotationsToBeShown.find(
      annotation => annotationId === annotation.id,
    );

    if (annotation) {
      setNewAnnotationContent(annotation?.description);
      setNewAnnotationTitle(annotation?.title);
    }
  };

  const updateAnnotation = async (event: React.FormEvent) => {
    event.preventDefault();
    const uneditedAnnotation = annotationsToBeShown.find(
      annotation => idEditingAnnotation === annotation.id,
    );

    if (uneditedAnnotation) {
      uneditedAnnotation.title = newAnnotationTitle;
      uneditedAnnotation.description = newAnnotationContent;

      try {
        if (checkEmptyString(newAnnotationTitle)) {
          throw new Error('Informe o título da anotação');
        }

        if (checkEmptyString(newAnnotationContent)) {
          throw new Error('Informe o título da anotação');
        }

        await updateAnnotationService(idEditingAnnotation, uneditedAnnotation);

        toast.success('Anotação atualizada com sucesso!');

        clearNewAnnotationData();
        await getAllAnnotations();
      } catch (error) {
        toast.error('Não foi possível atualizar a anotação!');
      }
    }
  };

  useEffect(() => {
    getAllAnnotations();
  }, [getAllAnnotations]);

  return (
    <Container>
      <Form
        onSubmit={e => {
          if (idEditingAnnotation !== '') {
            updateAnnotation(e);
          } else {
            createNewAnnotation(e);
          }
        }}
      >
        <FormTitle
          type="text"
          value={newAnnotationTitle}
          onChange={e => setNewAnnotationTitle(e.target.value)}
          placeholder="Titulo"
          required={true}
        />

        <FormTextArea
          value={newAnnotationContent}
          onChange={e => setNewAnnotationContent(e.target.value)}
          placeholder="Escreva aqui..."
          required={true}
        ></FormTextArea>

        <SubmitButton type="submit">
          {idEditingAnnotation ? 'Atualizar Nota' : 'Criar Nota'}
        </SubmitButton>
      </Form>

      <AnnotationList>
        {!idEditingAnnotation &&
          (isLoading ? (
            <Loading />
          ) : (
            annotationsToBeShown.map((annotation, index) => (
              <Note key={index}>
                <AnnotationHeader>
                  <AnnotationTitle>{annotation.title}</AnnotationTitle>

                  <AnnotationButtonContainer>
                    <AnnotationButton
                      onClick={() => {
                        editAnnotation(annotation.id);
                      }}
                    >
                      <BiEdit size={20} color="#444" />
                    </AnnotationButton>

                    <AnnotationButton
                      onClick={() => {
                        removeAnnotation(annotation.id);
                      }}
                    >
                      <BiTrash size={20} color="#444" />
                    </AnnotationButton>
                  </AnnotationButtonContainer>
                </AnnotationHeader>

                <AnnotationDate>
                  {new Intl.DateTimeFormat('pt-BR').format(annotation.date)}
                </AnnotationDate>

                <AnnotationContent>{annotation.description}</AnnotationContent>
              </Note>
            ))
          ))}
      </AnnotationList>
    </Container>
  );
};

export default Annotations;
