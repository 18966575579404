import httpClient from '../../http-client';

export interface TimeWatched {
  hours_watched: number;
}

const getActualUser = async () => {
  const actualUser = (await httpClient.get('/users/me')).data;

  return actualUser;
};

const getWatchTime = async (): Promise<TimeWatched> => {
  const watchTime = (await httpClient.get(`users/watch-time`)).data;

  return watchTime;
};

const acceptTerms = async (accepted: boolean) => {
  const result = (
    await httpClient.patch('/users/accept-conditions', {
      accept: accepted,
    })
  ).data;

  return result;
};

export { getActualUser, getWatchTime, acceptTerms };
