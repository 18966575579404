import styled from 'styled-components';

export const Container = styled.footer`
  height: 100px;
  width: 100vw;
  background-color: var(--primary-color);

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  font-size: var(--font-regular);
  line-height: 24px;
  color: var(--white-font-color);

  a {
    text-decoration: none;
    font-weight: 700;
    color: var(--white-font-color);

    & + a {
      margin-left: 38px;
    }

    img {
      width: 120px;
    }
  }

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    font-size: var(--font-min);
    line-height: 18px;
    padding: 20px 0px 12px 0px;

    span {
      max-width: 150px;
      margin-top: 16px;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    a {
      margin-top: 8px;

      & + a {
        margin-left: 0px;
      }

      img {
        width: 82px;
      }
    }
  }
`;
