import convertContent from '../../converters/convert-content';
import httpClient from '../../http-client';
import Content from '../../models/content';
import ContentFromResponse from '../../models/from-api/content';

const getContents = async (filters?: Object): Promise<Content[]> => {
  const contents = (
    await httpClient.get<ContentFromResponse[]>('/contents', {
      params: filters,
    })
  ).data;

  let convertedContents = [] as Content[];
  if (contents && contents.length) {
    convertedContents = contents
      .filter(content => content.is_active)
      .map(content => convertContent(content));
  }

  return convertedContents;
};

const getContent = async (contentId: string): Promise<Content> => {
  const content = (await httpClient.get<any>(`/contents/${contentId}`)).data;

  let convertedContent = {} as Content;

  if (content && content.content.content_id) {
    if (content.user) {
      convertedContent = convertContent(content.content, content.user);
    } else {
      convertedContent = convertContent(content.content);
    }
  }
  return convertedContent;
};

const startContent = async (
  contentId: string,
  courseId?: string,
  trailId?: string,
) => {
  if (!!contentId && !trailId && !courseId) {
    await httpClient.post(`/contents-user`, { content_id: contentId });
  } else {
    await httpClient.post(`/trail-course-content-users`, {
      trail_id: trailId,
      course_id: courseId,
      content_id: contentId,
    });
  }
};

const finishContent = async (
  contentId: string,
  courseId?: string,
  trailId?: string,
) => {
  if (!!contentId && !trailId && !courseId) {
    await httpClient.put(`/contents-user/${contentId}/finish`);
  } else {
    await httpClient.put(`/trail-course-content-users/finish`, {
      trail_id: trailId,
      course_id: courseId,
      content_id: contentId,
    });
  }
};

const updateContentProgress = async (
  contentId: string,
  progress: number | null,
) => {
  if ((progress || 0) <= 0) {
    return {};
  }

  return (
    await httpClient.patch(`/contents-user/${contentId}`, {
      content_view: Math.floor(progress || 0),
    })
  ).data;
};

export {
  getContents,
  getContent,
  startContent,
  finishContent,
  updateContentProgress,
};
