import { IUserReviews } from "../services/content-review";
import { ITrailUserReviews } from "../services/trail-review";

const average = (reviews: any[], totalReviwes: number) => {
  const result = reviews.reduce((acc: number, review: IUserReviews | ITrailUserReviews) => {
    return review.stars !== null && acc + review.stars;
  }, 0);

  return result / totalReviwes;
}

const evaluationGrade = (averageOfStarts: number) => {
  if (averageOfStarts === null || averageOfStarts <= 0 ) {
    return 0;
  }

  if (averageOfStarts !== null && averageOfStarts <= 0.69) {
    return 10;
  }

  if (averageOfStarts !== null && averageOfStarts <= 1.30) {
    return 20;
  }

  if (averageOfStarts !== null && averageOfStarts <= 1.69) {
    return 30;
  }

  if (averageOfStarts !== null && averageOfStarts <= 2.30) {
    return 40;
  }

  if (averageOfStarts !== null && averageOfStarts <= 2.69) {
    return 50;
  }
  
  if (averageOfStarts !== null && averageOfStarts <= 3.30) {
    return 60;
  }

  if (averageOfStarts !== null && averageOfStarts <= 3.69) {
    return 70;
  }

  if (averageOfStarts !== null && averageOfStarts <= 4.30) {
    return 80;
  }

  if (averageOfStarts !== null && averageOfStarts <= 4.69) {
    return 90;
  }

  if (averageOfStarts !== null && averageOfStarts <= 5.00) {
    return 100;
  }    
}

export { average, evaluationGrade };
