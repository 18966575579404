import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import { Container } from './style';

import { forgotPasswordConfirm as forgotPasswordConfirmService } from '../../../../services/forgot-password';
import HeaderLogo from '../../../../assets/logo.svg';
import InputPassword from '../../../../components/Inputs/InputPassword';
import DefaultButton from '../../../../components/Buttons/DefaultButton';
import AuthBackButton from '../../../../components/Buttons/AuthBackButton';
import DefaultInput from '../../../../components/Inputs/DefaultInput/DefaultInput';

import { resendCode as resendCodeService } from '../../../../services/verifyCode';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../../helpers/getValidationErrors';

const schema = Yup.object().shape({
  code: Yup.string().trim().required('Preencha corretamente este campo.'),
  password: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.')
    .min(8, 'A senha deve conter no mínimo 8 caracteres.'),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'As senhas informadas não correspondem. Por favor, verifique.',
    )
    .trim()
    .required('Preencha corretamente este campo.'),
});

interface ILocationProps {
  registration: string;
  cell: string;
}

interface IFormData {
  code: string;
  registration: string;
  password: string;
}

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const { state } = useLocation<ILocationProps>();
  const { registration, cell } = state;

  const resendCode = async () => {
    const formattedPhone = cell
      .replaceAll('-', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(' ', '');
    await resendCodeService({ phone_number: `+55${formattedPhone}` });
  };

  const handleSubmit = async (data: IFormData) => {
    const { code, password } = data;

    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await forgotPasswordConfirmService({
        code,
        registration,
        new_password: password,
      }).then(() => {
        history.push('/signIn');
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <Container>
      <AuthBackButton />
      <img src={HeaderLogo} alt="logo" />

      <h3>Redefinir a senha</h3>

      <p>
        Um código foi enviado para <span>{cell ? cell : ''}</span>
      </p>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <DefaultInput
          name="code"
          type="text"
          label="Digite o código recebido"
          required
        />
        <InputPassword name="password" label="Nova senha" />
        <InputPassword name="passwordConfirmation" label="Confirmar senha" />

        <p className="resend">
          Não recebeu?{' '}
          <button type="button" onClick={resendCode}>
            Reenviar código
          </button>
        </p>

        <DefaultButton type="submit">Cadastrar</DefaultButton>
      </Form>
    </Container>
  );
};

export default ResetPassword;
