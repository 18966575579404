const getErrorMessage = (error: any) => {
  return (
    (error &&
      error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message
  );
};

export default getErrorMessage;
