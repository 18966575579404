import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';
import { IoIosArrowBack } from 'react-icons/io';

import Answer from './components/Answer';

import { IAnswer, IQuestion } from '../../services/forum/models';
import {
  createAnswer,
  getQuestionAnswers,
  getQuestionData as getQuestionDataService,
  createCommentary,
} from '../../services/forum';

import {
  ForumPageWrapper,
  ForumPageHeader,
  PostAnswerList,
  ForumPostFooterWrapper,
  NoAnswerWrapper,
  TextareaWrapper,
  DeletedTopic,
} from './style';
import ContentLoader from 'react-content-loader';
import { toast } from 'react-toastify';

interface QuestionParams {
  questionId: string;
}

const ForumTopic = () => {
  const history = useHistory();
  const [questionData, setQuestionData] = useState<IQuestion>();
  const [answerList, setAnswerList] = useState<IAnswer[]>([]);
  const [orderBy, setOrderBy] = useState<'new' | 'best'>('new');
  const [page, setPage] = useState<number>(1);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(true);
  const [response, setResponse] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(true);

  const { questionId } = useParams() as QuestionParams;

  const { description, title, created_at, categories, user } =
    questionData || {};

  const fetchQuestionData = async (questionId: string) => {
    await getQuestionDataService(questionId).then((response: any) => {
      setQuestionData(response);
    });
  };

  const fetchAnswerData = async (questionId: string, page: number) => {
    try {
      await getQuestionAnswers(questionId, page, orderBy).then(
        (response: any) => {
          setCanLoadMore(response.length === 10);
          setLoading(false);
          if (page === 1) {
            setAnswerList([...response]);
            return;
          }
          setAnswerList([...answerList, ...response]);
        },
      );
    } catch (e) {
      setLoading(false);
    }
  };

  const handleChangeResponse = (e: any) => {
    setResponse(e.target.value);
  };

  const submitResponse = async (e: any) => {
    e.preventDefault();

    if (response.replace(/\s/g, '').length <= 3) {
      toast.error('Preencha sua resposta');
      return;
    }

    await createAnswer(questionId, response).then(() =>
      fetchAnswerData(questionId, page),
    );
    setResponse('');
  };

  const submitCommentary = async (id: string, answer: string) => {
    await createCommentary(id, answer).then(() => {
      setPage(1);
      fetchAnswerData(questionId, 1);
    });
  };

  const loadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    fetchQuestionData(questionId);
  }, []);

  useEffect(() => {
    if (page >= 1) {
      fetchAnswerData(questionId, page);
    }
  }, [page]);

  useEffect(() => {
    setPage(1);
    fetchAnswerData(questionId, 1);
  }, [orderBy]);

  const formattedDate = useMemo(() => {
    if (!created_at) {
      return '';
    }

    const date = new Intl.DateTimeFormat('pt-BR').format(new Date(created_at));
    const hour = new Intl.DateTimeFormat('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(created_at));

    return `${date}, ${hour}`;
  }, [created_at]);

  const PostDataLoader = () => {
    return (
      <ContentLoader width={'100%'} height={200} backgroundColor="#e3e3e3">
        <rect rx="0" ry="0" y="0" width="128" height="16" />
        <rect rx="0" ry="0" y="24" width="90%" height="16" />
        <rect rx="0" ry="0" y="48" width="90%" height="16" />
        <rect rx="0" ry="0" y="72" width="90%" height="16" />
        <rect rx="0" ry="0" y="96" width="90%" height="16" />
      </ContentLoader>
    );
  };
  const PostAnswerLoader = () => {
    return (
      <ContentLoader width={'100%'} height={340} backgroundColor="#e3e3e3">
        <rect rx="0" ry="0" y="0" width="100%" height="160" />
        <rect rx="0" ry="0" y="170" width="100%" height="160" />
      </ContentLoader>
    );
  };

  return (
    <ForumPageWrapper>
      <div className="content">
        {isLoading ? (
          <PostDataLoader />
        ) : questionData ? (
          <>
            <ForumPageHeader>
              <div className="buttons-container">
                <IoIosArrowBack
                  size={36}
                  color="#444"
                  onClick={() => history.goBack()}
                />
              </div>
              <div className="postData">
                <div className="title">
                  <h1>{title}</h1>

                  {!!categories && categories.length >= 1 && (
                    <span className="postTag">
                      {categories[0] && categories[0].description}
                    </span>
                  )}
                </div>

                <p>{description || ''} </p>
              </div>
              <div className="authorData">
                <BiUserCircle size={50} className="authorData__icon" />
                <div className="info">
                  <span className="info__name">{!!user ? user.name : ''}</span>
                  <span className="info__date">{formattedDate}</span>
                </div>
              </div>
              <div className="orderBy">
                <span>Ordenar por:</span>
                <button
                  onClick={() => setOrderBy('new')}
                  className={`action ${orderBy === 'new' ? 'selected' : ''}`}
                >
                  Respostas mais novas
                </button>
                <button
                  onClick={() => setOrderBy('best')}
                  className={`action ${orderBy === 'best' ? 'selected' : ''}`}
                >
                  Melhores respostas
                </button>
              </div>
            </ForumPageHeader>
            <PostAnswerList>
              {answerList.length >= 1 ? (
                answerList?.map((answer: IAnswer) => (
                  <Answer
                    key={answer.answer_id}
                    answerData={answer}
                    submitCommentary={submitCommentary}
                  />
                ))
              ) : (
                <NoAnswerWrapper>
                  <span>
                    Essa pergunta ainda não tem nenhuma resposta. Seja o
                    primeiro a responder!
                  </span>
                </NoAnswerWrapper>
              )}
            </PostAnswerList>
            <ForumPostFooterWrapper>
              {canLoadMore && (
                <span className="loadMore" onClick={loadMore}>
                  Carregar Mais
                </span>
              )}
            </ForumPostFooterWrapper>
            <TextareaWrapper onSubmit={submitResponse}>
              <textarea
                name="description"
                value={response}
                onChange={handleChangeResponse}
                required={true}
              />
              <button>Responder</button>
            </TextareaWrapper>
          </>
        ) : (
          <DeletedTopic>
            <h3>Este tópico foi excluído e não se encontra mais disponível.</h3>
            <button onClick={() => history.goBack()}>
              Voltar para o Fórum
            </button>
          </DeletedTopic>
        )}
      </div>
    </ForumPageWrapper>
  );
};

export default ForumTopic;
