import styled from 'styled-components';

export const Container = styled.form`
  width: 390px;
  height: 44px;
  display: flex;
  align-items: center;
  background: #f2f2f2;
  border: 1px solid #e6e6e6;
  border-radius: 50px;
  padding-right: 18px;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    background: transparent;
    padding: 16px 20px;
    font-size: var(--font-large);
    font-weight: 400;
  }

  input:-webkit-autofill {
    border-radius: 50px;
    -webkit-box-shadow: 0 0 0 30px #f2f2f2 inset !important;
  }

  button {
    cursor: pointer;
    height: 100%;
    border: none;
    outline: none;
    background: none;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
