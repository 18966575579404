import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const CourseContentContainer = styled.div`
  margin-bottom: 20px;

  max-width: 800px;
  .upper-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 768px) {
      padding: 0 4px;
    }

    .category {
      text-transform: capitalize;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 16px;
      color: var(--primary-color);
    }

    .title {
      font-size: var(--font-max);
      margin-bottom: 16px;
    }
  }

  .about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--regular-gray);
    margin-bottom: 20px;
    font-size: var(--font-default);

    @media (max-width: 768px) {
      padding: 0 4px;
    }

    div {
      display: flex;
    }

    svg {
      margin-bottom: 4px;
    }

    span,
    button {
      display: flex;
      align-items: center;

      *:not(*:first-child) {
        margin-left: 5px;
      }
    }

    .duration {
      svg {
        margin-right: 4px;
      }
    }

    .liked {
      color: var(--primary-color);
    }

    .likes {
      margin-left: 15px;

      button {
        cursor: pointer;
        border: none;
        background: none;
      }
    }

    .add-to-list {
      background-color: var(--light-gray);
      border: none;
      outline: none;
      border-radius: 5px;
      padding: 10px 10px;
      cursor: pointer;

      svg {
        margin-right: 8px;
      }

      span {
        font-weight: 500;
        color: var(--regular-gray);

        &.remove-to-list {
          color: #000;
        }
      }
    }

    .add-to-list.added-to-list {
      background-color: var(--secondary-color);
      color: white;
    }
  }

  .lesson-content {
    .lesson-video-container {
      background-color: rgba(0, 0, 0, 0.8);

      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 550px) {
        margin: 0 auto;
      }

      &,
      iframe {
        outline: none;
        border: none;
        width: 800px;
        height: 450px;

        @media (max-width: 1200px) {
          width: 800px;
          height: 450px;
        }

        @media (max-width: 800px) {
          width: 550px;
          height: 300px;
        }

        @media (max-width: 550px) {
          width: 350px;
          height: 200px;
        }
      }

      .player-icon-container {
        position: relative;

        .player-icon::after {
          content: '';
          position: absolute;
          top: calc(50% - 10px);
          left: 0;
          border-top: 15px solid #fff;
          width: 100%;
          transform: rotate(45deg);
        }

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .lesson-title {
      color: var(--default-font-color);
      font-size: var(--font-larger);
      margin: 10px 0;
    }

    .lesson-content-tabs {
      margin-top: 48px;

      .content-tab-contents {
        padding: 10px 5px;
        color: #253858;
        font-size: var(--font-medium);
        min-height: 100px;

        @media (max-width: 1200px) {
          min-height: auto;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    max-width: 100vw;
    margin-bottom: 0px;

    .upper-content {
      .category {
        color: var(--primary-color);
        font-size: var(--font-small);
      }

      .title {
        font-size: var(--font-default);
        margin-bottom: 16px;
      }
    }

    .about-content {
      font-size: var(--font-smallest);

      svg {
        width: 14px;
        height: 14px;
      }

      .likes {
        margin-left: 24px;
      }

      .add-to-list {
        svg {
          margin-right: 0px;
        }

        span {
          display: none;
        }
      }
    }
  }
`;

export const TabLinksContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;

  button {
    border: none;
    outline: none;
    padding: 10px 15px;
    cursor: pointer;
    background-color: transparent;
    color: var(--default-font-color);
    margin-right: 15px;

    &.modules-tab-link {
      display: none;
    }
  }

  button.active {
    font-weight: 500;
    border-bottom: solid 3px var(--primary-color);
  }

  @media (max-width: 1200px) {
    button.modules-tab-link {
      display: unset;
    }
  }
`;

export const DepositionsContent = styled.div`
  margin-top: 22px;
  background: var(--primary-color);
  color: #fff;
  padding: 32px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  strong {
    font-size: 20px;
    margin-bottom: 8px;
    text-align: center;
  }

  span {
    font-size: 18px;
    margin-top: 16px;
    display: block;
  }

  div.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  button {
    cursor: pointer;
    border: none;
    background: var(--secondary-color);
    font-size: 18px;
    font-weight: 500;
    padding: 18px 0;
    border-radius: 6px;
    transition: 0.2s;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }

    & + button {
      width: unset;
      background: none;

      color: #fff;
      margin-top: 12px;
    }

    &:hover {
      filter: opacity(0.9);
    }
  }

  @media (max-width: 780px) {
    width: 100%;
    flex-direction: column;
    padding: 16px;

    button {
      margin-top: 24px;
      font-size: 16px;
    }

    strong {
      font-size: 16px;
    }

    span {
      font-size: 16px;
    }
  }
`;
