import convertTrail from '../../converters/convert-trail';
import httpClient from '../../http-client';
import CourseUserFromResponse from '../../models/from-api/course-user';
import OngoingTrail from '../../models/from-api/ongoing-trail';
import TrailFromResponse from '../../models/from-api/trail';
import Trail from '../../models/trail';

const getTrails = async (filters?: Object): Promise<Trail[]> => {
  const trails = (
    await httpClient.get<TrailFromResponse[]>('/trails', { params: filters })
  ).data;

  let convertedTrails = [] as Trail[];
  if (trails && trails.length) {
    convertedTrails = trails
      .filter(trail => trail.is_active)
      .map(trail => convertTrail(trail));
  }

  return convertedTrails;
};

const getTrail = async (trailId: string): Promise<Trail> => {
  const trailContainer = (await httpClient.get<any>(`/trails/${trailId}`)).data;

  let courses: CourseUserFromResponse[] = [];

  if (
    trailContainer.user &&
    trailContainer.user.courses &&
    trailContainer.user.courses.length
  ) {
    trailContainer.user.courses.map((course: CourseUserFromResponse) => {
      if (course !== null) {
        courses.push(course);
      }
    });
  }

  let convertedTrail = {} as Trail;
  if (trailContainer && trailContainer.trail) {
    convertedTrail = convertTrail(
      trailContainer.trail,
      trailContainer.user,
      courses,
    );
  }

  return convertedTrail;
};

const getOngoingTrails = async (filters?: object) => {
  const trails = (
    await httpClient.get<OngoingTrail[]>('/trail-users/me/in-progress', {
      params: filters,
    })
  ).data;

  return trails;
};

const startTrail = async (trailId: string) => {
  await httpClient.post(`/trail-users`, { trail_id: trailId });
};

const finishTrail = async (trailId: string) => {
  await httpClient.put(`/trail-users/finish`, { trail_id: trailId });
};

const addTrailToList = async (trailId: string, add: boolean) => {
  if (add) {
    await httpClient.post(`/book-marks`, { trail_id: trailId });
  } else {
    await httpClient.delete(`/book-marks/${trailId}`);
  }
};

const updateContentProgress = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
  content_view: number | null,
) => {
  if (!courseId || !moduleId || !lessonId || (content_view || 0) <= 0) {
    return {};
  }

  return (
    await httpClient.patch(`/trail-course-content-users/content-view`, {
      trail_id: courseId,
      course_id: moduleId,
      content_id: lessonId,
      content_view: Math.floor(content_view || 0),
    })
  ).data;
};

export {
  getTrails,
  getTrail,
  getOngoingTrails,
  startTrail,
  finishTrail,
  addTrailToList,
  updateContentProgress,
};
