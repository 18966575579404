import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './style';

const PreFooter: React.FC = () => {
  return (
    <Container>
      <h1>Tem dúvidas ou precisa de ajuda?</h1>

      <Link to="/faq">Confira as Perguntas Frequentes.</Link>
    </Container>
  );
};

export default PreFooter;
