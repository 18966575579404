import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  margin: 58px 0 auto;

  div.shimmer {
    margin-top: 32px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 980px;
  }

  h2 {
    font-size: 32px;
  }

  div.dropdown {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 42px;

    header {
      width: 100%;
    }

    h2 {
      display: none;
    }

    aside {
      display: none;
    }

    > div {
      width: 100%;
    }

    div.dropdown {
      display: block;
    }
  }
`;

export const OngoingList = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 32px 32px 64px 0;
  }

  @media (max-width: 768px) {
    column-gap: 32px;
    justify-content: center;
    margin-top: 32px;

    > div {
      margin: 0 0 32px 0;
    }
  }
`;

export const SelectContainer = styled.div`
  position: relative;

  select {
    cursor: pointer;
    background-color: #e6e6e6;
    padding: 12px;
    width: 250px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    outline: none;

    &:-ms-expand {
      display: none;
    }
  }

  svg {
    position: absolute;
    top: 10px;
    right: 12px;
  }
`;
