import httpClient from '../../http-client';

const likeCourse = async (courseId: string) => {
  await httpClient.post(`/content-user-likes/`, { content_id: courseId });
};

const dislikeCourse = async (courseId: string) => {
  await httpClient.delete(`/content-user-likes/${courseId}`);
};

const addLike = async (trailId: string) => {
  const addLikeResponse = (
    await httpClient.post(`/trail-users/like`, { trail_id: trailId })
  ).data;
  return addLikeResponse;
};

const removeLike = async (trailId: string) => {
  const removeLikeResponse = (
    await httpClient.delete(`/trail-users/${trailId}/like`)
  ).data;
  return removeLikeResponse;
};

export { likeCourse, dislikeCourse, addLike, removeLike };
