import React from 'react';
import { useHistory } from 'react-router-dom';

import Logo from '../../assets/logo-white.svg';
import Footer from '../../components/Footer';

import {
  Container,
  Content,
  BrandContent,
  OptionsContent,
  Title,
  Description,
} from './style';

const Initial: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Content>
        <BrandContent>
          <img src={Logo} alt="Logo" />
        </BrandContent>

        <OptionsContent>
          <Title>Que bom te ver aqui!</Title>
          <Description>
            Bem-vindo (a) ao Portal Construir, a Plataforma de Treinamentos
            Telhanorte Tumelero e Obra Já.
          </Description>

          <button type="button" onClick={() => history.push('/signIn')}>
            JÁ TENHO CONTA
          </button>
          <button type="button" onClick={() => history.push('/signUp')}>
            É MEU PRIMEIRO ACESSO
          </button>
        </OptionsContent>
      </Content>

      <Footer />
    </Container>
  );
};

export default Initial;
