import styled from 'styled-components';

export const SelectContainer = styled.div`
  width: 100%;
  cursor: pointer;
  margin-top: 8px;
  z-index: 999;

  @media (min-width: 769px) {
    display: none;
  }

  header {
    height: 48px;
    background: #e6e6e6;
    border: 1px solid var(--light-gray);
    border-radius: 6px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    &.open {
      border-radius: 6px 6px 0 0;
    }
  }
`;

export const CategoriesContent = styled.div`
  margin: 0 auto;
  border: 1px solid var(--default-gray);
  border-radius: 0 0 8px 8px;
  button {
    cursor: pointer;
    width: 100%;
    display: block;
    text-align: left;
    font-size: var(--font-regular);
    color: var(--dark-gray);
    line-height: 22px;
    padding: 12px 32px;
    transition: all 0.2s;
    border: none;
    background: none;
    &:first-child {
      margin-top: 12px;
    }
    &:hover,
    &.active {
      background: rgba(84, 0, 110, 0.2);
      font-weight: bold;
    }
  }
`;
