import React from 'react';
import { useHistory } from 'react-router-dom';
import { FiChevronLeft } from 'react-icons/fi';
import { Container } from './style';

const AuthBackButton: React.FC = () => {
  const history = useHistory();

  return (
    <Container type="button" onClick={() => history.goBack()}>
      <FiChevronLeft color="#FFF" />
    </Container>
  );
};

export default AuthBackButton;
