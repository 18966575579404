import React, { useRef, useState } from 'react';
import { BiChevronUp } from 'react-icons/bi';
import { toast } from 'react-toastify';
import DefaultButton from '../Buttons/DefaultButton';
import User from '../../models/user';
import { uploadFile } from '../../services/files';
import { uploadUserFile } from '../../services/user-file';
import {
  FileUploadCollapseIndicatorContainer,
  FileUploadContainer,
  FileUploadLowerContent,
  FileUploadUpperContent,
} from './style';

interface IFileUpload {
  reference_id: string;
  reference_type: 'content' | 'trail';
}

const FileUpload: React.FC<IFileUpload> = ({
  reference_id,
  reference_type,
}) => {
  const [opened, setOpened] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const file = (fileInput?.current?.files || [])[0];

    if (!file) {
      toast.error('Selecione um arquivo.');
    }
    if (fileInput.current) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.doc|\.docx|\.pdf)$/i;
      const fileType = fileInput.current.value;
      if (!allowedExtensions.exec(fileType)) {
        toast.error(
          'Somente arquivos .jpg, .jpeg, .png, .doc, .docx e .pdf são permitidos',
        );
      } else {
        const data = new FormData();
        data.append('file', file);
        data.append('reference_id', reference_id);
        data.append('reference_type', reference_type);
        data.append('name', file.name);
        setLoading(true);
        try {
          await uploadUserFile(data);
          toast.success('Arquivo enviado com sucesso!');
          window.location.reload();
          setLoading(false);
        } catch (err) {
          toast.error(
            'Houve um erro ao enviar o arquivo. Por favor, tente novamente!',
          );
          setLoading(false);
        }
      }
    }
  };

  return (
    <FileUploadContainer>
      <FileUploadUpperContent onClick={() => setOpened(!opened)}>
        <strong>Envio de arquivos</strong>
        <FileUploadCollapseIndicatorContainer
          className={`${opened ? 'opened' : ''}`}
        >
          <BiChevronUp size={26} />
        </FileUploadCollapseIndicatorContainer>
      </FileUploadUpperContent>
      {opened && (
        <FileUploadLowerContent>
          <span>Envie o arquivo para avaliação.</span>
          <span>
            Certifique-se que o arquivo a ser enviado está no formato .doc,
            .pdf, .png ou .jpge.
          </span>
          <form onSubmit={handleSubmit}>
            <input
              type="file"
              ref={fileInput}
              accept=".doc, .docx, .pdf, .jpg, .jpeg, .png"
            />
            {!loading ? (
              <DefaultButton>Enviar</DefaultButton>
            ) : (
              <DefaultButton disabled>Enviando...</DefaultButton>
            )}
          </form>
        </FileUploadLowerContent>
      )}
    </FileUploadContainer>
  );
};

export default FileUpload;
