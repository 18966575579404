import Content from '../models/content';
import ContentFromResponse from '../models/from-api/content';
import ContentUserFromResponse from '../models/from-api/content-user';
import CourseFromResponse from '../models/from-api/course';
import TrailFromResponse from '../models/from-api/trail';
import convertExtraMaterial from './convert-extra-material';

const convertContent = (
  content: ContentFromResponse,
  contentUser?: ContentUserFromResponse,
  course?: CourseFromResponse,
  trail?: TrailFromResponse,
): Content => {
  return {
    id: content.content_id,
    info: content.info,
    trailId: trail && trail.trail_id ? trail.trail_id : '',
    courseId: course && course.course_id ? course.course_id : '',
    title: content.title,
    addedToList: content.book_mark,
    authorName:
      content.authors && content.authors.length
        ? content.authors.map(author => author.name).join(', ')
        : '',
    categoryName:
      content.categories && content.categories.length
        ? content.categories.map(cat => cat.title).join(', ')
        : '',
    contentUrl: content.reference,
    description: content.description,
    duration: content.duration,
    finish_at: content.finish_at,
    extraMaterials:
      content.extra_materials && content.extra_materials.length
        ? content.extra_materials.map(convertExtraMaterial)
        : [],
    extraMaterial:
      content.extra_material && content.extra_material.length
        ? content.extra_material.map(convertExtraMaterial)
        : [],
    numberOfLikes: (content.likes && content.likes.count) || 0,
    position: content.position,
    thumbnail: content.thumbnail,
    exam: content.exam ? content.exam : undefined,
    userLiked: (content.likes && content.likes.user_like) || false,
    alreadyStarted: contentUser ? !!contentUser.start_at : false,
    alreadyFinished: contentUser ? !!contentUser.finish_at : false,
    review:
      contentUser &&
      contentUser.review &&
      Object.keys(contentUser.review).length
        ? !!contentUser.review
        : false,
    isNew:
      new Date(content.created_at) &&
      (new Date().getTime() - new Date(content.created_at).getTime()) /
        (1000 * 3600 * 24) <
        10,
    isFirstOfCourse:
      course && course.contents && course.contents.length
        ? course.contents[0].content_id === content.content_id
        : false,
    isLastOfCourse:
      course && course.contents && course.contents.length
        ? course.contents[course.contents.length - 1].content_id ===
          content.content_id
        : false,
    contentView: contentUser && contentUser.content_view,
    type: content.type,
    startDate: content.created_at ? new Date(content.created_at) : new Date(),
    fileType:
      content.info && content.info.file_extension
        ? content.info.file_extension
        : '',
    deadline: content.deadline,
  };
};

export default convertContent;
