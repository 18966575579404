import React, { createContext, useCallback, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import convertContent from '../converters/convert-content';
import convertTrail from '../converters/convert-trail';
import Content from '../models/content';
import ContentFromResponse from '../models/from-api/content';
import CourseFromResponse from '../models/from-api/course';
import TrailFromResponse from '../models/from-api/trail';
import Trail from '../models/trail';

import { searchCourses } from '../services/course';
import Course from '../services/course/models/course-interface';

interface ISeachProviderProps {
  searchParam: string;
  setSearchParam: React.Dispatch<React.SetStateAction<string>>;
  searchResults: Course[];
  isSearching: boolean;
  trails?: Trail[];
  courses?: Content[];
}

export const SearchContext = createContext<ISeachProviderProps>(
  {} as ISeachProviderProps,
);

export const SearchProvider: React.FC = ({ children }) => {
  const [searchParam, setSearchParam] = useState('');
  const [searchResults, setSearchResults] = useState<Course[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [trails, setTrails] = useState();
  const [courses, setCourses] = useState();

  const searchRequest = useCallback(async () => {
    try {
      setIsSearching(true);

      const response = await searchCourses(searchParam);

      const convertedTrails = response.trails.map((trail: TrailFromResponse) =>
        convertTrail(trail),
      );
      const convertedCourses = response.contents.map(
        (course: ContentFromResponse) => convertContent(course),
      );

      setTrails(convertedTrails);
      setCourses(convertedCourses);
    } catch (e) {}

    setIsSearching(false);
  }, [searchParam]);

  useEffect(() => {
    if (searchParam !== '') {
      searchRequest();
    }
  }, [searchParam, searchRequest]);

  return (
    <SearchContext.Provider
      value={{
        searchParam,
        setSearchParam,
        searchResults,
        isSearching,
        trails,
        courses,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};
