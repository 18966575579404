import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;

  @media (max-width: 768px) {
    footer {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 80vw;
  margin: 0 auto;
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 768px) {
    width: 100vw;
    height: auto;
    flex-direction: column;
    padding-bottom: 63px;
  }
`;

export const BrandContent = styled.div`
  width: 50%;
  height: 84%;
  background: var(--primary-color);

  img {
    width: 228px;
    height: 95px;
  }

  @media (max-width: 768px) {
    margin-bottom: 55px;
    width: 100%;
    height: auto;
    padding: 36px;

    img {
      width: 164px;
      height: 68px;
    }
  }
`;

export const OptionsContent = styled.div`
  height: 85%;
  width: 50%;
  flex-direction: column;
  margin-left: 80px;

  button {
    cursor: pointer;
    width: 90%;
    font-weight: bold;
    font-size: var(--font-large);
    line-height: 26px;
    padding: 32px;
    border: 1px solid var(--medium-gray);
    background: none;

    & + button {
      margin-top: 48px;
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    margin-left: 0;

    button {
      font-size: var(--font-default);

      & + button {
        margin-top: 32px;
      }
    }
  }
`;

export const Title = styled.h1`
  font-size: var(--font-title);
  color: var(--primary-color);

  @media (max-width: 768px) {
    font-size: var(--font-larger);
  }
`;

export const Description = styled.p`
  max-width: 390px;
  font-size: var(-font-large);
  line-height: 28px;
  color: var(--dark-gray);
  margin: 8px 0px 32px;
  align-self: center;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 290px;
  }
`;
