import React, { useCallback, useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';

import useSearch from '../../hooks/useSearch';
import CourseCardItem from '../../components/CourseCardItem';

import {
  Container,
  SearchResultTitle,
  SearchResultsList,
  ShimmerContent,
} from './style';
import GridShimmer from '../../components/GridShimmer';
import Trail from '../../models/trail';
import TrailCard from '../../components/TrailCard';

const Search: React.FC = () => {
  const {
    searchResults,
    searchParam,
    isSearching,
    courses,
    trails,
  } = useSearch();

  const getAllResults = useMemo(() => {
    let trailsCount = 0;
    let coursesCount = 0;

    if (trails?.length) {
      trailsCount = trails.length;
    } else {
      trailsCount = 0;
    }

    if (courses?.length) {
      coursesCount = courses.length;
    } else {
      coursesCount = 0;
    }

    return trailsCount + coursesCount;
  }, [trails, courses]);

  return (
    <Container className="content">
      <SearchResultTitle>
        <FiSearch color="#54006e" />
        <p>
          Sua busca por <span>{`"${searchParam}"`}</span>{' '}
          {getAllResults > 0
            ? `retornou ${getAllResults} resultado${
                getAllResults > 1 ? 's' : ''
              }:`
            : 'não retornou resultado.'}
        </p>
      </SearchResultTitle>

      {isSearching ? (
        <ShimmerContent>
          <GridShimmer />
        </ShimmerContent>
      ) : (
        <>
          {searchResults ? (
            <SearchResultsList>
              <div>
                {trails &&
                  trails.map(trail => (
                    <TrailCard key={trail.id} trail={trail} />
                  ))}
              </div>

              <div>
                {courses &&
                  courses.map(course => (
                    <CourseCardItem key={course.id} course={course} />
                  ))}
              </div>
            </SearchResultsList>
          ) : (
            <div />
          )}
        </>
      )}
    </Container>
  );
};

export default Search;
