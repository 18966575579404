import styled from 'styled-components';
import { Form } from '@unform/web';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  margin-top: 42px;

  h3 {
    font-size: 24px;
    margin-bottom: 40px;
  }

  div.userDataInput {
    border: 1px solid var(--regular-gray);
    background: var(--backgroundWhite);
  }

  div.readOnly {
    background: #f2f2f2;
  }

  @media (max-width: 768px) {
    aside {
      display: none;
    }

    div.userDataInput {
      height: 44px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  position: relative;
  h2 {
    font-size: var(--font-largest);
    color: var(--primary-color);
  }

  p {
    margin-top: 8px;
    font-size: 24px;
    color: var(--regular-gray);
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 20px;
    }

    p {
      max-width: 327px;
      font-size: var(--font-large);
    }
  }
`;

export const ContentContainer = styled.div`
  margin: 0 auto;
  width: 100%;

  div.dropdown {
    display: none;
  }

  @media (max-width: 768px) {
    div.dropdown {
      display: block;
    }
  }
`;

export const AvatarInput = styled.div`
  position: relative;
  width: 140px;
  margin-left: 48px;

  img {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    object-fit: cover;
  }

  > svg {
    width: 140px;
    height: 140px;
  }

  label {
    cursor: pointer;
    position: absolute;
    width: 40px;
    height: 40px;
    background: var(--primary-color);
    border: none;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;

    input {
      display: none;
    }

    &:hover {
      background: var(--primary-color-hover);
    }
  }

  @media (max-width: 992px) {
    align-self: center;
    margin: 38px 0 42px;
  }
`;

export const RecommendedCourses = styled.div`
  padding-bottom: 54px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 40px;
  margin-top: 40px;

  > div {
    display: flex;

    flex-wrap: wrap;

    > div {
      margin-top: 24px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 0px;
    margin-bottom: 24px;

    h3 {
      font-size: var(--font-default);
      margin-bottom: 16px;
    }

    > div {
      display: flex;
      overflow-y: hidden;
      padding-bottom: 24px;
      flex-wrap: unset;

      > div {
        min-width: 190px;
        margin-top: 0;
      }
    }
  }
`;

export const ChangeDataForm = styled(Form)`
  padding-bottom: 54px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  > div {
    display: flex;
    align-items: flex-end;

    span {
      position: absolute;
    }
  }

  div {
    min-width: 300px;

    & + div {
      margin-left: 97px;
    }
  }

  button {
    max-width: 130px;
    margin-left: 57px;
  }

  @media (max-width: 1148px) {
    > div {
      flex-direction: column;
      align-items: flex-start;
    }

    div + div {
      margin: 48px 0 0 0;
    }

    button {
      margin: 48px 0 0 0;
    }
  }

  @media (max-width: 992px) {
    div + div {
      margin-top: 16px;
    }

    button {
      margin-top: 32px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 24px;
    margin-bottom: 24px;

    h3 {
      font-size: var(--font-large);
      margin-bottom: 16px;
    }

    > div {
      div {
        max-width: 350px;
        width: 100%;
      }
    }

    button {
      max-width: 350px;
      width: 100%;
      font-size: var(--font-default);
    }
  }
`;

export const ChangePasswordForm = styled(Form)`
  margin: 40px 0 88px;

  div {
    min-width: 300px;
  }

  > div {
    display: flex;
    align-items: flex-start;
  }

  @media (max-width: 992px) {
    > div {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 24px;
    margin-bottom: 24px;

    h3 {
      font-size: var(--font-large);
      margin-bottom: 16px;
    }

    > div {
      div {
        max-width: 350px;
        width: 100%;
      }
    }

    button {
      font-size: var(--font-default);
    }
  }
`;

export const NewPasswordContent = styled.div`
  margin-left: 97px;

  > div {
    margin-bottom: 24px;

    & + div {
      margin-bottom: 64px;
    }
  }

  button {
    max-width: 230px;
  }

  @media (max-width: 992px) {
    margin-left: 0;

    > div {
      margin-top: 16px;

      & + div {
        margin-bottom: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    button {
      max-width: unset;
    }
  }
`;

export const ExitButton = styled.button`
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  border: none;
  background: none;
  display: none;
  position: absolute;
  top: 160px;
  right: 5px;

  span {
    margin-right: 8px;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;
