function formatDataFromString(stringData: string | undefined): string {
  if (!stringData) return '';

  const data = new Date(stringData);

  const dia = adicionaZero(data.getDate());
  const mes = adicionaZero(data.getMonth() + 1);
  const ano = data.getFullYear();

  const horas = adicionaZero(data.getHours());
  const minutes = adicionaZero(data.getMinutes());
  return `${dia}/${mes}/${ano}`;
}

function adicionaZero(numero: number) {
  if (numero <= 9) return '0' + numero;
  else return numero;
}

export default formatDataFromString;
