import React, { useState } from 'react';
import { AiOutlinePlusCircle, AiFillMinusCircle } from 'react-icons/ai';
import { Container, Content, Question, Answer } from './style';
import { Questions } from '../../../../services/faq/models/question-and-answer-interface';

const QuestionAndAnswer: React.FC<Questions> = ({ id, question, answer }) => {
  const [collapse, setCollapse] = useState(false);

  const changeCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <Container key={id}>
      {collapse ? (
        <AiFillMinusCircle onClick={changeCollapse} size={26} color="#ffab00" />
      ) : (
        <AiOutlinePlusCircle onClick={changeCollapse} size={26} />
      )}
      <Content>
        <Question>{question}</Question>
        {collapse ? <Answer>{answer}</Answer> : <></>}
      </Content>
    </Container>
  );
};

export default QuestionAndAnswer;
