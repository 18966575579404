import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { createNewQuestion as createNewQuestionService } from '../../../../services/forum';

import CategoriesSelect from './components/CategoriesSelect';

import {
  Container,
  InputContainer,
  ButtonsContainer,
  ErrorMessage,
} from './style';

interface NewTopicFormProps {
  onCancel: () => void;
}

const NewTopicForm: React.FC<NewTopicFormProps> = ({ onCancel }) => {
  const history = useHistory();
  const [questionCategory, setQuestionCategory] = useState<{
    label: string;
    value: string;
  }>({ label: '', value: '' });

  const validationSchema = yup.object().shape({
    title: yup
      .string()
      .min(3, 'Este campo deve conter no mínimo 3 caracteres.')
      .trim()
      .required('Este campo é obrigatório.'),
    description: yup
      .string()
      .min(3, 'Este campo deve conter no mínimo 3 caracteres.')
      .trim()
      .required('Este campo é obrigatório.'),
  });

  const formik = useFormik({
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      title: '',
      description: '',
      category: questionCategory,
    },
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        const params = {
          title: values.title.trim(),
          description: values.description.trim(),
          categories: [{ category_id: questionCategory.value }],
          tags: [],
        };
        const response = await createNewQuestionService(params);
        resetForm();
        toast.success('Tópico criado com sucesso.');
        history.push(`/forum/post/${response.question_id}`);
      } catch (error) {
        toast.error(
          'Não foi possível criar o tópico. Por favor, tente novamente.',
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    isValid,
    handleSubmit,
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    isSubmitting,
  } = formik;

  const canSubmit = useMemo(() => {
    if (Object.keys(errors).length > 0) {
      return false;
    }
    if (!isValid) {
      return false;
    }

    return true;
  }, [errors, isValid]);

  return (
    <Container>
      <h3>Criar tópico</h3>

      <form onSubmit={handleSubmit}>
        <InputContainer>
          Título
          <input
            name="title"
            type="text"
            value={values.title}
            onBlur={handleBlur('title')}
            onChange={handleChange('title')}
            disabled={isSubmitting}
          />
          <ErrorMessage>
            {!!errors.title && touched.title && errors.title}
          </ErrorMessage>
        </InputContainer>

        <InputContainer>
          Descreva seu tópico
          <textarea
            name="description"
            value={values.description}
            onBlur={handleBlur('description')}
            onChange={handleChange('description')}
            disabled={isSubmitting}
          />
          <ErrorMessage>
            {!!errors.description && touched.description && errors.description}
          </ErrorMessage>
        </InputContainer>

        <div className="column">
          <InputContainer>
            Categoria
            <CategoriesSelect
              questionCategory={questionCategory}
              setQuestionCategory={setQuestionCategory}
            />
          </InputContainer>
        </div>

        <ButtonsContainer>
          <button type="button" className="cancel" onClick={onCancel}>
            Cancelar
          </button>
          <button type="submit" disabled={!canSubmit || isSubmitting}>
            Criar tópico
          </button>
        </ButtonsContainer>
      </form>
    </Container>
  );
};

export default NewTopicForm;
