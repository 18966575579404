import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;

  div.content {
    padding-left: 100px;
  }

  @media (max-width: 768px) {
    div.content {
      padding-left: 32px;
    }
  }
`;

export const MainHeader = styled.div`
  background-color: #fff;
  padding: 62px 0;

  @media (max-width: 768px) {
    padding: 32px 0 0px;
  }
`;

export const Body = styled.div`
  margin-top: 4px;
`;

export const Title = styled.h1`
  font-size: 44px;
  color: #000;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SubTitle = styled.p`
  color: #555;
  font-size: 22px;
  margin-top: 24px;

  @media (max-width: 768px) {
    font-size: var(--font-default);
  }
`;
