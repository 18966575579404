import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';
import { Form } from '@unform/web';

import { Container } from './style';

import { signIn as SignInService } from '../../services/signIn';

import HeaderLogo from '../../assets/logo.svg';
import DefaultInput from '../../components/Inputs/DefaultInput/DefaultInput';
import DefaultButton from '../../components/Buttons/DefaultButton';
import InputPassword from '../../components/Inputs/InputPassword';
import AuthBackButton from '../../components/Buttons/AuthBackButton';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../helpers/getValidationErrors';

const schema = Yup.object().shape({
  registration: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.'),
  password: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.')
    .min(8, 'A senha deve conter no mínimo 8 caracteres.'),
});

interface ISignInProps {
  registration: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit = async (data: ISignInProps) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await SignInService(data).then(() => {
        history.push('/home');
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <Container>
      <AuthBackButton />
      <img src={HeaderLogo} alt="logo" />

      <h3>Entrar</h3>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <DefaultInput
          name="registration"
          type="text"
          label="Matrícula"
          description="No campo abaixo, insira sua matrícula com TN ou TU antes dos
              números para ter acesso à plataforma. Ex: TN2222 / TU2222"
          required
        />
        <InputPassword name="password" label="Senha" />

        <Link to="/forgot-password">Esqueceu sua senha?</Link>

        <DefaultButton type="submit">Entrar</DefaultButton>
      </Form>
    </Container>
  );
};

export default SignIn;
