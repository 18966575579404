import { CertificateData } from '../../services/certificate';
import CertificateTemplate from '../templates/CertificateTemplate';
import { pdf } from '@react-pdf/renderer';

const generateCertificate = (CertificateData: CertificateData) => {
  const LuftCertificateTemplate = (
    <CertificateTemplate certificateData={CertificateData} />
  );

  return pdf(LuftCertificateTemplate).toBlob();
};

export { generateCertificate };
