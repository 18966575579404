import styled from 'styled-components';

export const ForumPageWrapper = styled.div`
  width: 100vw;

  h2 {
    text-align: left;
    margin-bottom: 8px;
  }

  button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    color: #fff;
    background-color: var(--primary-color);
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px;
    height: 32px;
    transition: 0.5s ease;
  }

  textarea {
    margin-bottom: 16px;
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    outline: none;
    /* max-width: 50%; */
    border: solid 1px #7a869a;
  }

  textarea {
    min-height: 64px;
  }
`;

export const ForumPageHeader = styled.div`
  padding: 38px 0 92px;
  display: flex;
  flex-direction: column;

  .buttons-container {
    margin-bottom: 56px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    svg {
      cursor: pointer;
      flex-shrink: 0;
    }
  }

  .postData {
    .title {
      display: flex;
      flex-direction: row;
      margin-bottom: 40px;
      align-items: center;
      color: #212631;

      h1 {
        font-size: 20px;
        font-weight: 700;
      }

      .postTag {
        margin-left: 42px;
        padding: 4px 4px;
        background-color: var(--primary-color);
        color: white;
        font-weight: 700;
        text-align: center;
        font-size: var(--font-min);
      }
    }
  }
  p {
    font-size: var(--font-default);
    font-weight: 400;
    margin-bottom: 16px;
    padding-right: 124px;
    color: #212631;
  }

  .authorData {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 40px;
    padding-right: 220px;

    &__icon {
      fill: #bdbdbd;
      margin-right: 4px;
    }

    .info {
      display: flex;
      flex-direction: column;

      &__name {
        font-weight: 700;
        color: var(--primary-color);
        font-size: var(--font-default);
      }

      &__date {
        font-weight: 700;
        font-size: var(--font-small);
      }
    }
  }

  .orderBy {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      border-radius: 0;
      font-weight: bold;
      background-color: #7a869a;
      height: 42px;

      &.selected {
        background-color: var(--primary-color);
      }
    }

    span {
      margin-right: 16px;
    }
  }
`;

export const Action = styled.div`
  border: none;
  color: white;
  border-radius: 0;
  padding: 8px;
  background-color: #7a869a;
  transition: 0.5s ease;
  cursor: pointer;

  &:hover {
    background-color: var(--primary-color);
  }
`;

export const PostAnswerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ForumPostFooterWrapper = styled.div`
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;

  .loadMore {
    font-weight: bold;
    color: var(--primary-color);
    transition: 0.5s ease;
    cursor: pointer;

    &:hover {
      color: var(--primary-color);
    }
  }
`;

export const NewPostButton = styled.button`
  padding: 10px 15px;
  color: #fff;
  background-color: var(--primary-color);
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 32px;
  transition: 0.5s ease;

  &:hover {
    background-color: var(--primary-color);
  }
`;

export const NoAnswerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;

  span {
    font-size: var(--font-large);
    font-weight: bold;
    margin-bottom: 16px;
  }
`;

export const TextareaWrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;

  margin-bottom: 32px;

  textarea {
    resize: none;
    height: 177px;
    border-radius: 6px;
    background: #f2f2f2;
    font-size: var(--font-default);
  }

  button {
    background: var(--secondary-color);
    padding: 0px 48px;
    height: 59px;
    color: #000;
    margin-left: auto;
    font-size: 18px;
    font-weight: 500;
    margin-top: 42px;

    &:hover {
      filter: opacity(0.9);
    }
  }
`;

export const DeletedTopic = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 30px;
  }
`;
