import React, { useState, useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import Modal from '../../../components/Modal';
import ModuleTrail from '../../../models/module-trail';
import Trail from '../../../models/trail';
import { IAttempts, IExam } from '../../../services/exam';
import { BodyText, BodyTitle } from '../../Exam/style';

import { BtnGroup, PrimaryButton, SecondaryButton } from '../style';

type ExamMoment = 'start' | 'end';

interface IModalExam {
  Exam: IExam;
  Attempts: IAttempts;
  open: boolean;
  setVisibility: (visible: boolean) => void;
  seeAnswers: () => void;
  seeExam: () => void;
  restart: () => void;
  goBack: () => void;
  trail?: Trail;
  moduleInfo?: ModuleTrail;
  result: number;
}

interface IModalType {
  [index: string]: {
    title: string;
    text: string;
    primaryBtn: {
      text: string;
      action: () => void;
    };
    secondaryBtn?: {
      text: string;
      action: () => void;
    };
  };
}

const ResultModal: React.FC<IModalExam> = ({
  Exam,
  Attempts,
  open,
  setVisibility,
  seeAnswers,
  seeExam,
  restart,
  goBack,
  trail,
  result,
}) => {
  const history = useHistory();

  function isEmpty(obj: any): Boolean {
    if (!obj) return true;
    return !Object.entries(obj).length;
  }

  function isBeforeTrailExam(): Boolean {
    if (isEmpty(trail)) return false;
    return !trail?.alreadyStarted;
  }

  const { amount_questions, average } = Exam;
  const { amount } = amount_questions[0];
  const minValue = Math.ceil((amount * Number(average)) / 100);

  const status: string = isBeforeTrailExam()
    ? 'none'
    : result >= minValue
    ? 'approved'
    : 'disapproved';

  const noAttempts = Attempts.exam_user.length === Attempts.user_total_attempts;

  const ModalText: IModalType = {
    approved: {
      title: '',
      text: `Você acertou ${result} de ${amount} questões.`,
      primaryBtn: {
        text: 'Voltar para a trilha',
        action: () => history.push(`/trails/${trail?.id}`),
      },
      secondaryBtn: {
        text: 'Ver prova',
        action: () => seeAnswers(),
      },
    },
    disapproved: {
      title: 'Que pena!',
      text: 'Você não atingiu o mínimo de acerto nas questões da trilha.',

      primaryBtn: {
        text: noAttempts ? 'Voltar para a trilha' : 'Iniciar avaliação',
        action: noAttempts
          ? () => history.push(`/trails/${trail?.id}`)
          : () => window.location.reload(),
      },
      secondaryBtn: {
        text: 'Ver prova',
        action: () => seeExam(),
      },
    },
    none: {
      title: 'Questionário',
      text: `Você acertou ${result} de ${amount} questões`,
      primaryBtn: {
        text: 'Voltar para a trilha',
        action: () => history.push(`/trails/${trail?.id}`),
      },
      secondaryBtn: {
        text: 'Ver prova',
        action: () => seeAnswers(),
      },
    },
  };

  return (
    <Modal
      open={open}
      bgColor={
        status === 'approved'
          ? '#8AFAB7'
          : status === 'disapproved'
          ? '#FA8A8A'
          : '#FFFFFF'
      }
      setVisibility={open => setVisibility(open)}
    >
      <h3>{Exam.title}</h3>
      <div>
        <BodyTitle>{ModalText[status]?.title}</BodyTitle>
        <BodyText>{ModalText[status]?.text}</BodyText>
      </div>
      <BtnGroup>
        <PrimaryButton
          onClick={() => {
            ModalText[status]?.primaryBtn.action();
            setVisibility(false);
          }}
        >
          {ModalText[status]?.primaryBtn.text}
        </PrimaryButton>
        {ModalText[status]?.secondaryBtn && (
          <SecondaryButton
            onClick={() => {
              ModalText[status]?.secondaryBtn?.action();
              setVisibility(false);
            }}
          >
            {ModalText[status]?.secondaryBtn?.text}
          </SecondaryButton>
        )}
      </BtnGroup>
    </Modal>
  );
};

export default ResultModal;
