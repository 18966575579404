import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import {
  Container,
  ExamContainer,
  Question,
  Title,
  Label,
  InputBlock,
  SubTitle,
  Button,
  Input,
  SecondaryButton,
  BtnGroup,
  PrimaryButton,
} from './style';

import {
  getExam,
  startExam as startExamService,
  IExam,
  IUserExam,
  getAttempts as getAttemptsService,
  IAnswer,
  getAnswers,
  putAnswer,
  putFinish,
  IStartExam,
  ExamType,
  getCorrectAlternativeId,
  getCorrectAlternative,
  getQuestions,
  IAttempts,
} from '../../services/exam';

import { getTrail as getTrailService } from '../../services/trail';

import Trail from '../../models/trail';
import { IoIosArrowBack } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useMemo } from 'react';
import getErrorMessage from '../../helpers/get-error-message';

import id from 'date-fns/esm/locale/id/index';
import { isBefore, parseISO } from 'date-fns';
import ResultModal from './Result';

type ExamMoment = 'start' | 'end';

interface IExamParams {
  courseId: string;
  examId: string;
  type: ExamType;
  id: string;
}

interface ILocationProps {
  trailId: string;
}

interface Props {
  trail?: Trail;
}

const Exam: React.FC<Props> = () => {
  const { courseId, examId, type, id } = useParams() as IExamParams;
  const history = useHistory();

  const [attempts, setAttempts] = useState<IAttempts>({} as IAttempts);
  const [userExam, setUserExam] = useState<IUserExam>({} as IUserExam);
  const [exam, setExam] = useState<IExam>({} as IExam);
  const [answers, setAnswers] = useState<any[]>([]);
  const [trail, setTrail] = useState({} as Trail);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [questionsToShow, setQuestionsToShow] = useState<any[]>([]);
  const [result, setResult] = useState(0);

  function attemptInProgress() {
    if (isEmpty(userExam)) {
      const { final_date, result } = attempts.exam_user[0];
      return final_date === null || result === null;
    } else {
      const { final_date, result } = userExam;
      return final_date === null || result === null;
    }
  }

  function isBeforeTrailExam(): Boolean {
    if (isEmpty(trail)) return false;
    return !trail?.alreadyStarted;
  }

  function isEmpty(obj: Object): boolean {
    if (!obj) return true;
    return !Object.entries(obj).length;
  }

  async function setAnswer(alternative: string, questionId: string) {
    if (!answers) return;

    const examAnswerUserId = answers.filter(
      ({ question_id }) => question_id === questionId,
    )[0].exam_answer_user_id;

    const correct_alternative = await getCorrectAlternativeId(questionId);

    putAnswer(examAnswerUserId, alternative)
      .then(({ question_id }) => {
        const newAnswers = answers.map(a => {
          if (a.question_id === question_id) {
            return { ...a, answer_id: alternative, correct_alternative };
          } else {
            return a;
          }
        });

        setAnswers(newAnswers);
      })
      .catch(err => {
        toast.error(
          'Não foi possível finalizar a prova. Verifique suas respostas e tente novamente!',
        );
      });
  }

  function sendExam() {
    const { exam_user_id } = userExam;

    putFinish(exam_user_id)
      .then(data => {
        setResult(data.result);
        setVisible(true);
      })
      .catch(err => {
        const errorMessage = getErrorMessage(err);
        toast.error('Erro ao entregar avaliação. ' + errorMessage);
      });
  }

  function seeAnswers() {
    setDisabled(true);
    if (exam.info.enable_quiz && attempts.exam_user.length === 1) {
      setShowTemplate(false);
    } else {
      setShowTemplate(true);
    }
  }

  function seeExam() {
    setDisabled(true);
  }

  function restart() {
    window.location.reload();
  }

  useEffect(() => {
    if (userExam && userExam.exam_user_id) {
      getQuestions(userExam.exam_user_id).then((data: any) => {
        setQuestionsToShow(data);
      });
    }
  }, [userExam]);

  const startExam = () => {
    if (isApproved) {
      return;
    }

    let startExamResponse = {} as Promise<IUserExam>;

    if (type === 'trail') {
      startExamResponse = startExamService({
        exam_id: examId,
        course_id: courseId,
      });
    } else {
      startExamResponse = startExamService({
        exam_id: examId,
        content_id: courseId,
      });
    }

    startExamResponse
      .then(data => {
        if (isEmpty(attempts)) {
          window.location.reload();
        }

        const newAttempts = attempts;

        newAttempts.exam_user.unshift(data);
        setAttempts(newAttempts);

        setUserExam(data);
      })
      .catch(err => {
        if (err && err.response && err.response.data) {
          const { message } = err.response.data;

          if (message === 'O usuário não iniciou a trilha') {
            toast.error(
              'Você precisa iniciar a trilha para realizar a avaliação!',
            );

            setTimeout(() => {
              history.push('/trails/' + id);
            }, 2000);
          }

          if (message === 'O limite de tentativas foi atingido') {
            toast.error('Você atingiu o limite de tentativas!');
            setTimeout(() => {
              history.push('/trails/' + id);
            }, 2000);
          }
        }
      });
  };

  useEffect(() => {
    getAttemptsService(examId, courseId).then(data => {
      if (!isEmpty(data.exam_user)) setAttempts(data);
      else startExam();
    });

    getExam(examId).then(data => {
      if (!isEmpty(data)) setExam(data);
    });

    if (type === 'trail')
      getTrailService(id).then(data => {
        setTrail(data);
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(attempts)) {
      if (isEmpty(userExam) && !attemptInProgress()) {
        startExam();
      } else {
        setUserExam(attempts.exam_user[0]);
      }
    }
  }, [attempts]);

  useEffect(() => {
    if (!isEmpty(userExam) && isEmpty(answers)) {
      const { exam_user_id } = userExam;
      getAnswers(exam_user_id).then(data => {
        data.map((answer: any) => {
          getCorrectAlternative(answer.question_id)
            .then(response => {
              if (answer && answer.question_id === response.question_id) {
                setAnswers(oldArray => [
                  ...oldArray,
                  {
                    ...answer,
                    correct_alternative:
                      response.correct_alternative.alternative_id,
                  },
                ]);
              } else {
                setAnswers(oldArray => [...oldArray, { answer }]);
              }
            })
            .catch(err => {});
        });
      });
    }
  }, [userExam]);

  function isSelected(alt: string, question: string): boolean {
    if (isEmpty(answers)) return false;

    const q = answers.filter(({ question_id }) => question_id === question);
    return q.length ? q[0].answer_id === alt : false;
  }

  const isApproved = useMemo(() => {
    if (exam && exam.amount_questions && exam.amount_questions.length) {
      const numberOfQuestions = (exam.amount_questions || [])
        .map(amt => amt.amount * amt.points)
        .reduce((a, b) => a + b);
      return (
        Math.ceil((numberOfQuestions * Number(exam.average)) / 100) <=
        userExam.result
      );
    }
  }, [trail, exam, attempts, userExam, result]);

  return (
    <Container className="content">
      {!isEmpty(exam) && !isEmpty(attempts) && (
        <ResultModal
          Exam={exam}
          Attempts={attempts}
          open={visible}
          setVisibility={show => setVisible(show)}
          seeAnswers={() => seeAnswers()}
          seeExam={() => seeExam()}
          restart={() => restart()}
          trail={trail}
          goBack={() => {
            history.push('/trails/' + id);
          }}
          result={result}
        />
      )}
      <IoIosArrowBack color="#444" size={40} onClick={() => history.goBack()} />
      {questionsToShow && questionsToShow.length ? (
        <>
          <Title>{exam.title}</Title>
          <SubTitle>{exam.instructions}</SubTitle>
          <ExamContainer>
            {questionsToShow.length ? (
              questionsToShow.map(
                ({ question_id, enunciated, alternative }, index) => (
                  <div>
                    <Question id={question_id}>
                      {`${index + 1} - `}
                      {enunciated}
                    </Question>
                    {alternative?.map(({ alternative_id, value }: any) => (
                      <InputBlock
                        key={alternative_id}
                        className={`${
                          showTemplate &&
                          answers.find(
                            answer =>
                              answer.correct_alternative === alternative_id,
                          ) &&
                          'correct'
                        }
                    ${
                      showTemplate &&
                      isSelected(alternative_id, question_id) &&
                      answers.find(
                        answer => answer.correct_alternative === alternative_id,
                      ) &&
                      'correct-checked'
                    }
                    `}
                      >
                        <Input
                          value={alternative_id}
                          id={alternative_id}
                          type="radio"
                          name={question_id}
                          checked={isSelected(alternative_id, question_id)}
                          disabled={disabled || !answers[0]}
                          onChange={() => {
                            setAnswer(alternative_id, question_id);
                          }}
                          className={`${
                            showTemplate &&
                            isSelected(alternative_id, question_id) &&
                            answers.find(
                              answer =>
                                answer.correct_alternative !== alternative_id,
                            ) &&
                            'wrong'
                          }`}
                        />
                        <Label
                          htmlFor={alternative_id}
                          className={`${
                            isSelected(alternative_id, question_id) &&
                            'selected'
                          } ${
                            showTemplate &&
                            isSelected(alternative_id, question_id) &&
                            answers.find(
                              answer =>
                                answer.correct_alternative !== alternative_id,
                            ) &&
                            'wrong'
                          }`}
                        >
                          {value}
                        </Label>
                      </InputBlock>
                    ))}
                  </div>
                ),
              )
            ) : (
              <></>
            )}
            <BtnGroup>
              {!disabled && (
                <Button onClick={sendExam}>Entregar avaliação</Button>
              )}
              {disabled && (
                <>
                  {isBeforeTrailExam() ? (
                    <PrimaryButton
                      onClick={() => history.push('/trails/' + id)}
                    >
                      Voltar para a trilha
                    </PrimaryButton>
                  ) : (
                    <>
                      {attempts.exam_user.length < exam.attempts &&
                        trail.alreadyStarted &&
                        !isApproved && (
                          <PrimaryButton
                            onClick={() => window.location.reload()}
                          >
                            Reiniciar Avaliação
                          </PrimaryButton>
                        )}
                      <SecondaryButton
                        onClick={() => history.push('/trails/' + id)}
                      >
                        Voltar para a trilha
                      </SecondaryButton>
                    </>
                  )}
                </>
              )}
            </BtnGroup>
          </ExamContainer>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default Exam;
