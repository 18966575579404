import React, { useState } from 'react';
import { AnnotationFromResponse } from '../../../../services/course/annotations';
import {
  AnnotationCardActionButtons,
  AnnotationCardButtons,
  AnnotationCardCancelButton,
  AnnotationCardContainer,
  AnnotationCardCreationDate,
  AnnotationCardDescription,
  AnnotationCardEditButton,
  AnnotationCardRemoveButton,
  AnnotationCardSaveButton,
  AnnotationCardTitle,
  AnnotationCardUpperContent,
} from './style';
import { FiEdit } from 'react-icons/fi';
import { BiTrash, BiSave } from 'react-icons/bi';
import { GrClose } from 'react-icons/gr';
import {
  updateAnnotation as updateAnnotationService,
  deleteAnnotation as deleteAnnotationService,
} from '../../../../services/course/annotations';
import { toast } from 'react-toastify';

interface AnnotationCardProps {
  annotation: AnnotationFromResponse;
  onDeleteAnnotation: (annotationId: string) => void;
}

const AnnotationCard: React.FC<AnnotationCardProps> = ({ annotation, onDeleteAnnotation }) => {
  const [isEditting, setIsEditting] = useState(false);
  const [annotationTitle, setAnnotationTitle] = useState('');
  const [annotationDescription, setAnnotationDescription] = useState('');

  const startEditting = () => {
    setAnnotationTitle(annotation.title);
    setAnnotationDescription(annotation.description);
    setIsEditting(true);
  };

  const updateAnnotation = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (!annotationTitle) {
        throw new Error('Informe o título da anotação!');
      }

      if (!annotationDescription) {
        throw new Error('Informe a descrição da anotação!');
      }

      await updateAnnotationService(annotation.annotation_id, {
        annotation_id: annotation.annotation_id,
        title: annotationTitle,
        description: annotationDescription,
      });

      toast.success('Anotação atualizada com sucesso!');

      annotation.title = annotationTitle;
      annotation.description = annotationDescription;

      setIsEditting(false);
    } catch (e) {
      toast.error('Erro ao atualizar anotação. ' + e.message);
    }
  };

  const deleteAnnotation = async () => {
    try {
      await deleteAnnotationService(annotation.annotation_id);

      toast.success('Anotação removida com sucesso!');

      onDeleteAnnotation(annotation.annotation_id);
    } catch (e) {
      toast.error('Erro ao remover anotação.');
    }
  };

  const getFormattedDate = (date: string | Date) => {
    if (date) {
      if (date instanceof Date) {
        return new Intl.DateTimeFormat('pt-BR').format(date);
      } else {
        return new Intl.DateTimeFormat('pt-BR').format(new Date(date));
      }
    }
  };

  return (
    <AnnotationCardContainer onSubmit={e => updateAnnotation(e)}>
      <AnnotationCardUpperContent>
        <AnnotationCardTitle>
          {isEditting ? (
            <input
              type="text"
              value={annotationTitle}
              onChange={e => setAnnotationTitle(e.target.value)}
              required={true}
            />
          ) : (
            annotation.title
          )}
        </AnnotationCardTitle>

        <AnnotationCardButtons>
          {isEditting ? (
            <></>
          ) : (
            <>
              <AnnotationCardEditButton type="button" onClick={startEditting}>
                <FiEdit size={24} color="#C3C9D2" />
              </AnnotationCardEditButton>

              <AnnotationCardRemoveButton
                onClick={deleteAnnotation}
                type="button"
              >
                <BiTrash size={24} color="#C3C9D2" />
              </AnnotationCardRemoveButton>
            </>
          )}
        </AnnotationCardButtons>
      </AnnotationCardUpperContent>

      {isEditting ? (
        <></>
      ) : (
        <AnnotationCardCreationDate>
          {getFormattedDate(annotation.created_at)}
        </AnnotationCardCreationDate>
      )}

      <AnnotationCardDescription>
        {isEditting ? (
          <textarea
            rows={3}
            value={annotationDescription}
            onChange={e => setAnnotationDescription(e.target.value)}
            required={true}
          ></textarea>
        ) : (
          annotation.description
        )}
      </AnnotationCardDescription>

      {isEditting ? (
        <AnnotationCardActionButtons>
          <AnnotationCardCancelButton
            type="button"
            onClick={e => setIsEditting(false)}
          >
            <GrClose />
            <span>Cancelar</span>
          </AnnotationCardCancelButton>

          <AnnotationCardSaveButton type="submit">
            <BiSave />
            <span>Salvar Edição</span>
          </AnnotationCardSaveButton>
        </AnnotationCardActionButtons>
      ) : (
        <></>
      )}
    </AnnotationCardContainer>
  );
};

export default AnnotationCard;
