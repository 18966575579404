import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../assets/logo-white.svg';

import { Container, Content } from './style';

const Footer: React.FC = () => {
  return (
    <Container>
      <Content className="content">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>

        <span>© Telhanorte Tumelero, 2021, todos os direitos reservados</span>

        <div>
          <Link to="/faq">FAQ</Link>

          {/* <Link to="/faq">Fale Conosco</Link> */}
        </div>
      </Content>
    </Container>
  );
};

export default Footer;
