import httpClient from '../../http-client';

interface UserExamData {
  exam_user_id: string;
  exam_id: string;
  reference_id: string;
  result: any;
  initial_date: string;
  final_date: string;
}

export interface IUserExam {
  exam_user_id: string;
  exam_id: string;
  reference_id: string;
  result: any;
  initial_date: string;
  final_date: string;
  user_id?: string;
}

export interface IAttempts {
  exam_user: IUserExam[];
  user_total_attempts: number;
}

export interface IExamAnswerUser {
  exam_answer_user_id: string;
  question_id: string;
  answer_id: any;
  answer: any;
  upload: any;
  date: any;
}

interface IQuestion {
  question_id: string;
  enunciated: string;
  answer: object;
  enabled: true;
  randomize_alternatives: true;
  points: number;
  limit_time: string;
  alternative: IAlternative[];
}

export interface IExam {
  exam_id: string;
  title: string;
  attempts: number;
  average: string;
  enabled: boolean;
  execution_time: string;
  instructions: string;
  questions: IQuestion[];
  info: { enable_quiz: boolean };
  amount_questions: [
    {
      amount: number;
      points: number;
    },
  ];
}

export interface IAnswer {
  exam_answer_user_id: string;
  question_id: string;
  answer_id: string;
  answer: string;
  upload: string;
  date: string;
  answersWithResult: any;
  correct_alternative?: string;
}

export interface IAlternative {
  alternative_id: string;
  order: number;
  value: string;
  selected: any;
}

export type ExamType = 'trail' | 'content';

export interface IStartExam {
  exam_id: string;
  content_id?: string;
  trail_id?: string;
  course_id?: string;
}

export const startExam = async (body: IStartExam) => {
  return httpClient
    .post<any>(`exam-users/start`, body)
    .then(({ data }) => data);
};

export const getExam = async (examId: string) => {
  return httpClient.get<IExam>(`/exams/${examId}`).then(({ data }) => data);
};

export const getUserExams = async (examId: string) => {
  const userExams = (
    await httpClient.get<UserExamData[]>(`/trail-users/certificate/${examId}`)
  ).data;

  return userExams;
};

export const getQuestions = async (examUserId: string) => {
  const questions = await httpClient.get(`questions/${examUserId}/exam-users`);
  return questions.data;
};

export const getExamAnswerUser = async (examUserId: string) => {
  const raw = (
    await httpClient.get<IExamAnswerUser[]>(`/exam-users/${examUserId}`)
  ).data;
  return raw;
};

export const getAttempts = async (examId: string, referenceId: string) => {
  return httpClient
    .get<any>(`/exam-users/${examId}/${referenceId}/attempts`)
    .then(({ data }) => data);
};

export const putAnswer = async (
  exam_answer_user_id: string,
  answer_id: string,
) => {
  return httpClient
    .put<IAnswer>(`/exam-users/answer`, {
      exam_answer_user_id,
      answer_id,
    })
    .then(({ data }) => data);
};

export const getAnswers = async (exam_user_id: string) => {
  return httpClient
    .get<IAnswer>(`/exam-users/${exam_user_id}/answers`)
    .then(({ data }) => data.answersWithResult);
};

export const putFinish = async (exam_user_id: string) => {
  return httpClient
    .put<IUserExam>(`/exam-users/finish`, {
      exam_user_id,
    })
    .then(({ data }) => data);
};

export const getCorrectAlternativeId = async (questionId: string) => {
  return (
    await httpClient.get<{ correct_alternative: { alternative_id: string } }>(
      `/questions/${questionId}/correct-alternative`,
    )
  ).data.correct_alternative.alternative_id;
};

export const getCorrectAlternative = async (questionId: string) => {
  return (
    await httpClient.get<any>(`/questions/${questionId}/correct-alternative`)
  ).data;
};

export const examUsers = async () => {
  const exams = await httpClient.get('/exam-users/');
  return exams.data;
};
