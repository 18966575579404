import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { AiFillLike, AiOutlinePlayCircle } from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineLike } from 'react-icons/ai';
import Annotations from './components/Annotations';
import ExtraMaterials from './components/ExtraMaterials';
import PlayerSambaVideos from '../../../../components/PlayerSambaVideos';
import { CourseContentContainer, TabLinksContainer } from './style';
import { addBookMark, removeBookMark } from '../../../../services/bookmark';
import { toast } from 'react-toastify';
import Trail from '../../../../models/trail';
import Content from '../../../../models/content';
import { updateContentProgress as updateContentProgressService } from '../../../../services/trail';
import { addLike, removeLike } from '../../../../services/like';
import PlayScorm from '../../../Course/components/PlayScorm';
import { convertToDuration } from '../../../../helpers/seconds-to-time';
import ReactAudioPlayer from 'react-audio-player';
import { formatDistanceStrict } from 'date-fns';
import { Rating } from 'react-simple-star-rating';
import { getTrailUserReviews } from '../../../../services/trail-review';
import { average, evaluationGrade } from '../../../../utils/getReviews';

interface TrailContentProps {
  selectedContent: Content;
  trail: Trail;
  getEventListeners: (player: PlayerEventListener) => void;
  progress: number;
  startCurrentContent: () => Promise<void>;
  finishContent: () => Promise<void>;
  goToNextContent: () => void;
  startModule: () => Promise<void>;
}

export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}
const TrailContent: React.FC<TrailContentProps> = ({
  selectedContent,
  trail,
  getEventListeners,
  progress,
  startCurrentContent,
  finishContent,
  goToNextContent,
  startModule,
}) => {
  const [addedToList, setAddedToList] = useState(trail?.addedToList);
  const [addedToLike, setAddedToLike] = useState(trail.userLiked);
  const [numberOfLikes, setNumberOfLikes] = useState(trail.numberOfLikes);
  const [tabToBeShown, setTabToBeShown] = useState('overview-tab');
  const [shouldShowPlayer, setShouldShowPlayer] = useState(true);
  const [lastWatchedTime, setLastWatchedTime] = useState<number>(0);
  const [rating, setRating] = useState(0);
  const [previousSelectedContent, setPreviousSelectedContent] = useState(
    {} as Content,
  );

  const displayTab = (tabToBeDisplayed: string) => {
    setTabToBeShown(tabToBeDisplayed);
  };

  const resumeTime = useMemo(() => {
    if (!selectedContent) {
      return false;
    }
    if (selectedContent.contentView) {
      setLastWatchedTime(selectedContent.contentView);
      return selectedContent.contentView;
    }

    return false;
  }, [selectedContent]);

  const daysToBeFinishContent = formatDistanceStrict(
    new Date(trail.deadline),
    new Date(),
    {
      unit: 'day',
    },
  )
    .replace('days', 'dias')
    .replace('day', 'dia');

  const addToList = async () => {
    if (trail) {
      try {
        const shouldAddToList = !trail.addedToList;

        if (shouldAddToList) {
          await addBookMark(trail.id);
        } else {
          await removeBookMark(trail.id);
        }

        trail.addedToList = shouldAddToList;
        setAddedToList(trail.addedToList);
      } catch (e) {
        toast.error('Erro ao adicionar a trilha aos favoritos.');
      }
    }
  };

  const addToLike = async (event: React.MouseEvent) => {
    if (trail && trail.id) {
      try {
        if (addedToLike) {
          await removeLike(trail.id);
          setNumberOfLikes(numberOfLikes - 1);
        } else {
          await addLike(trail.id);
          setNumberOfLikes(numberOfLikes + 1);
        }
        setAddedToLike(!addedToLike);
      } catch (error) {
        toast.error('Erro ao curtir a trilha.');
      }
    }
  };

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateContentProgressService(
        selectedContent.trailId || '',
        selectedContent.courseId || '',
        selectedContent.id,
        time,
      );

      return;
    },
    [selectedContent.trailId, selectedContent.courseId, selectedContent.id],
  );

  useEffect(() => {
    const selectedContentIsDifferent =
      selectedContent.id !== previousSelectedContent.id ||
      selectedContent.courseId !== previousSelectedContent.courseId ||
      selectedContent.trailId !== previousSelectedContent.trailId;
    if (selectedContentIsDifferent) {
      setShouldShowPlayer(false);
      process.nextTick(() => setShouldShowPlayer(true));
    }

    setPreviousSelectedContent(selectedContent);
    setTabToBeShown('overview-tab');
  }, [trail, selectedContent]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [progress, lastWatchedTime, updateContentProgress]);

  useEffect(() => {
    (async () => {
      if (selectedContent.type === 'FILE') {
        await startCurrentContent().then(async () => await finishContent());
      }
    })();
  }, [selectedContent]);

  const reviews = useCallback(async () => {
    const allReviews = await getTrailUserReviews(trail.id);
    const qtdReviews = allReviews.length;
    const averageOfStarts = average(allReviews, qtdReviews);
    const stars = evaluationGrade(averageOfStarts) || 0;

    setRating(stars);

  },[trail.id]);

  useEffect(() => {
    reviews();
  },[reviews]);

  return (
    <CourseContentContainer>
      <>
        <div className="upper-content">
          <div className="category" style={{ color: '#000' }}>
            {trail.categoryDescription}
          </div>

          <h2 className="title">{trail.name}</h2>
          <div>
            <Rating
              ratingValue={rating}
              fillColorArray={[
                '#f17a45',
                '#f19745',
                '#f1a545',
                '#f1b345',
                '#f1d045',
              ]}
              size={25}
              allowHalfIcon
              readonly
            />
          </div>
        </div>

        <div className="about-content">
          <div>
            <span className="duration">
              <AiOutlineClockCircle size={24} color="var(--primary-color)" />
              <span>
                Duração: {`${convertToDuration(trail.duration) || 0}`}
              </span>
            </span>

            <span className={`likes ${addedToLike && 'liked'}`}>
              <button onClick={addToLike}>
                {addedToLike ? (
                  <AiFillLike color="var(--primary-color)" size={24} />
                ) : (
                  <AiOutlineLike color="var(--primary-color)" size={24} />
                )}
              </button>{' '}
              <span>
                {numberOfLikes} {numberOfLikes === 1 ? 'curtida' : 'curtidas'}
              </span>
            </span>
          </div>

          {trail.deadline ? (
            new Date(trail.deadline) > new Date() ? (
              daysToBeFinishContent === '0 dias' ? (
                <strong style={{ marginLeft: '10px' }}>
                  Hoje é o último dia para realizar a trilha.
                </strong>
              ) : (
                <p style={{ marginLeft: '10px' }}>
                  Faltam{' '}
                  <strong style={{ color: 'var(--primary-color)' }}>
                    {daysToBeFinishContent}
                  </strong>{' '}
                  para a trilha encerrar.
                </p>
              )
            ) : (
              'A vigência do curso expirou.'
            )
          ) : (
            ''
          )}

          <button
            className={`
              add-to-list
              ${addedToList ? 'added-to-list' : ''}
            `}
            onClick={addToList}
          >
            <BsBookmark size={20} color={`${addedToList ? '#000' : '#555'}`} />{' '}
            {addedToList ? (
              <span className="remove-to-list">Remover dos favoritos</span>
            ) : (
              <span>Adicionar aos favoritos</span>
            )}
          </button>
        </div>
      </>

      <div className="lesson-content">
        <div className="lesson-video-container">
          <div className="player-container">
            {shouldShowPlayer ? (
              selectedContent?.type &&
              selectedContent.type.toUpperCase() === 'SCORM' ? (
                <PlayScorm
                  finishCourseOrTrail={finishContent}
                  startCourseOrTrail={startCurrentContent}
                  goToNextContent={goToNextContent}
                  selectedContent={selectedContent}
                  startModule={startModule}
                />
              ) : selectedContent?.type &&
                selectedContent.type.toUpperCase() === 'FILE' ? (
                selectedContent.fileType === 'MP3' ? (
                  <div style={{ display: 'flex' }}>
                    <ReactAudioPlayer
                      src={selectedContent.contentUrl}
                      volume={0.5}
                      controls
                      style={{
                        marginBottom: '10px',
                        backgroundColor: '#FFFFFF',
                      }}
                    />
                  </div>
                ) : (
                  <iframe
                    title="referenced-file"
                    allowFullScreen={true}
                    src={selectedContent.contentUrl}
                    frameBorder={0}
                    style={{ marginBottom: '10px' }}
                  ></iframe>
                )
              ) : (
                <PlayerSambaVideos
                  contentReference={
                    selectedContent.contentUrl || trail.introductionUrl
                  }
                  resume={resumeTime ? resumeTime : 0}
                  getEventListeners={getEventListeners}
                />
              )
            ) : (
              <div className="player-icon-container">
                <div className="player-icon">
                  <AiOutlinePlayCircle color="#FFF" size={200} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="lesson-content-tabs">
          <TabLinksContainer>
            <button
              type="button"
              className={`
                modules-tab-link
                ${tabToBeShown === 'modules-tab' ? 'active' : ''}
              `}
              onClick={e => {
                displayTab('modules-tab');
              }}
            >
              Módulos
            </button>

            <button
              type="button"
              className={`
                overview-tab-link
                ${tabToBeShown === 'overview-tab' ? 'active' : ''}
              `}
              onClick={e => {
                displayTab('overview-tab');
              }}
            >
              Visão Geral
            </button>

            {selectedContent.id && (
              <button
                type="button"
                className={`
                support-material-tab-link
                ${tabToBeShown === 'support-material-tab' ? 'active' : ''}
              `}
                onClick={e => {
                  displayTab('support-material-tab');
                }}
              >
                Material de Apoio
              </button>
            )}

            {selectedContent.id && (
              <button
                type="button"
                className={`
                    annotations-tab-link
                    ${tabToBeShown === 'annotations-tab' ? 'active' : ''}
                  `}
                onClick={e => {
                  displayTab('annotations-tab');
                }}
              >
                Anotações
              </button>
            )}
          </TabLinksContainer>

          <div className="content-tab-contents">
            {(() => {
              switch (tabToBeShown) {
                case 'overview-tab':
                  return (
                    <div className="overview-tab-content">
                      {selectedContent.description || trail.description}
                    </div>
                  );

                case 'support-material-tab':
                  return (
                    <div className="support-material-tab-content">
                      <ExtraMaterials
                        extra_material={selectedContent.extraMaterial}
                      />
                    </div>
                  );

                case 'annotations-tab':
                  return (
                    selectedContent && (
                      <Annotations
                        contentId={selectedContent.id}
                        trailId={trail.id}
                      />
                    )
                  );
              }
            })()}
          </div>
        </div>
      </div>
    </CourseContentContainer>
  );
};

export default TrailContent;
