import React from 'react';
import { useHistory } from 'react-router-dom';
import { BiCheck, BiLock } from 'react-icons/bi';
import { AiOutlinePlayCircle } from 'react-icons/ai';

import Content from '../../../../../../../../models/content';

import {
  TrailCourseContentContainer,
  TrailCourseContentDuration,
  TrailCourseContentFluxIndicator,
  TrailCourseContentFluxIndicatorContainer,
  TrailCourseContentTitle,
} from './style';
import { secondsToTimeText } from '../../../../../../../../helpers/time';
import Trail from '../../../../../../../../models/trail';
import { convertToDuration } from '../../../../../../../../helpers/seconds-to-time';

interface TrailModuleContentProps {
  content: string;
  isSelected: boolean;
  trail: Trail;
}

const IntroductionContent: React.FC<TrailModuleContentProps> = ({
  content,
  isSelected,
  trail,
}) => {
  const history = useHistory();

  const changeContent = () => {
    history.push(`/trails/${trail.id}`);
  };

  return (
    <TrailCourseContentContainer onClick={changeContent}>
      <TrailCourseContentFluxIndicatorContainer>
        <TrailCourseContentFluxIndicator className="selected">
          {isSelected ? (
            <AiOutlinePlayCircle size={22} />
          ) : (
            <BiCheck size={20} />
          )}
        </TrailCourseContentFluxIndicator>
      </TrailCourseContentFluxIndicatorContainer>
      <TrailCourseContentTitle className={`${isSelected ? 'selected' : ''}`}>
        Apresentação do curso
      </TrailCourseContentTitle>
      {trail.info && trail.info.video_reference_duration && (
        <TrailCourseContentDuration>
          {`${convertToDuration(trail.info.video_reference_duration)}`}
        </TrailCourseContentDuration>
      )}
    </TrailCourseContentContainer>
  );
};

export default IntroductionContent;
