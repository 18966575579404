import axios from 'axios';
import { toast } from 'react-toastify';
import httpClient from '../../http-client';

interface IForgotPasswordReset {
  password: string;
  registration: string;
  new_password_confirm: string;
}

const resetPassword = async ({
  registration,
  password,
  new_password_confirm,
}: IForgotPasswordReset): Promise<void> => {
  try {
    await httpClient
      .post(`${process.env.REACT_APP_API_BASE_URL}/users/change-password`, {
        previous_password: password,
        proposed_password: new_password_confirm,
      })
      .then(response => {});

    return Promise.resolve();
  } catch (err) {
    if (err.response.data.message === 'Incorrect username or password.') {
      toast.error('A senha atual informada está incorreta.');
    } else {
      toast.error(
        'Algo deu errado ao resetar a senha. Por favor, tente novamente.',
      );
    }

    return Promise.reject();
  }
};

export { resetPassword };
