import React, { useEffect, useState } from 'react';

import MenuCategories from './components/MenuCategories';
import TrailCard from '../../components/TrailCard';
import PreFooter from '../../components/PreFooter';

import Trail from '../../models/trail';
import { getTrails } from '../../services/trail';

import { Container, Content, TrailsContent } from './style';

import { toast } from 'react-toastify';
import GridShimmer from '../../components/GridShimmer';
import MobileCategories from './components/MobileCategories';

const Trails: React.FC = () => {
  const [trails, setTrails] = useState([] as Trail[]);
  const [currentCategoryId, setCurrentCategoryId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let categoryFilter;

        if (currentCategoryId && currentCategoryId !== '') {
          categoryFilter = { category: currentCategoryId };
        }

        const trails = await getTrails({ ...categoryFilter, is_active: true });
        if (trails) {
          setTrails(trails);
        }
      } catch (e) {
        toast.error('Erro ao carregar as trilhas disponíveis.');
      }
      setIsLoading(false);
    })();
  }, [currentCategoryId]);

  return (
    <>
      <Container>
        <div className="content">
          <h2>Todas as trilhas</h2>

          <Content>
            <MenuCategories
              onClick={categoryId => setCurrentCategoryId(categoryId)}
              selectedCategory={currentCategoryId}
            />

            <MobileCategories
              onClick={categoryId => setCurrentCategoryId(categoryId)}
              selectedCategory={currentCategoryId}
            />

            <TrailsContent>
              {isLoading ? (
                <div>
                  <GridShimmer />
                </div>
              ) : (
                <>
                  {trails && trails.length ? (
                    trails.map(trail => (
                      <TrailCard key={trail.id} trail={trail} />
                    ))
                  ) : (
                    <h4 style={{ padding: '15px' }}>
                      Não foram encontradas trilhas nessa categoria.
                    </h4>
                  )}
                </>
              )}
            </TrailsContent>
          </Content>
        </div>
      </Container>
      <PreFooter />
    </>
  );
};

export default Trails;
