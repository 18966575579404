import React from 'react';
import { useHistory } from 'react-router-dom';
import { BiCheck, BiLock } from 'react-icons/bi';
import { AiOutlinePlayCircle } from 'react-icons/ai';

import Content from '../../../../../../../../models/content';

import {
  TrailCourseContentContainer,
  TrailCourseContentDuration,
  TrailCourseContentFluxIndicator,
  TrailCourseContentFluxIndicatorContainer,
  TrailCourseContentTitle,
} from './style';
import { convertToDuration } from '../../../../../../../../helpers/seconds-to-time';

interface TrailModuleContentProps {
  content: Content;
  isSelected: boolean;
  trailAlreadyStarted: boolean;
}

const TrailModuleContent: React.FC<TrailModuleContentProps> = ({
  content,
  isSelected,
  trailAlreadyStarted,
}) => {
  const history = useHistory();

  const changeContent = () => {
    if (trailAlreadyStarted) {
      history.push(
        `/trails/${content.trailId}/modules/${content.courseId}/contents/${content.id}`,
      );
    }
  };

  return (
    <TrailCourseContentContainer
      onClick={changeContent}
      className={`${!trailAlreadyStarted ? 'not-allowed' : ''}`}
    >
      <TrailCourseContentFluxIndicatorContainer>
        <TrailCourseContentFluxIndicator
          className={`${
            isSelected || content.alreadyFinished ? 'selected' : ''
          } ${!content.isLastOfCourse && 'flux-bar'}`}
        >
          {!trailAlreadyStarted ? (
            <BiLock size={20} color="#555" />
          ) : isSelected ? (
            <AiOutlinePlayCircle size={22} />
          ) : content.alreadyFinished ? (
            <BiCheck size={20} />
          ) : (
            <></>
          )}
        </TrailCourseContentFluxIndicator>
      </TrailCourseContentFluxIndicatorContainer>
      <TrailCourseContentTitle className={`${isSelected ? 'selected' : ''}`}>
        {content.title}
      </TrailCourseContentTitle>
      <TrailCourseContentDuration>
        {`${convertToDuration(content.duration)}`}
      </TrailCourseContentDuration>
    </TrailCourseContentContainer>
  );
};

export default TrailModuleContent;
