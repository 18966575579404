import * as Style from './styles';

const Term = () => {
  return (
    <Style.Container>
      <h1>Termo de Uso do Portal Construir</h1>

      <p>Olá! Seja bem-vindo (a) ao Portal Construir!</p>
      <p>
        A seguir estão descritas as regras aplicáveis à utilização do Portal
        Construir, que é de propriedade da, Saint Gobain Distribuição Brasil
        Ltda., sociedade inscrita no CNPJ sob o nº 03.840.986/0010-97, com sede
        na AV. PRESIDENTE CASTELO BRANCO, 6201, ÁGUA BRANCA, na cidade de São
        Paulo, Estado do São Paulo, CEP 5034000.
      </p>
      <p>
        O Portal Construir é a plataforma de aprendizagem on line da Telhanorte
        Tumelero, onde os usuários terão acesso a conteúdos para desenvolvimento
        pessoal e profissional.
      </p>
      <p>
        O cadastro para uso do Portal Construir é realizado no primeiro acesso
        do usuário, que deverá acessar com sua matrícula da empresa. Será
        permitido um único cadastramento por Usuário, devendo o acesso,
        visualização e uso do Portal Construir ser feito pelo Usuário em caráter
        voluntário e intransferível.
      </p>
      <p>
        A Seguir apresentaremos as principais regras que devem ser observadas
        pelos usuários, desse modo a aceitação livre, expressa e informada sem
        quaisquer reservas com relação aos itens deste termo, dar-se por meio do
        clique no botão “Aceito os Termos”. Caso o usuário não concorde com
        quaisquer das disposições desses Termos, deverá se abster de utilizar o
        Portal Construir.{' '}
      </p>
      <ul>
        <li>
          <h3>1. Informações Gerais</h3>
          <p>
            1.1. O Portal Construir e suas funcionalidades são apresentados aos
            usuários na maneira como estão disponíveis com acesso restrito
            mediante digitação de senha pessoal que reconhece e autentica a sua
            identidade podendo passar por constantes aprimoramentos de
            atualizações.
          </p>
          <p>
            1.2. Eventuais procedimentos de manutenção serão informados pelos
            meios de comunicação oficiais, exemplo: Conecta e E-mail.
          </p>
          <p>
            1.3. Dentro do Portal Construir o usuário terá acesso a diversos
            conteúdos, em tem integral, podendo o usuário acessá-lo a qualquer
            momento, no entanto a Telhanorte Tumelero está isenta de qualquer
            obrigatoriedade legal caso o usuário venha acessar tais materiais ou
            qualquer outra funcionalidade disponível no Portal Construir fora do
            seu horário de trabalho, considerando que o Portal Construir é de
            uso voluntário e pode ser acessado na jornada normal de trabalho.
          </p>
          <p>
            1.4. O usuário se compromete durante a utilização do Portal
            Construir, respeitar as boas práticas, incluindo, cuidados com a
            linguagem e postura ao interagir com demais usuários, publicados em
            fóruns ou avaliações, podendo ser responsabilizado civil e/ou
            criminalmente por quais uso indevido deste conteúdo.
          </p>
          <p>
            1.5. A TELHANORTE TUMELERO poderá, sem prévio aviso, bloquear e
            cancelar o acesso ao Portal Construir quando verificar que o Usuário
            praticou algum ato ou mantenha conduta que (i) viole as leis e
            regulamentos federais, estaduais e/ou municipais, (ii) contrarie as
            regras destes Termos de Uso, (iii) viole os princípios da companhia,
            (iv) seja desligado de suas funções na companhia.
          </p>
          <p>
            1.6. Toda e qualquer ação executada ou conteúdo publicado pelo
            Usuário durante o uso do Portal Construir será de sua exclusiva e
            integral responsabilidade, devendo isentar e indenizar a TELHANORTE
            TUMELERO de quaisquer reclamações, prejuízos, perdas e danos
            causados a TELHANORTE TUMELERO em decorrência de tais ações ou
            manifestações.
          </p>
          <p>
            1.7. O Usuário autoriza a TELHANORTE TUMELERO ou terceiros por ela
            indicados, a utilizar, por prazo indeterminado, as informações
            fornecidas no ato do cadastro e durante o uso do Portal Construir,
            para fins estatísticos e envio de material publicitário,
            newsletters, informes, etc.
          </p>
          <p>
            1.8. A TELHANORTE TUMELERO se reserva o direito de incluir, excluir
            ou alterar os conteúdos e funcionalidades do Portal Construir, bem
            como suspendê-lo temporariamente ou cancelá-lo, a qualquer momento,
            independentemente de aviso-prévio ao Usuário. Da mesma forma, poderá
            modificar estes Termos de Uso, cuja versão mais recente estará
            sempre disponível para consulta no Portal Construir.
          </p>
          <p>
            1.9. A utilização do Portal Construir fora do ambiente corporativo
            Telhanorte Tumelero não caracteriza horas de trabalho ou horas
            extras de trabalho. Devido ao caráter de acesso voluntário, nenhum
            tipo de remuneração pode ser exigida pelo usuário mediante
            decorrente de qualquer tipo de interação com o Portal Construir e
            suas funcionalidades.{' '}
          </p>
          <p>
            1.10. Os cursos obrigatórios deverão ser realizados dentro do
            horário de trabalho do usuário, isentando de quaisquer
            responsabilidades que caracterize horas de trabalho ou horas extras,
            o acesso fora da jornada de trabalho na Telhanorte Tumelero e
            estando sujeito as medidas disciplinares que a lei autoriza, se
            forem observados a realização de treinamentos fora do horário de
            trabalho. Os cursos obrigatórios também poderão ser realizados em
            equipamento da empresa, destinado a treinamento para o caso de
            colaboradores que optarem por não baixar o Portal Construir.
          </p>
          <p>
            1.11. As informações solicitadas ao Usuário no momento do cadastro
            serão utilizadas pela TELHANORTE TUMELERO somente para os fins
            previstos nestes Termos de Uso e em nenhuma circunstância, tais
            informações serão cedidas ou compartilhadas com terceiros, exceto
            por ordem judicial ou de autoridade competente.
          </p>
        </li>
      </ul>
      <p>
        Fica eleito o Foro da Comarca da cidade de São Paulo, Estado de São
        Paulo, para dirimir quaisquer questões decorrentes destes Termos de Uso,
        que será regido pelas leis brasileiras.
      </p>
    </Style.Container>
  );
};

export default Term;
