import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNumberOfPages, DOTS } from '../../../../hooks/useNumberOfPages';

import ProfileMenu from '../../components/ProfileMenu';
import MobileMenuDropdown from '../../components/MobileMenuDropdown';

import { Container, CertificatesList } from './style';
import CertificateCard from '../../../../components/CertificateCard';
import CertificatesShimmer from './components/Skeleton';
import { checkCertificates, ICertificate } from '../../../../services/certificate';
import { DefaultTablePagination } from '../../../../components/DefaultTable/style';
import DefaultButton from '../../../../components/Buttons/DefaultButton';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';

const Certificates: React.FC = () => {
  const [certificates, setCertificates] = useState<ICertificate[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPageNumber, setLastPageNumber] = useState<number>(0);

  const usePagination = true;
  const itemsPerPage = 10;

  const getCertificates = useCallback(async () => {
    setIsLoading(true);

    const localCertificates = await checkCertificates();

    setCertificates(localCertificates);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getCertificates();
  }, [getCertificates]);

  let totalCount = certificates.length;
  const paginationRange = useNumberOfPages({
      currentPage,
      totalCount,
      pageSize: itemsPerPage,
  });

  const itemsToShow = useMemo(() => {
    return usePagination
      ? [...certificates].splice(currentPage * itemsPerPage, itemsPerPage)
      : certificates;
}, [certificates, itemsPerPage, currentPage])

  const numberOfPages = useMemo(() => {
    return Math.ceil(totalCount / itemsPerPage);
  }, [certificates, itemsPerPage, currentPage]);

  const changeCurrentPage = (newPage: any) => {
    setCurrentPage(newPage -1)
  };

  useEffect(() => {
    if (paginationRange !== undefined && paginationRange.length) {
      const sizePagination = paginationRange.length
      const lastPage = paginationRange[sizePagination -1] as number
      setLastPageNumber(lastPage)
    }
  }, [paginationRange]);

  return (
    <Container className="content">
      <ProfileMenu />

      <div>
        <MobileMenuDropdown className="dropdown" />
        <h2>Certificados</h2>
        {isLoading ? (
          <CertificatesShimmer />
        ) : (
          <CertificatesList>
            {itemsToShow && itemsToShow.length ? (
              <div>
                  {
                    itemsToShow.map(certificate => (
                      <CertificateCard key={certificate.certificate_id} course={certificate} />
                    ))
                  }
                  {
                  usePagination && numberOfPages > 1 && (
                    <DefaultTablePagination>
                      <DefaultButton
                        className="medium white"
                        onClick={() => changeCurrentPage(currentPage)}
                        disabled={currentPage <= 0}
                      >
                        <AiOutlineLeft size={18} />
                      </DefaultButton>
                      {paginationRange?.length && paginationRange
                        .map((pageNumber, index) => {
                          return (
                            (
                              <DefaultButton
                                key={index}
                                className="white medium"
                                onClick={() => pageNumber !== DOTS && changeCurrentPage(pageNumber)}
                                style={
                                  currentPage +1 === pageNumber
                                    ? { background: 'var(--primary-color)', color: 'white' }
                                    : {}
                                }
                                disabled={pageNumber > numberOfPages}
                              >
                                {pageNumber}
                              </DefaultButton>
                            )
                          )
                        })}
                      <DefaultButton
                        className="medium white"
                        onClick={() => changeCurrentPage(currentPage + 2)}
                        disabled={(currentPage + 1) >= lastPageNumber}
                      >
                        <AiOutlineRight size={18} />
                      </DefaultButton>
                    </DefaultTablePagination>
                    )
                  }
              </div>
            ) : (
              <h4 style={{ padding: '15px' }}>
                Não foram encontrados cursos concluídos.
              </h4>
            )}
          </CertificatesList>
        )}
      </div>
    </Container>
  );
};

export default Certificates;
