import styled from 'styled-components';

export const Container = styled.aside`
  width: 250px;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 1400px) {
    position: relative;
  }
`;

export const CategoryItem = styled.button`
  cursor: pointer;
  border: 0;
  background: none;
  font-size: var(--font-large);
  color: var(--dark-gray);
  display: block;
  margin-top: 40px;
  text-align: left;
  position: relative;

  > div {
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:after {
    content: '';
    width: 6px;
    height: 0px;
    opacity: 0;
    left: -39px;
    top: 0;
    position: absolute;
    transition: all 0.2s ease-in-out;
    background: #ffab00;
  }

  &:hover,
  &.selected {
    font-weight: 500;
    color: #000;

    &:after {
      height: 100%;
      opacity: 1;
    }
  }
`;

export const SubCategoriesItems = styled.div`
  padding-left: 40px;
  display: flex;
  flex-direction: column;

  span {
    font-size: var(--font-large);
    color: var(--dark-gray);
    margin-top: 36px;
  }
`;

export const CategoryItemContainer = styled.div`
  display: flex;

  button {
    border: none;
    background: none;
    cursor: pointer;

    svg {
      display: flex;
      font-size: var(--font-large);
      color: var(--dark-gray);
      margin-top: 36px;
    }
  }
`;
