import React, { useState } from 'react';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import Category from '../../../models/category';

import {
  Container,
  CategoryItem,
  SubCategoriesItems,
  CategoryItemContainer,
} from './style';

interface IMenuCategories {
  onClick: (categoryId: string) => void;
  selectedCategory: string;
  category: Category;
}

const CategoryItemOnly: React.FC<IMenuCategories> = ({
  category,
  onClick,
  selectedCategory,
}) => {
  const [showSubCategories, setShowSubCategories] = useState(false);

  return (
    <Container>
      <CategoryItemContainer>
        <CategoryItem
          onClick={() => onClick(category.id)}
          className={`${selectedCategory === category.id && 'selected'}`}
        >
          <div>
            <span>{category.title}</span>
          </div>
        </CategoryItem>
        {category.child_categories && category.child_categories.length ? (
          <button onClick={() => setShowSubCategories(!showSubCategories)}>
            {showSubCategories ? (
              <RiArrowUpSLine size={28} />
            ) : (
              <RiArrowDownSLine size={28} />
            )}
          </button>
        ) : null}
      </CategoryItemContainer>
      {showSubCategories && (
        <SubCategoriesItems>
          {category.child_categories && category.child_categories.length ? (
            category.child_categories.map((category: any) => (
              <CategoryItem
                onClick={() => onClick(category.category_id)}
                className={`${
                  selectedCategory === category.category_id && 'selected'
                }`}
              >
                {category.title}
              </CategoryItem>
            ))
          ) : (
            <></>
          )}
        </SubCategoriesItems>
      )}
    </Container>
  );
};

export default CategoryItemOnly;
