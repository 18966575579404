const secondsToTimeText = (seconds: number, extended?: boolean): string => {
  seconds = seconds || 0;
  const minutes = Math.floor(seconds / 60);

  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);

    return ` ${hours} hora${hours > 1 ? 's' : ''}`;
  } else {
    return ` ${minutes} ${extended ? 'minuto' : 'min'}${
      minutes > 1 ? 's' : ''
    }`;
  }
};

export { secondsToTimeText };
