import React, { useEffect, useState } from 'react';

import MenuCategories from '../../components/MenuCategories';
import PreFooter from '../../components/PreFooter';

import { getTrails } from '../../services/trail';

import { Container, ContentItems, CoursesContent } from './style';

import { toast } from 'react-toastify';
import GridShimmer from '../../components/GridShimmer';
import CourseCardItem from '../../components/CourseCardItem';
import Content from '../../models/content';
import { getContents } from '../../services/content';
import MobileCategories from '../../components/MobileCategories';

const Courses: React.FC = () => {
  const [courses, setCourses] = useState([] as Content[]);
  const [currentCategoryId, setCurrentCategoryId] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let category_id;

        if (currentCategoryId && currentCategoryId !== '') {
          category_id = [currentCategoryId];
        }

        const courses = await getContents({
          is_library: true,
          category_id,
          is_active: true,
        });
        if (courses) {
          setCourses(courses);
        }
      } catch (e) {
        toast.error('Erro ao carregar os cursos disponíveis.');
      }
      setIsLoading(false);
    })();
  }, [currentCategoryId]);

  return (
    <>
      <Container>
        <div className="content">
          <h2>Todos os cursos</h2>

          <ContentItems>
            <MenuCategories
              onClick={categoryId => setCurrentCategoryId(categoryId)}
              selectedCategory={currentCategoryId}
            />

            <MobileCategories
              onClick={categoryId => setCurrentCategoryId(categoryId)}
              selectedCategory={currentCategoryId}
            />

            <CoursesContent>
              {isLoading ? (
                <div>
                  <GridShimmer />
                </div>
              ) : (
                <>
                  {courses && courses.length ? (
                    courses.map(course => (
                      <CourseCardItem key={course.id} course={course} />
                    ))
                  ) : (
                    <h4 style={{ padding: '15px' }}>
                      Não foram encontrados cursos nessa categoria.
                    </h4>
                  )}
                </>
              )}
            </CoursesContent>
          </ContentItems>
        </div>
      </Container>
      <PreFooter />
    </>
  );
};

export default Courses;
