import { addHours, getHours, getMinutes, getSeconds } from 'date-fns';

const convertToDuration = (secondsAmount: number) => {
  const normalizeTime = (time: string): string =>
    time.length === 1 ? `0${time}` : time;

  const SECONDS_TO_MILLISECONDS_COEFF = 1000;
  const MINUTES_IN_HOUR = 60;

  const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

  const hoursOutput = Number(hours) > 0 ? `${hours}:` : '';

  return `${hoursOutput}${minutes}:${seconds} ${
    Number(hoursOutput.replace(':', '')) > 0 ? 'h' : ''
  } ${
    Number(hoursOutput.replace(':', '')) <= 0 && Number(minutes) > 0
      ? 'mins'
      : ''
  } ${
    Number(hoursOutput.replace(':', '')) <= 0 &&
    Number(minutes) <= 0 &&
    Number(seconds) >= 0
      ? 's'
      : ''
  }`;
};

export { convertToDuration };
