import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { Container, Content, MenuItem } from './style';

interface IMobileMenuDropdownProps {
  className?: string;
}

const MobileMenuDropdown: React.FC<IMobileMenuDropdownProps> = ({
  className,
}) => {
  const location = useLocation();

  const [showOptions, setShowOptions] = useState(false);
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    switch (location.pathname) {
      case '/profile':
        setCurrentPage('Meus dados');
        break;
      case '/ongoing-courses':
        setCurrentPage('Em andamento');
        break;
      case '/favorite-courses':
        setCurrentPage('Favoritos');
        break;
      case '/finished-contents-and-trails':
        setCurrentPage('Finalizados');
        break;
      case '/certificates':
        setCurrentPage('Certificados');
        break;
      case '/uploaded-files':
        setCurrentPage('Arquivos enviados');
        break;
    }
  }, [location.pathname]);

  return (
    <Container className={className}>
      <header
        onClick={() => setShowOptions(!showOptions)}
        className={`${showOptions && 'open'}`}
      >
        <h4>{currentPage}</h4>
        {showOptions ? (
          <IoIosArrowUp size={20} />
        ) : (
          <IoIosArrowDown size={20} />
        )}
      </header>

      {showOptions && (
        <Content>
          <MenuItem
            to="/profile"
            className={`${location.pathname === '/profile' && 'active'}`}
          >
            Meus dados
          </MenuItem>

          <MenuItem
            to="/ongoing-courses"
            className={`${
              location.pathname === '/ongoing-courses' && 'active'
            }`}
          >
            Em andamento
          </MenuItem>

          <MenuItem
            to="/favorite-courses"
            className={`${
              location.pathname === '/favorite-courses' && 'active'
            }`}
          >
            Favoritos
          </MenuItem>

          <MenuItem
            to="/finished-contents-and-trails"
            className={`${
              location.pathname === '/finished-contents-and-trails' && 'active'
            }`}
          >
            Finalizados
          </MenuItem>

          <MenuItem
            to="/certificates"
            className={`${location.pathname === '/certificates' && 'active'}`}
          >
            Certificados
          </MenuItem>
          <MenuItem
            to="/uploaded-files"
            className={`${location.pathname === '/uploaded-files' && 'active'}`}
          >
            Arquivos enviados
          </MenuItem>
        </Content>
      )}
    </Container>
  );
};

export default MobileMenuDropdown;
