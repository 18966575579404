import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { hoursToMinutes, minutesToHours } from 'date-fns';
import { HiOutlinePencil } from 'react-icons/hi';
import * as Yup from 'yup';

import ProfileMenu from '../../components/ProfileMenu';

import DefaultButton from '../../../../components/Buttons/DefaultButton';
import DefaultInput from '../../../../components/Inputs/DefaultInput/DefaultInput';
import InputPassword from '../../../../components/Inputs/InputPassword';
import CourseCardItem from '../../../../components/CourseCardItem';

import PerfilAvatar from '../../../../assets/perfil.png';

import { courses } from '../../../Home/mock/courses';

import { getContents } from '../../../../services/content';
import Content from '../../../../models/content';
import Slider from 'react-slick';
import ModalSkeleton from '../../../Home/components/Skeleton/Cards';
import SliderPrevButton from '../../../../components/SliderButtons/SliderPrevButton';
import SliderNextButton from '../../../../components/SliderButtons/SliderNextButton';
import { coursesSliderSettings } from '../../../../config/sliderSettings';

import {
  Container,
  Header,
  ContentContainer,
  AvatarInput,
  RecommendedCourses,
  ChangeDataForm,
  ChangePasswordForm,
  NewPasswordContent,
  ExitButton,
} from './style';

import MobileMenuDropdown from '../../components/MobileMenuDropdown';
import User from '../../../../models/user';
import InputWithMask from '../../../../components/Inputs/InputWithMask';
import { FormHandles } from '@unform/core';
import { resetPassword } from '../../../../services/reset-password';
import { toast } from 'react-toastify';
import getValidationErrors from '../../../../helpers/getValidationErrors';
import { BiUserCircle } from 'react-icons/bi';
import { changeAvatar } from '../../../../services/avatar';
import { getWatchTime, TimeWatched } from '../../../../services/user';
import { changePhoneNumber } from '../../../../services/update-phone';
import { useHistory } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';

const schema = Yup.object().shape({
  new_password_confirm: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.')
    .min(8, 'A senha deve conter no mínimo 8 caracteres.'),
  password_confirmation: Yup.string()
    .oneOf(
      [Yup.ref('new_password_confirm'), null],
      'As senhas informadas não correspondem. Por favor, verifique.',
    )
    .trim()
    .required('Preencha corretamente este campo.'),
});

const schemaPhone = Yup.object().shape({
  cell: Yup.string().trim().required('Preencha corretamente este campo.'),
});

interface IChangePassword {
  new_password_confirm: string;
  registration: string;
  password: string;
}

interface IPhoneNumber {
  cell: string;
}

const UserData: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const phoneFormRef = useRef<FormHandles>(null);
  const [userTimeWatched, setUserTimeWatched] = useState(0);
  const inputRef = useRef(null);

  const [user, setUser] = useState<User>(
    JSON.parse(localStorage.getItem('telhanorte-user') || ''),
  );

  const handleChangePassword = async (
    data: IChangePassword,
    { reset }: any,
  ) => {
    const { registration } = user;
    const { password, new_password_confirm } = data;
    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await resetPassword({
        registration,
        password,
        new_password_confirm,
      }).then(() => {
        toast.success('Senha alterada com sucesso!');
        reset();
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  const handleChangePhoneNumber = async (data: IPhoneNumber) => {
    const { cell } = data;
    const access_token = localStorage.getItem('telhanorte-access-token') || '';

    const formattedPhone = cell
      .replaceAll('-', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(' ', '');

    try {
      phoneFormRef.current?.setErrors({});

      await schemaPhone.validate(data, { abortEarly: false });

      await changePhoneNumber(`+55${formattedPhone}`, access_token).then(() => {
        localStorage.setItem(
          'telhanorte-user',
          JSON.stringify({ ...user, phone_number: `+55${formattedPhone}` }),
        );

        setUser(JSON.parse(localStorage.getItem('telhanorte-user') || ''));

        toast.success('Telefone alterado com sucesso!');
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        phoneFormRef.current?.setErrors(errors);
      } else {
        toast.error('Houve um erro ao atualizar o telefone.');
      }
    }
  };

  const handleAvatarChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('file', e.target.files[0]);

        try {
          const response = await changeAvatar(data);
          toast.success('Avatar atualizado!');

          localStorage.setItem(
            'telhanorte-user',
            JSON.stringify({ ...user, info: { avatar: response } }),
          );

          window.location.reload();
        } catch (err) {
          toast.error(
            'Houve um erro ao atualizar o avatar. Por favor, tente novamente!',
          );
        }
      }
    },
    [],
  );

  const getUserWatchTime = async () => {
    const response = await getWatchTime();

    if (response.hours_watched) {
      setUserTimeWatched(response.hours_watched);
    }
  };

  useEffect(() => {
    getUserWatchTime();
  }, [getWatchTime]);

  const [suggestedCourses, setSuggestedCourses] = useState<Content[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const courses = await getContents({
        is_library: true,
        suggest_by_admin: true,
      });
      setSuggestedCourses(courses);
      setIsLoading(false);
    })();
  }, []);

  const convertMinutes = (time: number): number => {
    const hour = String(time).split('.');
    return Number(`0.${hour[1]}`);
  };

  const logout = () => {
    window.localStorage.removeItem('telhanorte-api-token');
    window.localStorage.removeItem('telhanorte-user');
    history.push('/');
    document.location.reload();
  };

  return (
    <Container className="content">
      <ProfileMenu />

      <ContentContainer>
        <MobileMenuDropdown className="dropdown" />
        <Header>
          <div>
            <h2>Olá, {user.name}!</h2>

            <p>
              {userTimeWatched ? (
                <>
                  Você já realizou{' '}
                  {`${minutesToHours(hoursToMinutes(userTimeWatched))} ${
                    userTimeWatched < 2 ? 'hora' : 'horas'
                  } e ${hoursToMinutes(
                    convertMinutes(userTimeWatched),
                  )} minutos`}{' '}
                  de cursos e treinamentos. Parabéns!
                </>
              ) : (
                <>
                  Você já realizou 0 horas e 0 minutos de cursos e treinamentos.
                  Parabéns!
                </>
              )}
            </p>
          </div>

          <AvatarInput>
            {user.info.avatar && user.info.avatar !== '' ? (
              <img src={user.info.avatar} alt="Avatar" />
            ) : (
              <BiUserCircle color="#BDBDBD" />
            )}
            <label htmlFor="avatar">
              <HiOutlinePencil size={20} color="#fff" />

              <input
                type="file"
                name="avatar"
                id="avatar"
                onChange={handleAvatarChange}
                ref={inputRef}
              />
            </label>
          </AvatarInput>
          <ExitButton type="button" onClick={logout}>
            <span>Sair</span>
            <FiLogOut size={16} color="#000" />
          </ExitButton>
        </Header>

        <RecommendedCourses>
          <h3>Cursos recomendados pelo gestor</h3>

          {suggestedCourses && suggestedCourses.length ? (
            <div>
              {isLoading ? (
                <ModalSkeleton />
              ) : suggestedCourses && suggestedCourses.length ? (
                suggestedCourses.map(course => (
                  <>
                    <CourseCardItem key={course.id} course={course} />
                  </>
                ))
              ) : (
                <h4 style={{ width: '100%', padding: '15px' }}>
                  Não foram encontrados cursos sugeridos.
                </h4>
              )}
            </div>
          ) : (
            <div />
          )}
        </RecommendedCourses>

        <ChangeDataForm onSubmit={handleChangePhoneNumber} ref={phoneFormRef}>
          <h3>Meus dados</h3>

          <div>
            <DefaultInput
              name="registration"
              type="text"
              label="Matrícula"
              className="userDataInput readOnly"
              value={user.registration}
              readOnly
            />
            <InputWithMask
              name="cell"
              type="text"
              label="Telefone"
              className="userDataInput"
              mask={'(99) 99999-9999'}
              default_value={user.phone_number?.split('+55')[1]}
            />

            {/* <DefaultButton type="submit">Salvar</DefaultButton> */}
          </div>
        </ChangeDataForm>

        <ChangePasswordForm onSubmit={handleChangePassword} ref={formRef}>
          <h3>Alterar minha senha</h3>

          <div>
            <InputPassword
              name="password"
              label="Senha atual"
              className="userDataInput"
            />

            <NewPasswordContent>
              <InputPassword
                name="new_password_confirm"
                label="Nova senha"
                className="userDataInput"
              />
              <InputPassword
                name="password_confirmation"
                label="Confirmar nova senha"
                className="userDataInput"
              />

              <DefaultButton type="submit">Salvar nova senha</DefaultButton>
            </NewPasswordContent>
          </div>
        </ChangePasswordForm>
      </ContentContainer>
    </Container>
  );
};

export default UserData;
