import styled from 'styled-components';

const AnnotationsContainer = styled.div`
  width: 100vw;
`;

const AnnotationTitle = styled.h2`
  font-size: var(--font-largest);
  margin-top: 10px;
  margin-bottom: 15px;
`;

const AnnotationTrailCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * {
    margin-bottom: 10px;
  }
`;

export {
  AnnotationsContainer,
  AnnotationTitle,
  AnnotationTrailCardsContainer,
};
