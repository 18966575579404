import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import { Container, MobileDepositions } from './style';

import DepositionCard from '../../../../components/DepositionCard';
import SliderNextButton from '../../../../components/SliderButtons/SliderNextButton';
import SliderPrevButton from '../../../../components/SliderButtons/SliderPrevButton';

import { depositionsSliderSettings } from '../../../../config/sliderSettings';

import { getReviews as getReviewsService } from '../../../../services/content-review';

import Deposition from '../../../../models/depositions';

const Depositions: React.FC = () => {
  const [depositions, setDepositions] = useState([] as Deposition[]);

  const getReviews = async () => {
    const localDepositions = await getReviewsService();

    setDepositions(localDepositions);
  };

  useEffect(() => {
    getReviews();
  }, []);

  return (
    <Container>
      <h2>Depoimentos</h2>
      <div className="content">
        <Slider
          {...depositionsSliderSettings}
          prevArrow={<SliderPrevButton />}
          nextArrow={<SliderNextButton />}
        >
          {depositions &&
            depositions.map(deposition => (
              <div>
                <DepositionCard
                  key={deposition.description}
                  deposition={deposition}
                />
              </div>
            ))}
        </Slider>
      </div>
      <MobileDepositions>
        {depositions &&
          depositions.map(deposition => (
            <div>
              <DepositionCard
                key={deposition.description}
                deposition={deposition}
              />
            </div>
          ))}
      </MobileDepositions>
    </Container>
  );
};

export default Depositions;
