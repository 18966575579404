import React from 'react';

import ContentLoader from 'react-content-loader';

const PostListLoader: React.FC = () => {
  return (
    <ContentLoader
      style={{ marginTop: 16 }}
      width={'100%'}
      height={640}
      backgroundColor="#e3e3e3"
    >
      <rect rx="0" ry="0" y="0" width="100%" height="9%" />
      <rect rx="0" ry="" y="10%" width="100%" height="9%" />
      <rect rx="0" ry="" y="20%" width="100%" height="9%" />
      <rect rx="0" ry="" y="30%" width="100%" height="9%" />
      <rect rx="0" ry="" y="40%" width="100%" height="9%" />
      <rect rx="0" ry="" y="50%" width="100%" height="9%" />
      <rect rx="0" ry="" y="60%" width="100%" height="9%" />
      <rect rx="0" ry="" y="70%" width="100%" height="9%" />
      <rect rx="0" ry="" y="80%" width="100%" height="9%" />
      <rect rx="0" ry="" y="90%" width="100%" height="9%" />
    </ContentLoader>
  );
};

export default PostListLoader;
