import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.2s linear;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Modal = styled.div`
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 48px;
  width: 90%;
  max-width: 900px;
  border-radius: 5px;
  height: 80%;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch !important;

  &::-webkit-scrollbar {
    width: 6px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-color); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
`;

export const Body = styled.div`
  margin-bottom: 20px;
`;

export const Button = styled.button`
  display: block;
  margin: auto;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 1.4rem;
  width: 100px;
  cursor: pointer;
`;
