import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  width: 260px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:after {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 20.31%,
      rgba(255, 255, 255, 0.1) 100%
    );
    position: absolute;
    content: '';
    height: 140px;
    width: 100%;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 140px;
    object-fit: cover;
    margin-bottom: -15px;
  }

  div.image {
    width: 100%;
    height: 140px;
    object-fit: cover;
    margin-bottom: -8px;
  }

  h4 {
    max-width: 80%;
    font-size: var(--font-large);
    line-height: 22px;
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 320px;
  }
`;

export const BookmarkButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  top: 13px;
  right: 14px;
  z-index: 1;

  svg.favorite {
    fill: var(--secondary-color);

    path {
      fill-rule: inherit;
    }
  }
`;

export const CompletionPercentage = styled.span`
  font-size: var(--font-regular);
  color: var(--white-font-color);
  position: absolute;
  top: 116px;
  right: 12px;
`;

export const Coursefooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;

  div {
    display: flex;
  }

  span.divider {
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 8px 0px 16px;
  }
`;

export const CourseTime = styled.div`
  font-size: var(--font-regular);
  color: var(--regular-gray);

  span {
    margin-top: 2.5px;
  }

  svg {
    margin-right: 6px;
  }
`;

export const CourseLikes = styled.div`
  font-size: var(--font-regular);
  color: var(--regular-gray);

  button {
    cursor: pointer;
    border: none;
    background: none;
    display: flex;
    align-items: center;
  }

  span {
    margin-top: 2.5px;
  }

  svg {
    margin-right: 6px;
  }
`;
