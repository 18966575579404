import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CourseContent, { PlayerEventListener } from './components/CourseContent';
import BackButton from '../../components/Buttons/BackButton';
import CourseCardItem from '../../components/CourseCardItem';
import Modal from '../../components/Modal';
import { format, isBefore, parseISO } from 'date-fns';
import {
  startContent as startContentService,
  finishContent as finishContentService,
  getContent as getContentService,
  getContents,
} from '../../services/content';
import Content from '../../models/content';
import {
  BtnGroup,
  CourseContainer,
  ModalText,
  OtherCourses,
  PrimaryButton,
  SecondaryButton,
  TrailDownloadCertificate,
  TrailStartExam,
} from './style';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import {
  getExam,
  IExam,
  getAttempts as getAttemptsService,
} from '../../services/exam';
import { BsFillAwardFill } from 'react-icons/bs';
import {
  CertificateData,
  downloadCertificate,
  getAllCertificates,
  setContentCertificate,
} from '../../services/certificate';
import { AiOutlineDownload } from 'react-icons/ai';

interface CourseParams {
  courseId: string;
}

interface ExtendedWindow extends Window {
  hasStartedCourse: boolean;
  hasFinishedCourse: boolean;
}

interface IExamUser {
  exam_user_id: string;
  exam_id: string;
  reference_id: string;
  result: number | null;
  general_attempts: number | null;
  is_quiz: boolean;
  initial_date: string | null;
  final_date: string | null;
}

declare let window: ExtendedWindow;

const Course: React.FC = () => {
  const history = useHistory();
  const { courseId } = useParams() as CourseParams;
  const [nextAttemptsDate, setNextAttemptsDate] = useState<any>();
  const [examUser, setExamUser] = useState<IExamUser[]>([]);

  const [otherCourses, setOtherCourses] = useState<Content[]>([]);
  const [content, setContent] = useState({} as Content);
  const [courseProgress, setCourseProgress] = useState<number>(0);
  const [userApproved, setUserApproved] = useState<boolean>(false);

  const [exam, setExam] = useState<IExam>({} as IExam);
  const [ableToExam, setAbleToExam] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalCertificate, setShowModalCertificate] = useState(false);

  const [certificate, setCertificate] = useState<CertificateData[] | undefined>();

  function isEmpty(obj: Object): boolean {
    if (!obj) return true;
    return !Object.entries(obj).length;
  }

  const [visible, setVisible] = useState(false);
  const getAttempts = useCallback(async () => {    
    if (content.exam) {    
      if ((exam.info && exam.info.enable_quiz) || content.alreadyFinished) {
        getAttemptsService(content.exam, courseId).then((data: any) => {
          setExamUser(data.exam_user);

          const { alreadyStarted, alreadyFinished } = content;
          const totalAttempts = data.user_total_attempts;
          
          if (data && data.date_allow_new_attempts) {
            setNextAttemptsDate(data.date_allow_new_attempts);
          }

          let isAble = false;
          const attempts = data.exam_user.length;
          const examUser = data.exam_user;

          if (!alreadyStarted) {
            if (isEmpty(examUser)) {
              isAble = true;

            } else {
              isAble =
                examUser[0].result === null && examUser[0].final_date === null;
            }
          } else if (alreadyFinished) {            
            if (attempts === totalAttempts) {
              isAble = false;
              const { amount, points } = exam.amount_questions[0];
              if (examUser && examUser.length) {
                const bestScore = examUser
                  .map(({ result }: any) => result)
                  .reduce((a: any, b: any) => (a > b ? a : b));

                const total = amount * points;

                const minScore = total * (Number(exam.average) / 100);
                
                if (bestScore >= minScore) {
                  setUserApproved(true);
                }
              }
            } else {
              if (isEmpty(exam)) {
                return false;
              }

              if (attempts === 1) {
                isAble = true;
              } else {               
                if (examUser && examUser.length) {
                  isAble = attempts < totalAttempts;
                }
              }
            }

            if (
              (examUser &&
                examUser[0] &&
                examUser[0].result === null &&
                examUser[0].final_date === null) ||
              attempts < totalAttempts
            ) {
              isAble = true;
            }
          }
          
          setAbleToExam(isAble);

          if (exam.info.enable_quiz && attempts < 2) {
            setVisible(true);
          }

          if ((isAble && exam.info.enable_quiz && attempts < 2) || (isAble && !exam.info?.enable_quiz && attempts < 1)) {
            setVisible(true);
          }
        });
      }
    }
  }, [
    content.exam,
    content.alreadyStarted,
    content.alreadyFinished,
    exam,
    courseId,
    setAbleToExam,
    setVisible,
    getAttemptsService,
    setUserApproved
  ]);

  const startExam = () => {
    history.push(`/course-exam/${courseId}/${content.exam}/content`);
  };

  const isLoading = useMemo(() => {
    return !content || !content.id;
  }, [content]);

  const getEventListeners = async (player: PlayerEventListener) => {
    if (window.location.href.endsWith(`courses/${content.id}`)) {
      if (content && content.id) {
        switch (player.event) {
          case 'onProgress':
            if (hasReachedCompletionTime(player.duration, player.eventParam)) {
              await finishCourse();
            }

            updateCourseWatchTime(player);
            break;

          case 'onStart':
            await startCourse();
            break;

          case 'onFinish':
            if (!!content.exam && !content.alreadyFinished) {
              setAbleToExam(true);
              setVisible(true);
            }
            break;

          default:
            break;
        }
      }
    }
  };

  const hasReachedCompletionTime = (duration: number, currentTime: number) => {
    const totalDurationInSecs = duration;
    const completionRate = 1;

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const updateCourseWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setCourseProgress(eventParam);
    }
    return null;
  }, []);

  const startCourse = async () => {
    if (!window.hasStartedCourse && !content.alreadyStarted) {
      try {
        window.hasStartedCourse = true;
        content.alreadyStarted = true;
        await startContentService(courseId || '');
      } catch (error: any) {
        if (
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message ===
            'É necessário finalizar a prova antes de assistir ao curso.') ||
          error.response.data.message ===
          'É necessário realizar a prova antes de assistir ao curso.'
        ) {
          history.push(`/course-exam/${courseId}/${content.exam}/content`);
        }

        window.hasStartedCourse = false;
        content.alreadyStarted = false;
      }
    }
  };

  const finishCourse = useCallback(async () => {
    if (!window.hasFinishedCourse && !content.alreadyFinished) {
      try {
        window.hasFinishedCourse = true;
        content.alreadyFinished = true;

        setShowModal(true);

        finishContentService(courseId || '');

        if (!!content.exam && !content.alreadyFinished) {
          setAbleToExam(true);
          setVisible(true);
        }

        if (
          content.alreadyFinished &&
          content.info.no_certificate === false &&
          !content.exam
        ) {
          setContentCertificate(content.id);
          setShowModalCertificate(true);
        }
      } catch (error) {
        window.hasFinishedCourse = false;
        content.alreadyFinished = false;
      }
    }
  }, [content, content.exam, window.hasFinishedCourse]);

  useEffect(() => {
    getAttempts();
  }, [getAttempts]);

  const getCourse = async () => {
    const localContent = await getContentService(courseId);
    const localCertificate = await getAllCertificates();
    
    if (localContent.exam) {
      const examResponse: IExam = await getExam(localContent.exam);
      setExam(examResponse);
    }

    if (localContent && localContent.id) {
      setContent(localContent);
    }

    if (localCertificate?.length) {
      const userCertificate = localCertificate.filter(certificate => certificate.reference_id === localContent.id);
      setCertificate(userCertificate);
    }
  };

  const checkResult = useCallback((result): boolean => {
    const { amount_questions, average } = exam;
    const { amount } = amount_questions[0];
    
    const minValue = Math.ceil((amount * Number(average)) / 100);
    const isApproved = result >= minValue;

    return isApproved;
  }, [exam]);

  const checkApproved = useCallback(async () => {
    if (certificate?.length) {
      setUserApproved(true);
      return;
    }
    
    if (
      certificate?.length === 0 &&
      content.alreadyFinished &&
      content.info.no_certificate === false &&
      content.exam === undefined
    ) {
      setContentCertificate(content.id);
      setUserApproved(true);
      return;
    }
      
    if (content?.exam && examUser?.length) {
      const completedExam = examUser.find((exam: IExamUser) => exam.result !== null && exam.final_date !== null);
      const approval = completedExam !== undefined && checkResult(completedExam.result);
      
      if (
        certificate?.length === 0 &&
        content.alreadyFinished &&
        content.info.no_certificate === false &&
        approval
      ) {
        setContentCertificate(content.id);
        setUserApproved(approval);
        return;
      }
    }
  }, [certificate, checkResult, examUser, content])

  useEffect(() => {
    getCourse();
  }, [courseId]);

  useEffect(() => {
    window.hasStartedCourse = false;
    window.hasFinishedCourse = false;

    if (content && content.id) {
      window.hasStartedCourse = content.alreadyStarted;
      window.hasFinishedCourse = content.alreadyFinished;
    }
  }, [courseId, content]);

  useEffect(() => {
    (async () => {
      try {
        const mostViewedFilters = { is_library: true, most_viewed: true };
        const localCourses = await getContents(mostViewedFilters);
        if (localCourses && localCourses.length) {
          setOtherCourses(localCourses);
        }
      } catch (e) {
        toast.error('Erro ao carregar os cursos sugeridos.');
      }
    })();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [content]);

  useEffect(() => {
    checkApproved(); 
  }, [checkApproved]);

  return !isLoading ? (
    <CourseContainer>
      <Modal
        open={ableToExam && visible}
        setVisibility={() => {
          setVisible(!visible);
        }}
        background={content.thumbnail}
      >
        <div>
          <span>{content.categoryName}</span>
          <h2 style={{ wordBreak: 'break-word' }}>{content.title}</h2>
        </div>

        {!window.hasStartedCourse && !content.alreadyStarted ? (
          <ModalText>
            Para iniciar o curso é necessário realizar uma pequena avaliação, ao
            concluir a avaliação, o curso poderá ser iniciado.
          </ModalText>
        ) : (
          <ModalText>
            Meus parabéns, você chegou ao fim deste curso! Para receber seu
            certificado, você precisa realizar uma rápida avaliação sobre o que
            você aprendeu.
          </ModalText>
        )}

        <BtnGroup>
          <PrimaryButton
            onClick={() =>
              history.push(`/course-exam/${courseId}/${content.exam}/content`)
            }
          >
            Iniciar Avaliação
          </PrimaryButton>

          {window.hasStartedCourse && content && content.alreadyStarted && (
            <SecondaryButton onClick={() => setVisible(false)}>
              Fazer Depois
            </SecondaryButton>
          )}
        </BtnGroup>
      </Modal>

      <>
        <BackButton />
        <div className="content">
          <div>
            <CourseContent
              content={content}
              getEventListeners={getEventListeners}
              progress={courseProgress}
              finished={
                (window.hasFinishedCourse && !content.review) || showModal
              }
              finishCourse={finishCourse}
              startCourse={startCourse}
              examModal={visible || content.exam !== undefined}
            />
          </div>

          <OtherCourses>
            {window.hasStartedCourse === false && ableToExam && (
              <TrailStartExam onClick={startExam}>
                <span>Responder Questionário</span>{' '}
                <BsFillAwardFill size={24} />
              </TrailStartExam>
            )}

            {window.hasFinishedCourse && ableToExam && userApproved === false && (
              <TrailStartExam onClick={startExam}>
                <span>Responder Questionário</span>{' '}
                <BsFillAwardFill size={24} />
              </TrailStartExam>
            )}

            {window.hasFinishedCourse &&
            userApproved &&
            !content.info.no_certificate && (
              <TrailStartExam onClick={() => downloadCertificate(courseId, false)}>
                <span>Você foi Aprovado</span>{' '}
                <BsFillAwardFill size={24} />
              </TrailStartExam>
            )}

            <Modal
              open={content.alreadyFinished && showModalCertificate}
              setVisibility={() => {
                setShowModalCertificate(!showModalCertificate);
              }}
            >
              <div>
                <span>{content.categoryName}</span>
                <h2 style={{ wordBreak: 'break-word' }}>{content.title}</h2>
              </div>
              <ModalText>Meus parabéns, você conclui este curso.</ModalText>

              <BtnGroup>
                <PrimaryButton onClick={() => history.push('/certificates')}>
                  Meus certificados
                </PrimaryButton>

                <SecondaryButton onClick={() => setShowModalCertificate(false)}>
                  Voltar para o curso
                </SecondaryButton>
              </BtnGroup>
            </Modal>
            {!ableToExam &&
              userApproved === false &&
              nextAttemptsDate &&
              isBefore(new Date(), parseISO(nextAttemptsDate)) && (
                <TrailStartExam
                  onClick={() =>
                    toast.error(
                      `Você receberá novas tentativas no dia ${format(
                        parseISO(nextAttemptsDate),
                        "dd/MM/yyyy 'às' HH:mm",
                      )}.`,
                    )
                  }
                >
                  <span>Responder Questionário</span>{' '}
                  <BsFillAwardFill size={24} />
                </TrailStartExam>
              )}

            <h3>Outros cursos</h3>

            {otherCourses &&
              otherCourses
                .filter(course => course.id !== courseId)
                .slice(0, 3)
                .map(course => (
                  <CourseCardItem key={course.id} course={course} />
                ))}
          </OtherCourses>
        </div>
      </>
    </CourseContainer >
  ) : (
    <Loading size={160} />
  );
};

export default Course;
