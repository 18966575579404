import styled from 'styled-components';

export const CourseContainer = styled.div`
  margin-top: 50px;

  .content {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 42px 0 10px 0;

    @media (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 1300px) {
      flex-wrap: wrap;
    }

    @media (max-width: 780px) {
      padding: 0 4px;
    }
  }
`;

export const OtherCourses = styled.div`
  margin: 192px 32px 56px 0;
  h3 {
    font-size: 24px;
    margin-bottom: 48px;
  }
  > div {
    margin-bottom: 48px;
  }
  @media (max-width: 1200px) {
    margin: 48px 0px;
    h3 {
      margin-bottom: 32px;
    }
    > div {
      margin-bottom: 32px;
    }
  }
  @media (max-width: 768px) {
    h3 {
      display: none;
    }
  }
`;

const ModalButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
`;

export const ModalText = styled.p`
  color: #555555;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const PrimaryButton = styled(ModalButton)`
  padding: 16px 32px;
  color: #000;
  background-color: #ffab00;
`;

export const SecondaryButton = styled(ModalButton)`
  color: #444;
  background-color: transparent;
  margin-top: 8.5px;
`;

export const BtnGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GoToExam = styled.button`
  margin-bottom: 24px;
  padding: 10px 30px;
  border: none;
  outline: none;
  background-color: var(--secondary-color);
  color: white;
  font-weight: bold;
  font-size: var(--font-medium);
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    margin-right: 5px;
  }
`;
