import styled from 'styled-components';

export const Container = styled.div`
  width: 70vw;
  margin: 48px 0 auto;

  @media (max-width: 992px) {
    width: 100vw;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    width: 90vw;
  }
`;

export const SearchResultTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: auto;

  svg {
    width: 85px;
    height: 85px;
    margin-right: 72px;
  }

  p {
    font-weight: 500;
    font-size: var(--font-large);
  }

  span {
    font-weight: 700;
    color: var(--primary-color);
    margin: 0 4px;
  }

  @media (max-width: 768px) {
    svg {
      width: 42px;
      height: 72px;
      margin-right: 20px;
    }
  }
`;

export const SearchResultsList = styled.div`
  margin-top: 72px;
  display: flex;
  flex-wrap: wrap;

  > div {
    > div {
      margin-bottom: 52px;
    }
  }

  @media (max-width: 768px) {
    column-gap: 32px;
    justify-content: center;
    margin-top: 46px;
    margin-left: 0;
    width: 100%;

    > div {
      justify-content: center;
      > div {
        margin: 0 0 32px 0;
        width: 100%;
        max-width: 320px;

        img {
          height: 120px;
          object-fit: cover;
        }

        h4 {
          font-size: var(--font-large);
        }

        div:last-child {
          align-items: center;

          span {
            margin-top: 2px;
            font-size: var(--font-regular);
          }

          svg {
            margin-right: 6px;
            width: 20px;
            height: 20px;
          }

          button {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  div {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const ShimmerContent = styled.div`
  margin-top: 72px;
`;
