import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';

import useSearch from '../../../hooks/useSearch';

import { Container } from './style';

const SearchInput: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [inputValue, setInputValue] = useState('');
  const { setSearchParam } = useSearch();

  const handleChange = (e: React.FormEvent) => {
    e.preventDefault();
    setSearchParam(inputValue);

    if (location.pathname !== '/search') {
      history.push('/search');
    }
  };

  useEffect(() => {
    if (location.pathname !== '/search') {
      setInputValue('');
    }
  }, [location.pathname]);

  return (
    <Container className="search-input" onSubmit={e => handleChange(e)}>
      <input
        type="text"
        name="search"
        onChange={e => setInputValue(e.target.value)}
        value={inputValue}
        required
      />

      <button type="submit">
        <FiSearch size={18} color="#54006E" />
      </button>
    </Container>
  );
};

export default SearchInput;
