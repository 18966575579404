import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 21px;
  @media (max-width: 500px) {
    width: 350px;
  }
  .course-progress-bar {
    flex-grow: 0.94;
    height: 16px;
    border: solid 1px var(--default-gray);
    background-color: var(--default-gray);
    border-radius: 4px;
    .progress {
      background-color: var(--secondary-color);
      height: 100%;
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
  .course-progress-percentage {
    margin-left: 10px;
    font-size: var(--font-regular);
    font-weight: bold;
    color: var(--black);
    &.darken-progress {
      color: var(--neutralMid400);
    }
  }
`;

export { ProgressBarContainer };
