import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const CourseModuleContainer = styled.div`
  margin: 180px 0px 20px 20px;
  &.not-started {
    margin-top: 195px;
  }

  @media (max-width: 780px) {
    margin: 0;

    &.not-started {
      margin-top: 0px;
    }
  }
`;

export const DepositionsContent = styled.div`
  margin: 24px 0px 20px 20px;
  background: var(--primary-color);
  color: #fff;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  strong {
    font-size: 20px;
    margin-bottom: 8px;
    text-align: center;
  }
  span {
    font-size: 18px;
    margin-bottom: 24px;
  }
  button {
    cursor: pointer;
    border: none;
    background: var(--secondary-color);
    font-size: 18px;
    font-weight: 500;
    width: 100%;
    padding: 18px 0;
    border-radius: 6px;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 26px;
      height: 26px;
      margin-right: 10px;
    }
    & + button {
      width: unset;
      background: none;
      color: #fff;
      margin-top: 12px;
    }
    &:hover {
      filter: opacity(0.9);
    }
  }

  @media (max-width: 780px) {
    width: 100%;
    flex-direction: column;
    padding: 16px;
    margin-left: 0;

    button {
      margin-top: 24px;
      font-size: 16px;
    }

    strong {
      font-size: 16px;
    }

    span {
      font-size: 16px;
    }
  }
`;
