import httpClient from '../../http-client';

const changePhoneNumber = async (
  phone_number: string,
  access_token: string,
) => {
  await httpClient.patch(`/users/update-phone-number`, {
    phone_number,
    access_token,
  });
};

export { changePhoneNumber };
