import React from 'react';
import { BiUserCircle } from 'react-icons/bi';

import Deposition from '../../models/depositions';

import QuotationMarks from '../../assets/svgs/quotation-marks.svg';

import {
  Container,
  ContainerFooter,
  DepositionAuthor,
  DepositionCourses,
  DepositionOffice,
} from './style';

interface IDeposition {
  deposition: Deposition;
}

const DepositionCard: React.FC<IDeposition> = ({ deposition }) => {
  return (
    <Container>
      <img src={QuotationMarks} alt="Quotation marks" />
      <p>{deposition.description}</p>
      <ContainerFooter>
        <BiUserCircle color="#BDBDBD" />
        <div>
          <DepositionAuthor>{deposition.name}</DepositionAuthor>
          <DepositionOffice>{deposition.roles[0]}</DepositionOffice>
        </div>
      </ContainerFooter>
      <DepositionCourses>
        {deposition.title
          ? `Curso: ${deposition.title}`
          : `Trilha: ${deposition.trail_name}`}
      </DepositionCourses>
    </Container>
  );
};

export default DepositionCard;
