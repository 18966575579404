import styled from 'styled-components';

interface IModalContainer {
  open?: boolean;
}

interface IColor {
  bgColor?: string;
}
// eslint-disable-next-line import/prefer-default-export
export const ModalContainer = styled.div<IModalContainer>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  background-color: #00000055;
  min-height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  z-index: 999999;
`;

export const ModalBox = styled.div<IColor>`
  background-color: ${({ bgColor }) => bgColor};
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  max-height: 100vh;
  width: 300px;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    height: 480px;
    overflow-y: scroll;
  }
`;

export const ModalHeader = styled.div`
  background-color: black;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: auto;
  border-radius: 8px 8px 0 0;
  position: relative;

  img {
    width: 300px;
    height: 137px;
    border-radius: 8px 8px 0 0;
    position: absolute;
    margin-bottom: 32px;
    filter: brightness(50%);
  }
  div {
    text-align: center;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-bottom: 8px;
      text-overflow: ellipsis;
    }
  }
`;

export const ModalBody = styled.div<IColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 14px 13px 14px 11px;
`;

export const ModalFooter = styled.div<IColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 14.5px;
  button + button {
    margin-top: 12px;
    font-weight: 500;
  }
`;
