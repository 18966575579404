import convertContent from '../../converters/convert-content';
import convertTrail from '../../converters/convert-trail';
import httpClient from '../../http-client';
import ContentFromResponse from '../../models/from-api/content';
import TrailFromResponse from '../../models/from-api/trail';
import ModuleTrail from '../../models/module-trail';
import Course from './models/course-interface';
import Lesson from './models/lesson-interface';
import Module from './models/module-interface';
import OngoingCourse from './models/ongoing-course-interface';

interface LessonFromResponse {
  content_id: string;
  description: string;
  duration: number;
  title: string;
  reference: string;
  position: number;
}

interface LessonUserFromResponse {
  content_id: string;
  start_at?: string;
  finish_at?: string;
}

interface AuthorFromResponse {
  author_id: string;
  name: string;
  description: string;
  is_active: boolean;
}

interface ModuleFromResponse {
  author: AuthorFromResponse;
  course_id: string;
  title: string;
  description: string;
  position: number;
  duration: number;
  thumbnail: string;
  company_id: string;
  is_active: boolean;
  contents: LessonFromResponse[];
  created_at: Date;
  deleted_at: Date;
  categories: string[];
  alreadyStarted: boolean;
  exam_id?: string;
}

interface ModuleUserFromResponse {
  course_id: string;
  start_at?: string;
  finish_at?: string;
  contents: LessonUserFromResponse[];
  exam_id?: string;
}

type Author = {
  author_id: string;
  name: string;
  description: string;
};

type Category = {
  category_id: string;
  name: string;
};

interface CourseFromResponse {
  trail_id: string;
  name: string;
  author: Author;
  category: Category;
  description: string;
  is_active: boolean;
  company_id: string;
  thumbnail: string;
  created_at: Date;
  deleted_at: Date;
  duration?: string;
  courses: ModuleFromResponse[];
  user?: CourseUserFromResponse;
  video_reference: string;
  finish_at?: Date;
  book_mark: boolean;
}

interface CourseUserFromResponse {
  start_at?: string;
  finish_at?: string;
  courses: ModuleUserFromResponse[];
}

interface TrailUserProgressFromResponse {
  progress: number;
}

const convertToCourse = (trails: CourseFromResponse[]) => {
  return trails.map(
    trail =>
      ({
        id: trail.trail_id,
        isNew:
          trail.created_at &&
          (new Date().getTime() - new Date(trail.created_at).getTime()) /
            (1000 * 3600 * 24) <
            30,
        imageUrl: trail.thumbnail,
        category: trail.category && trail.category.name,
        addedToList: trail.book_mark,
        title: trail.name,
        description: trail.description,
        author: trail.author && trail.author.name,
        authorDescription: trail.author && trail.author.description,
        duration: !!trail.duration
          ? trail.duration
          : trail.courses &&
            trail.courses.length &&
            trail.courses
              .map(course => course.contents)
              .flat()
              .map(content => +content.duration)
              .reduce(
                (somaDuracao, duracaoAtual) => somaDuracao + duracaoAtual,
              ),
        alreadyStarted: !!trail.user?.start_at,
        alreadyFinished: !!trail.user?.finish_at,
        modules:
          trail.courses && trail.courses.length
            ? convertToModules(
                trail.courses
                  .filter(course => course.is_active && !course.deleted_at)
                  .sort(
                    (courseA, courseB) => courseA.position - courseB.position,
                  ),
                trail.user,
              )
            : [],
        reference: trail.video_reference || '',
        finishDate:
          trail.user && trail.user.finish_at
            ? new Intl.DateTimeFormat('pt-BR').format(
                new Date(trail.user.finish_at),
              )
            : trail.finish_at,
        active: trail.is_active && !trail.deleted_at,
      } as Course),
  );
};

const convertToModules = (
  courses: ModuleFromResponse[],
  courseUser?: CourseUserFromResponse,
) => {
  return courses.map(course => {
    let moduleUser: ModuleUserFromResponse | undefined;

    if (courseUser && courseUser.courses) {
      moduleUser = courseUser.courses.find(
        moduleUser => moduleUser.course_id === course.course_id,
      );
    }

    return {
      id: course.course_id,
      title: course.title,
      position: course.position,
      isFirstModule: course.position === 1,
      duration: course.contents
        .map(content => +content.duration)
        .reduce((somaDuracao, duracaoAtual) => somaDuracao + duracaoAtual),
      lessons:
        course.contents && course.contents.length
          ? convertToLessons(
              course.contents.sort(
                (lessonA, lessonB) => lessonA.position - lessonB.position,
              ),
              courseUser?.courses.find(
                userCourse => userCourse.course_id === course.course_id,
              ),
            )
          : [],
      alreadyStarted: !!moduleUser?.start_at,
      alreadyFinished: !!moduleUser?.finish_at,
      exam_id: course.exam_id,
    } as Module;
  });
};

const convertToLessons = (
  contents: LessonFromResponse[],
  moduleUser?: ModuleUserFromResponse,
) => {
  return contents.map((content, index, self) => {
    let previousContentUser: LessonUserFromResponse | undefined;
    const previousContent =
      index >= 0 ? self[index - 1] : ({} as LessonFromResponse);

    if (moduleUser && moduleUser.contents) {
      previousContentUser = moduleUser.contents.find(
        contentUser => contentUser.content_id === previousContent?.content_id,
      );
    }

    let contentUser: LessonUserFromResponse | undefined;
    const actualContent = self[index];

    if (moduleUser && moduleUser.contents) {
      contentUser = moduleUser.contents.find(
        contentUser => contentUser.content_id === actualContent?.content_id,
      );
    }

    return {
      id: content.content_id,
      description: content.description,
      duration: content.duration,
      title: content.title,
      types: [],
      nextToWatch:
        !!moduleUser?.start_at && index > 0
          ? previousContentUser && previousContentUser?.finish_at
          : !!moduleUser?.start_at,
      isLocked:
        !!moduleUser?.start_at && index > 0
          ? !previousContentUser || !previousContentUser?.finish_at
          : !moduleUser?.start_at,
      contentUrl: content.reference,
      position: content.position,
      alreadyStarted: !!contentUser?.start_at,
      alreadyFinished: !!contentUser?.finish_at,
    } as Lesson;
  });
};

const getAllCourses = async (filters?: object) => {
  let courses = (await httpClient.get('/trails', { params: filters }))
    .data as CourseFromResponse[];

  if (courses && courses.length) {
    courses = courses.filter(course => course.is_active);
  }

  return convertToCourse(courses);
};

const getBannerCourses = async (filters?: object) => {
  let courses = (await httpClient.get('/trails', { params: filters }))
    .data as CourseFromResponse[];

  if (courses && courses.length) {
    courses = courses.filter(course => course.is_active);
  }

  return convertToCourse(courses);
};

const getOngoingCourses = async (filters?: object) => {
  let courses = (
    await httpClient.get<OngoingCourse[]>('/trail-users/me/in-progress', {
      params: filters,
    })
  ).data;
  return courses;
};

const getOngoingContents = async (filters?: object) => {
  let contents = (
    await httpClient.get<any[]>('/contents-user/in-progress', {
      params: filters,
    })
  ).data;

  return contents;
};

const getRecommendedCourses = async () => {
  let courses = (await httpClient.get('/trails')).data as CourseFromResponse[];

  if (courses && courses.length) {
    courses = courses.filter(course => course.is_active);
  }

  return convertToCourse(courses);
};

const getNewCourses = async () => {
  let courses = (await httpClient.get('/trails')).data as CourseFromResponse[];

  if (courses && courses.length) {
    courses = courses.filter(course => course.is_active);
  }

  return convertToCourse(courses);
};

const getMostViewedCourses = async () => {
  let courses = (
    await httpClient.get('/trails', { params: { most_viewed: true } })
  ).data as CourseFromResponse[];

  if (courses && courses.length) {
    courses = courses.filter(course => course.is_active);
  }

  return convertToCourse(courses);
};

const getCoursesForAnnotation = async () => {
  let courses = (await httpClient.get('/trails')).data as CourseFromResponse[];

  if (courses && courses.length) {
    courses = courses.filter(course => course.is_active);
  }

  return convertToCourse(courses);
};

const getCourse = async (courseId: string) => {
  if (!courseId) {
    return {} as Course;
  }

  let course = (await httpClient.get(`/trails/${courseId}`)).data;

  if (course && course.trail && course.user) {
    course = {
      ...course.trail,
      user: course.user,
    };
  }

  return convertToCourse([course])[0];
};

const getCompletionPercentage = async (courseId: string) => {
  if (!courseId) {
    return 0;
  }

  const progress =
    (
      (await httpClient.get(`/trail-users/progress/${courseId}`))
        .data as TrailUserProgressFromResponse
    ).progress || 0;

  return Math.floor(progress * 100);
};

const startModule = async (courseId: string, moduleId: string) => {
  if (!courseId || !moduleId) {
    return {};
  }

  return (
    await httpClient.post(`/trail-course-users`, {
      trail_id: courseId,
      course_id: moduleId,
    })
  ).data;
};

const finishModule = async (courseId: string, moduleId: string) => {
  if (!courseId || !moduleId) {
    return {};
  }

  return (
    await httpClient.put(`/trail-course-users/finish`, {
      trail_id: courseId,
      course_id: moduleId,
    })
  ).data;
};

const startLesson = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
) => {
  if (!courseId || !moduleId || !lessonId) {
    return {};
  }

  return (
    await httpClient.post(`/trail-course-content-users`, {
      trail_id: courseId,
      course_id: moduleId,
      content_id: lessonId,
    })
  ).data;
};

const finishLesson = async (
  courseId: string,
  moduleId: string,
  lessonId: string,
) => {
  if (!courseId || !moduleId || !lessonId) {
    return {};
  }

  return (
    await httpClient.put(`/trail-course-content-users/finish`, {
      trail_id: courseId,
      course_id: moduleId,
      content_id: lessonId,
    })
  ).data;
};

const searchCourses = async (param: string) => {
  if (!param || param === '') {
    return [];
  }

  let response = (
    await httpClient.get(`/searches/trails-and-contents?search=${param}`)
  ).data as any;

  return response;
};

const startCourse = async (courseId: string, trailId: string) => {
  httpClient.post(`/trail-course-users`, {
    trail_id: trailId,
    course_id: courseId,
  });
};

const finishCourse = async (courseId: string, trailId: string) => {
  httpClient.put(`/trail-course-users/finish`, {
    trail_id: trailId,
    course_id: courseId,
  });
};

const getModule = async (moduleId: string): Promise<ModuleTrail> => {
  const module: ModuleTrail = await (
    await httpClient.get(`/courses/${moduleId}`)
  ).data;
  return module;
};

export {
  getAllCourses,
  getNewCourses,
  getMostViewedCourses,
  getOngoingContents,
  getCourse,
  getCompletionPercentage,
  startCourse,
  finishCourse,
  startModule,
  finishModule,
  startLesson,
  finishLesson,
  getCoursesForAnnotation,
  getOngoingCourses,
  getBannerCourses,
  getRecommendedCourses,
  searchCourses,
  getModule,
};
