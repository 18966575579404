import styled from 'styled-components';

export const Container = styled.div`
  margin: 94px 0;

  h2 {
    font-size: var(--font-largest);
    margin-bottom: 86px;
  }

  div.slick-list {
    max-width: 90%;
    margin: 0 auto;
    padding-left: 0px;
  }

  button {
    width: 56px;
    height: 56px;
  }

  div.content {
    padding: 0 !important;
  }

  @media (max-width: 768px) {
    margin: 24px 0 32px;

    div.content {
      display: none;
    }

    h2 {
      font-size: var(--font-default);
      margin-bottom: 16px;
    }
  }
`;

export const MobileDepositions = styled.div`
  display: flex;
  gap: 18px;
  overflow: scroll;
  padding-bottom: 18px;

  @media (min-width: 768px) {
    display: none;
  }
`;
