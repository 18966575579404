import React, { useState, useEffect, useCallback } from 'react';

import { GoReply } from 'react-icons/go';
import {
  AiOutlineDislike,
  AiOutlineLike,
  AiFillLike,
  AiFillDislike,
} from 'react-icons/ai';
import { BiUserCircle } from 'react-icons/bi';

import { IPostComment } from '../../../../services/forum/models';
import { updateRating as updateRatingService } from '../../../../services/forum';

import { toast } from 'react-toastify';

import { AnswerWrapper, PostAnswerItemWrapper, TextareaWrapper } from './style';

const Answer = ({ answerData, submitCommentary }: any) => {
  const { answer_id, comment, user, created_at, description, likes } =
    answerData || {};
  const [isTextareaShown, setTextareaShown] = useState<boolean>(false);
  const [commentary, setCommentary] = useState<string>('');

  const [ratingData, setRatingData] = useState(likes);

  useEffect(() => {
    setRatingData(likes);
  }, [likes]);

  const handleChangeCommentary = (e: any) => {
    setCommentary(e.target.value);
  };

  const handleUpdateRating = async (rating: boolean) => {
    await updateRatingService(answer_id, rating).then(
      ({ negative, positive }) => {
        if (!!positive) {
          setRatingData({
            ...ratingData,
            total_positive: +ratingData.total_positive + 1,
            total_negative:
              ratingData.user_review === false
                ? +ratingData.total_negative - 1
                : +ratingData.total_negative,
            user_review: true,
          });

          return;
        }

        setRatingData({
          ...ratingData,
          total_negative: +ratingData.total_negative + 1,
          total_positive:
            ratingData.user_review === true
              ? +ratingData.total_positive - 1
              : +ratingData.total_positive,
          user_review: false,
        });

        return;
      },
    );
  };

  const toggleTextArea = () => {
    setTextareaShown(!isTextareaShown);
  };

  const submit = async (e: any) => {
    e.preventDefault();

    if (commentary.replace(/\s/g, '').length <= 3) {
      toast.error('Preencha seu comentário');
      return;
    }

    await submitCommentary(answer_id, commentary).then(() =>
      setTextareaShown(false),
    );

    setCommentary('');
  };

  const formatDate = useCallback(
    date => {
      if (!created_at) {
        return '';
      }

      const day = new Intl.DateTimeFormat('pt-BR').format(new Date(date));
      const hour = new Intl.DateTimeFormat('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      }).format(new Date(date));

      return `${day} ${hour}`;
    },
    [answerData],
  );

  return (
    <>
      <AnswerWrapper>
        <PostAnswerItemWrapper isComment={false}>
          <div className="postData">
            <div className="userData">
              <BiUserCircle size={80} className="userData__icon" />
              <span className="userData__name">{user.name}</span>
              <span className="userData__date">{formatDate(created_at)}</span>
            </div>
            <p>{description}</p>
          </div>
          <div className="postActions">
            <div className="action">
              <GoReply size={32} onClick={toggleTextArea} />
            </div>
            <div className="action">
              <span>{ratingData.total_negative}</span>
              {ratingData.user_review === false ? (
                <AiFillDislike size={32} />
              ) : (
                <AiOutlineDislike
                  size={32}
                  onClick={() => handleUpdateRating(false)}
                />
              )}
            </div>
            <div className="action">
              <span>{ratingData.total_positive}</span>
              {ratingData.user_review === true ? (
                <AiFillLike size={32} />
              ) : (
                <AiOutlineLike
                  size={32}
                  onClick={() => handleUpdateRating(true)}
                />
              )}
            </div>
          </div>
        </PostAnswerItemWrapper>
        {isTextareaShown && (
          <TextareaWrapper onSubmit={submit}>
            <textarea
              rows={6}
              name="description"
              value={commentary}
              onChange={handleChangeCommentary}
              required={true}
            />
            <button>Responder</button>
          </TextareaWrapper>
        )}
        {comment &&
          comment.length >= 1 &&
          comment.map((commentItem: IPostComment) => (
            <PostAnswerItemWrapper isComment={true} key={commentItem.answer_id}>
              <div className="postData">
                <div className="userData">
                  <BiUserCircle size={80} className="userData__icon" />
                  <span className="userData__name">
                    {commentItem.user?.name}
                  </span>
                  <span className="userData__date">
                    {formatDate(commentItem.created_at)}
                  </span>
                </div>
                <p>{commentItem.description}</p>
              </div>
            </PostAnswerItemWrapper>
          ))}
      </AnswerWrapper>
    </>
  );
};

export default Answer;
