import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';

import { IQuestion } from '../../services/forum/models';
import { getPosts, getPostsLength } from '../../services/forum';

import {
  ForumPageHeader,
  ForumPageWrapper,
  NewPostButton,
  ForumPostList,
  EmptyListWrapper,
  ForumPostFooterWrapper,
} from './style';
import PostListLoader from './components/PostListLoader';
import ForumPost from './components/ForumPost';
import NewTopicForm from './components/NewTopicForm';

const Forum = () => {
  const history = useHistory();
  const [page, setPage] = useState<number>(1);
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [postList, setPostList] = useState<IQuestion[]>([]);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  const [createNewPost, setCreateNewPost] = useState(false);

  const getTotalPages = async () => {
    const postsTotal = await getPostsLength();
    setCanLoadMore(postsTotal > 10);
    const pages = [];

    for (let i = 1; i <= Math.ceil(postsTotal / 10); i++) {
      pages.push(i);
    }

    setPageNumbers(pages);
  };

  const fetchPosts = async () => {
    await getPosts(page).then(response => {
      setLoading(false);
      if (page === 1) {
        setPostList([...response]);
        return;
      }

      setPostList([...response]);
    });
  };

  const createPost = () => {
    setCreateNewPost(true);
  };

  const loadMore = (pageNumber: number) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    getTotalPages();

    if (page >= 1) {
      fetchPosts();
    }
  }, [page]);

  return (
    <ForumPageWrapper>
      <div className="content">
        <ForumPageHeader>
          <div className="buttons-container">
            <IoIosArrowBack
              size={36}
              color="#444"
              onClick={() => history.goBack()}
            />
            {!createNewPost && (
              <NewPostButton onClick={createPost}>Criar Tópico</NewPostButton>
            )}
          </div>

          <div>
            <h1>Fórum</h1>
            <h2>
              Quer bater um papo sobre o curso ou tirar dúvidas com os colegas?
            </h2>
          </div>
        </ForumPageHeader>
        {createNewPost && (
          <NewTopicForm onCancel={() => setCreateNewPost(false)} />
        )}
        <ForumPostList>
          <tr className="header">
            <th>Título</th>
            <th>Criado por</th>
            <th>Data</th>
            <th className="lastPost">Última postagem</th>
            <th>Respostas</th>
          </tr>
          {isLoading ? (
            <PostListLoader />
          ) : (
            <>
              {postList.length === 0 ? (
                <EmptyListWrapper>
                  {!createNewPost && (
                    <>
                      <h4>
                        Ainda não temos perguntas. Deseja criar um novo tópico?
                      </h4>

                      <NewPostButton onClick={createPost}>
                        Novo Tópico
                      </NewPostButton>
                    </>
                  )}
                </EmptyListWrapper>
              ) : (
                postList.map((post: IQuestion) => <ForumPost postData={post} />)
              )}
            </>
          )}
        </ForumPostList>
        <ForumPostFooterWrapper>
          {canLoadMore && (
            <>
              <span> Página: </span>
              {pageNumbers.map(pageNumber => (
                <button
                  type="button"
                  className={`${page == pageNumber && 'selected'}`}
                  onClick={() => loadMore(pageNumber)}
                >
                  {pageNumber}
                </button>
              ))}
            </>
          )}
        </ForumPostFooterWrapper>
      </div>
    </ForumPageWrapper>
  );
};

export default Forum;
