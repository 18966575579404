import React from 'react';
import ContentLoader from 'react-content-loader';

const CourseCardSkeleton = () => {
  return (
    <ContentLoader
      style={{ width: '100%', height: '60px', marginBottom: '10px' }}
      backgroundColor="#e3e3e3"
    >
      <rect style={{ width: '95%', height: '60px' }} ry="5" />
    </ContentLoader>
  );
};

export default CourseCardSkeleton;
