import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import AnnotationTrailCardSkeleton from './components/AnnotationTrailCardSkeleton';
import {
  AnnotationsContainer,
  AnnotationTitle,
  AnnotationTrailCardsContainer,
} from './style';
import {
  getTrailsAndLessonsWithAnnotations,
  TrailAndLessonsWithAnnotation,
} from '../../services/course/annotations';
import TrailCard from './components/TrailCard';

const Annotations: React.FC = () => {
  const [courses, setCourses] = useState([] as TrailAndLessonsWithAnnotation[]);
  const [shouldShowSkeleton, setShouldShowSkeleton] = useState(true);

  const getCoursesForAnnotation = async () => {
    try {
      const localCourses = await getTrailsAndLessonsWithAnnotations();

      if (localCourses && localCourses.length) {
        setCourses(localCourses);
      } else {
        setShouldShowSkeleton(false);
      }
    } catch (e) {
      toast.error('Erro ao recuperar os cursos com anotações.');
    }
  };

  useEffect(() => {
    (async () => {
      await getCoursesForAnnotation();
    })();
  }, []);

  return (
    <AnnotationsContainer>
      <div className="content">
        <AnnotationTitle>Anotações</AnnotationTitle>

        <AnnotationTrailCardsContainer>
          {courses && courses.length ? (
            courses.map(course => (
              <TrailCard course={course} key={course.trail_id}>

              </TrailCard>
            ))
          ) : shouldShowSkeleton ? (
            Array(12)
              .fill('')
              .map((item, index) => <AnnotationTrailCardSkeleton key={index} />)
          ) : (
            <h4 style={{ width: '100%' }}>
              Não foram encontrados cursos que possuam anotações.
            </h4>
          )}
        </AnnotationTrailCardsContainer>
      </div>
    </AnnotationsContainer>
  );
};

export default Annotations;
