import httpClient from '../../http-client';

export interface ITrailUserReviews {
  trail_id: string;
  user_id: string;
  user_name: string;
  description: string;
  created_at: string;
  stars: number | null;
}

const getTrailContentReview = async (trailId: string) => {
  const response = (await httpClient.get(`/trail-user-reviews/${trailId}/user`))
    .data;

  return response;
};

const reviewTrailContent = async (trailId: string, description: string, rating: number) => {
  await httpClient.post(`/trail-user-reviews`, {
    trail_id: trailId,
    description: description,
    stars: rating,
  });
};

const getTrailUserReviews = async (trailId: string) => {
  const allReviews = (
    await httpClient.get<ITrailUserReviews[]>(
      `/trail-user-reviews/${trailId}`,
    )
  ).data;

  return allReviews;
};

export { getTrailContentReview, reviewTrailContent, getTrailUserReviews };
