import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  width: 260px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 32px;

  &:after {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 20.31%,
      rgba(255, 255, 255, 0.1) 100%
    );
    position: absolute;
    content: '';
    height: 140px;
    width: 100%;
    top: 0;
    left: 0;
  }

  span.divider {
    height: 16px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0 8px 0px 16px;
  }

  img {
    width: 100%;
    min-height: 140px;
    max-height: 140px;
    object-fit: cover;
  }

  h4 {
    max-width: 80%;
    font-size: var(--font-large);
    line-height: 22px;
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    width: 190px;

    img {
      min-height: 102px;
      height: 102px;
    }

    h4 {
      max-width: 90%;
      font-size: var(--font-regular);
    }

    &:after {
      height: 102px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.3) 36.31%,
        rgba(255, 255, 255, 0.1) 100%
      );
    }
  }

> footer {
  display: flex;
  align-items: center;
  margin-top: 16px;

> div {
  font-size: var(--font-regular);
  color: var(--regular-gray);
  display: flex;
  align-items: center;

  svg {
      margin-right: 6px;
      width: 20px;
      height: 20px;
    }

    span {
      margin-top: 2.5px;
    }

  @media (max-width: 768px) {
    font-size: var(--font-min);

    svg {
        width: 11px;
        height: 11px;
      }

      span {
        margin-top: 0;
      }
    } 
  }
}

`;

export const BookmarkButton = styled.button`
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  top: 13px;
  right: 14px;
  z-index: 1;

  svg.favorite {
    fill: var(--secondary-color);

    path {
      fill-rule: inherit;
    }
  }
`;
