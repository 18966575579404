import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  display: flex;
  margin: 58px 0 auto;

  div.shimmer {
    margin-top: 32px;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 980px;
  }

  h2 {
    font-size: 32px;
  }

  div.dropdown {
    display: none;
  }

  @media (max-width: 768px) {
    margin-top: 42px;

    header {
      width: 100%;
    }

    h2 {
      display: none;
    }

    aside {
      display: none;
    }

    > div {
      width: 100%;
    }

    div.dropdown {
      display: block;
    }
  }
`;

const UploadedFilesContainer = styled.div`
  margin: 0 auto;
  width: 100%;

  h2 {
    margin-bottom: 42px;
  }

  div.dropdown {
    display: none;
  }

  @media (max-width: 768px) {
    div.dropdown {
      display: block;
    }
  }
`;

const UploadedFilesList = styled.div`
  @media (max-width: 539px) {
    overflow-x: scroll;
  }
`;

export { Container, UploadedFilesContainer, UploadedFilesList };
