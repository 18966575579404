import styled from 'styled-components';
import { Form } from '@unform/web';
import DefaultInput from '../../../../components/Inputs/DefaultInput/DefaultInput';

export const Container = styled.div`
  background: #f2f2f2;
  margin-top: 84px;
  padding: 32px;

  @media (max-width: 768px) {
    background: #fff;
    padding: 0 32px;

    div.content {
      padding-left: 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 80px 0px;
  margin: 0 auto;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 992px) {
    div {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    padding: 0px 0px 32px;

    div {
      align-items: flex-start;
    }
  }
`;

export const Title = styled.h2`
  font-size: var(--font-largest);
  max-width: 360px;

  @media (max-width: 768px) {
    font-size: var(--font-large);
    line-height: 40px;
  }

  @media (max-width: 992px) {
    margin-bottom: 32px;
  }
`;

export const ContentForm = styled(Form)`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 82px 0 16px;

  @media (max-width: 992px) {
    margin-right: 0;
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 1rem auto 0 0;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  label {
    width: 100%;
    color: #444;
    margin-bottom: 0.5rem;
    font-weight: 4+00;
  }
`;

export const Input = styled(DefaultInput)`
  height: 48px;
  width: 100%;
  background-color: #f2f2f2;
  border: 1px solid #444;
  border-radius: 6px;

  &.textarea {
    height: 180px;
    align-items: flex-start;

    input {
      border: 1px solid red;
    }
  }

  @media (max-width: 768px) {
    background: #fff;
  }
`;

export const Label = styled.label`
  width: 100%;
  color: #444;
  margin-bottom: 0.5rem;
`;

export const TextArea = styled.textarea`
  width: 100%;
  background-color: #f2f2f2;
  border: 1px solid #444;
  border-radius: 6px;
  resize: none;

  @media (max-width: 768px) {
    background: #fff;
  }
`;

export const Button = styled.button`
  font-weight: bold;
  font-size: var(--font-default);
  background-color: var(--secondary-color);
  border-radius: 24px;
  color: #000;
  width: 130px;
  height: 48px;
  border: none;
  transition: background-color 0.2s;
  cursor: pointer;

  &:hover {
    background-color: var(--secondary-color-hover);
  }
`;
