import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiFillLike,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';

import Content from '../../models/content';
import useDragDetection from '../../hooks/useDragDetection';
import {
  addContentBookmark,
  removeContentBookmark,
} from '../../services/bookmark';

import { toast } from 'react-toastify';

import {
  Container,
  BookmarkButton,
  CourseDetails,
  CourseTime,
  CourseLikes,
  LikeAndTimeContainer,
} from './style';
import { secondsToTimeText } from '../../helpers/time';
import { Rating } from 'react-simple-star-rating';
import { getContentUserReviews } from '../../services/content-review';
import { average, evaluationGrade } from '../../utils/getReviews';

interface ICourseProps {
  course: Content;
  style?: any;
}

const BannerCard: React.FC<ICourseProps> = ({ course, style }) => {
  const history = useHistory();
  const [addedToList, setAddedToList] = useState(course.addedToList);
  const [addedToLike, setAddedToLike] = useState(course.userLiked);
  const [rating, setRating] = useState(0);

  const { handleMouseDown, dragging } = useDragDetection();

  const goToCourse = () => {
    history.push(`/courses/${course.id}`);
  };

  function handleChildClick(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ): void {
    if (dragging) {
      e.stopPropagation();
    }
  }

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (course) {
      try {
        const shouldAddToList = !course.addedToList;

        if (shouldAddToList) {
          await addContentBookmark(course.id);
        } else {
          await removeContentBookmark(course.id);
        }

        course.addedToList = shouldAddToList;
        setAddedToList(course.addedToList);
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  const reviews = useCallback(async () => {
    const allReviews = await getContentUserReviews(course.id);
    const qtdReviews = allReviews.length;
    const averageOfStarts = average(allReviews, qtdReviews);
    const stars = evaluationGrade(averageOfStarts) || 0;

    setRating(stars);

  },[course.id]);

  useEffect(() => {
    reviews();
  },[reviews]);

  return (
    <Container
      style={style}
      onClick={goToCourse}
      onMouseDownCapture={handleMouseDown}
      onClickCapture={handleChildClick}
    >
      <img src={course.thumbnail} alt="Course" />

      <CourseDetails>
        <BookmarkButton onClick={addToList}>
          <BsBookmark color="#FFF" className={`${addedToList && 'favorite'}`} />
        </BookmarkButton>

        <h3>{course.title.length > 90 ? course.title.substring(0, 90) + '...' : course.title}</h3>

        <div>
          <LikeAndTimeContainer>
            <CourseTime>
              <AiOutlineClockCircle />
              <span>{`${secondsToTimeText(course.duration)}`}</span>
            </CourseTime>
            <span className="divider" />
            <CourseLikes>
              {addedToLike ? (
                <AiFillLike color="#fff" />
              ) : (
                <AiOutlineLike color="#fff" />
              )}

              <span>
                {course.numberOfLikes &&
                  `${course.numberOfLikes} ${
                    course.numberOfLikes === 1 ? 'curtida' : 'curtidas'
                  }`}
              </span>
            </CourseLikes>
          </LikeAndTimeContainer>
          <div className="rating-container">
            <Rating
              ratingValue={rating}
              fillColorArray={[
                '#f17a45',
                '#f19745',
                '#f1a545',
                '#f1b345',
                '#f1d045',
              ]}
              size={30}
              allowHalfIcon
              readonly
            />
          </div>
          
        </div>
      </CourseDetails>
    </Container>
  );
};

export default BannerCard;
