import React, { useState, useCallback, useEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import CourseCardItem from '../../../../components/CourseCardItem';
import ProfileMenu from '../../components/ProfileMenu';

import { Container, FavoritesList, SelectContainer } from './style';
import MobileMenuDropdown from '../../components/MobileMenuDropdown';
import TrailCard from '../../../../components/TrailCard';
import Trail from '../../../../models/trail';
import { getTrails } from '../../../../services/trail';
import { getContents } from '../../../../services/content';
import Content from '../../../../models/content';
import GridShimmer from '../../../../components/GridShimmer';
import { getAllLives } from '../../../../services/lives';
import Live from '../../../../models/lives';
import LiveCardItem from '../../../../components/LiveCardItem';

const FavoriteCourses: React.FC = () => {
  const [favoriteTrails, setFavoriteTrails] = useState([] as Trail[]);
  const [favoriteContents, setFavoriteContents] = useState([] as Content[]);
  const [favoriteLives, setFavoriteLives] = useState([] as Live[]);
  const [contentSelected, setContentSelected] = useState('contents');
  const [isLoading, setIsLoading] = useState(true);

  const getFavoriteLives = useCallback(async () => {
    setIsLoading(true);
    const data = await getAllLives({ book_mark: true });
    setFavoriteLives(data);
    setIsLoading(false);
  }, []);

  const refreshFavoriteTrails = useCallback(async () => {
    setIsLoading(true);
    const favoriteFilters = {
      book_mark: true,
    };

    const localFavoriteTrails = await getTrails(favoriteFilters);

    if (localFavoriteTrails && localFavoriteTrails.length) {
      setFavoriteTrails(localFavoriteTrails);
    }
    setIsLoading(false);
  }, []);

  const refreshFavoriteContents = useCallback(async () => {
    setIsLoading(true);
    const favoriteFilters = {
      book_mark: true,
    };

    const localFavoriteContents = await getContents(favoriteFilters);

    if (localFavoriteContents && localFavoriteContents.length) {
      setFavoriteContents(localFavoriteContents);
    }
    setIsLoading(false);
  }, []);

  const removeLiveItem = useCallback(async (live_id) => {
    setFavoriteLives(favoriteLives.filter(live => live.live_id !== live_id))
  }, [favoriteLives])

  useEffect(() => {
    (async () => {
      await refreshFavoriteTrails();
      await refreshFavoriteContents();
    })();
  }, [refreshFavoriteTrails, refreshFavoriteContents, getFavoriteLives]);

  useEffect(() => {
    if (contentSelected === 'lives') {
      getFavoriteLives();
    }
  }, [contentSelected, getFavoriteLives])

  return (
    <Container className="content">
      <ProfileMenu />

      <div>
        <MobileMenuDropdown className="dropdown" />

        <header>
          <h2>
            {contentSelected === 'contents'
              ? 'Cursos Favoritos'
              : contentSelected === 'trails' ? 'Trilhas favoritas' : 'Lives favoritas'}
          </h2>

          <SelectContainer>
            <select onChange={e => setContentSelected(e.target.value)}>
              <option value="contents">Cursos favoritos</option>
              <option value="trails">Trilhas favoritas</option>
              <option value="lives">Lives favoritas</option>
            </select>
            <IoIosArrowDown size={20} />
          </SelectContainer>
        </header>
        {isLoading ? (
          <div className="shimmer">
            <GridShimmer />
          </div>
        ) : (
          <>
            {contentSelected === 'contents' && (
              <div>
                <FavoritesList>
                  {favoriteContents && favoriteContents.length ? (
                    favoriteContents.map(course => (
                      <>
                        <CourseCardItem
                          key={course.id}
                          course={course}
                          onAddToList={refreshFavoriteContents}
                        />
                        <div />
                      </>
                    ))
                  ) : (
                    <h4 style={{ padding: '15px' }}>
                      Não foram encontrados cursos favoritados.
                    </h4>
                  )}
                </FavoritesList>
              </div>
            )}

            {contentSelected === 'trails' && (
              <div>
                <FavoritesList>
                  {favoriteTrails && favoriteTrails.length ? (
                    favoriteTrails.map(trail => (
                      <>
                        <TrailCard
                          key={trail.id}
                          trail={trail}
                          onAddToList={refreshFavoriteTrails}
                        />
                        <div />
                      </>
                    ))
                  ) : (
                    <h4 style={{ padding: '15px' }}>
                      Não foram encontradas trilhas favoritadas.
                    </h4>
                  )}
                </FavoritesList>
              </div>
            )}

            {contentSelected === 'lives' && (
              <div>
                <FavoritesList>
                  {favoriteLives && favoriteLives.length ? (
                    favoriteLives.map(live => (
                      <>
                        <LiveCardItem
                          key={live.live_id}
                          live={live}
                          hasAction
                          onAddToList={removeLiveItem}
                        />
                        <div />
                      </>
                    ))
                  ) : (
                    <h4 style={{ padding: '15px' }}>
                      Não foram encontradas lives favoritadas.
                    </h4>
                  )}
                </FavoritesList>
              </div>
            )}

          </>
        )}
      </div>
    </Container>
  );
};

export default FavoriteCourses;
