import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { useHistory, useParams } from 'react-router-dom';
import TrailContent, {
  PlayerEventListener,
} from '../Trail/components/TrailContent';
import BackButton from '../../components/Buttons/BackButton';
import {
  startContent as startContentService,
  finishContent as finishContentService,
} from '../../services/content';
import {
  startTrail as startTrailService,
  finishTrail as finishTrailService,
  getTrail as getTrailService,
} from '../../services/trail';
import {
  startCourse as startCourseService,
  finishCourse as finishCourseService,
} from '../../services/course';
import Trail from '../../models/trail';
import Content from '../../models/content';
import {
  BtnGroup,
  CourseContainer,
  ModalText,
  PrimaryButton,
  SecondaryButton,
} from './style';
import Loading from '../../components/Loading';
import TrailModules from '../Trail/components/TrailModules';
import CreateDepositionModal from '../../components/CreateDepositionModal';
import { getExam, IExam } from '../../services/exam';
import Course from '../../models/course';
import { toast } from 'react-toastify';
import getErrorMessage from '../../helpers/get-error-message';
import { setTrailCertificate } from '../../services/certificate';
import { default as CertificateModal } from '../../components/Modal';

export interface CourseParams {
  trailId: string;
  moduleId?: string;
  contentId?: string;
}

const TrailComponent: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { trailId, moduleId, contentId } = useParams() as CourseParams;
  const [trail, setTrail] = useState({} as Trail);

  const [currentModule, setCurrentModule] = useState({} as Course);
  const [moduleList, setModuleList] = useState<Course[]>([]);

  const [currentContent, setCurrentContent] = useState({} as Content);
  const [contentList, setContentList] = useState<Content[]>([]);

  const [showDepositionModal, setShowDepositionModal] = useState(false);

  const [exam, setExam] = useState<IExam>({} as IExam);
  const [trailProgress, setTrailProgress] = useState<number>(0);
  const [showEvaluateBox, setShowEvaluateBox] = useState(false);
  const [showModalCertificate, setShowModalCertificate] = useState(false);

  const isLoading = useMemo(() => {
    return !trail || !trail.id;
  }, [trail]);

  useEffect(() => {
    setShowEvaluateBox(!trail.review);
  }, [trail.review]);

  const redirectURLOnOpen = useCallback(() => {
    if (!contentList.length || !moduleList.length) return;

    const url = location.pathname.split('/');

    if (url[3] !== undefined) {
      if (trail.alreadyStarted) {
        const firstNonWatchedContent =
          contentList.find(content => !content.alreadyFinished) ||
          contentList[0];
        const selectedModule =
          moduleList.find(
            module => module.id === firstNonWatchedContent.courseId,
          ) || moduleList[0];

        history.push(
          `/trails/${firstNonWatchedContent.trailId}/modules/${firstNonWatchedContent.courseId}/contents/${firstNonWatchedContent.id}`,
        );

        setCurrentModule(selectedModule);
        setCurrentContent(firstNonWatchedContent);
      }
    }
  }, [contentList.length]);

  const getcurrentContent = useCallback(() => {
    const foundModule = moduleList.find(module => module.id === moduleId);
    const foundContent = contentList.find(
      content =>
        content.id === contentId &&
        content.courseId === moduleId &&
        content.trailId === trailId,
    );

    if (foundContent && foundContent.id && foundModule && foundModule.id) {
      setCurrentModule(foundModule);
      setCurrentContent(foundContent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId]);

  const pushLessonUrl = (
    courseId: string,
    moduleId?: string,
    lessonId?: string,
  ) => {
    const baseUrl = `/trails/${courseId}`;
    history.push(`${baseUrl}/modules/${moduleId}/contents/${lessonId}`);
  };

  const startTrail = async () => {
    if (!trail.alreadyStarted) {
      try {
        await startTrailService(trail.id);

        trail.alreadyStarted = true;
        const firstLesson = ((trail.courses || [])
          .map(course => course.contents || [])
          .flat() || [])[0] as Content;

        setTrail({ ...trail });

        pushLessonUrl(
          firstLesson.trailId!,
          firstLesson.courseId!,
          firstLesson.id,
        );
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar o curso. ' + errorMessage);
      }
    }
  };

  const startModule = async () => {
    if (currentModule.alreadyStarted) return;
    try {
      const modules = [...moduleList];
      const moduleIndex = modules.findIndex(
        module => module.id === currentModule.id,
      );
      modules[moduleIndex].alreadyStarted = true;

      await startCourseService(
        currentContent.courseId || '',
        currentContent.trailId || '',
      );
      setCurrentModule({ ...currentModule, alreadyStarted: true });
      setModuleList(modules);
    } catch (error) {
      const modules = [...moduleList];
      const moduleIndex = modules.findIndex(
        module => module.id === currentModule.id,
      );
      modules[moduleIndex].alreadyStarted = false;

      setCurrentModule({ ...currentModule, alreadyStarted: false });
      setModuleList(modules);
    }
  };

  const startCurrentContent = async () => {
    if (!currentModule.alreadyStarted) {
      try {
        currentModule.alreadyStarted = true;
        await startCourseService(
          currentContent.courseId!,
          currentContent.trailId!,
        );
      } catch (error) {
        currentModule.alreadyStarted = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar o módulo. ' + errorMessage);
      }
    }

    if (!currentContent.alreadyStarted) {
      try {
        currentContent.alreadyStarted = true;
        await startContentService(
          currentContent.id,
          currentContent.courseId,
          currentContent.trailId,
        );
      } catch (error) {
        currentContent.alreadyStarted = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao iniciar a aula. ' + errorMessage);
      }
    }

    setTrail({ ...trail });
  };

  const getEventListeners = async (player: PlayerEventListener) => {
    if (
      window.location.href.endsWith(
        `trails/${currentContent.trailId}/modules/${currentContent.courseId}/contents/${currentContent.id}`,
      )
    ) {
      if (currentContent && currentContent.id) {
        switch (player.event) {
          case 'onProgress':
            if (
              hasReachedCompletionTime(
                player.duration,
                player.eventParam,
                currentContent.id,
              )
            ) {
              await finishContent();
            } else {
              await startModule();
              await startCurrentContent();
            }

            updateTrailWatchTime(player);
            break;

          case 'onStart':
            await startTrail();
            break;

          case 'onFinish':
            goToNextContent();
            break;

          default:
            break;
        }
      }
    }
  };

  const hasReachedCompletionTime = (
    duration: number,
    currentTime: number,
    contentId: string,
  ) => {
    const totalDurationInSecs = duration;
    let completionRate = 0.9;

    if (
      contentList &&
      contentList.length &&
      contentList[contentList.length - 1].id === contentId &&
      contentList[contentList.length - 1].courseId === moduleId &&
      contentList[contentList.length - 1].trailId === trailId
    ) {
      completionRate = 0.999;
    }

    const completionTime = totalDurationInSecs * completionRate;
    return currentTime >= completionTime;
  };

  const goToNextContent = () => {
    if (currentContent) {
      const indexOfCurrentContent = contentList.indexOf(currentContent);
      if (
        indexOfCurrentContent > -1 &&
        indexOfCurrentContent <= contentList.length - 2
      ) {
        const nextContent = contentList[indexOfCurrentContent + 1];

        history.push(
          `/trails/${nextContent.trailId}/modules/${nextContent.courseId}/contents/${nextContent.id}`,
        );
      }
    }
  };

  const finishContent = async () => {
    if (!currentContent.alreadyFinished) {
      try {
        currentContent.alreadyFinished = true;
        await finishContentService(
          currentContent.id,
          currentContent.courseId,
          currentContent.trailId,
        );
      } catch (error) {
        currentContent.alreadyFinished = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao finalizar a aula. ' + errorMessage);
      }
    }

    if (
      currentModule &&
      !currentModule.alreadyFinished &&
      (currentModule.contents || [])
        .filter(les => les.type !== 'CERTIFICATE')
        .every(lesson => lesson.alreadyFinished)
    ) {
      try {
        currentModule.alreadyFinished = true;
        await finishCourseService(
          currentContent.courseId!,
          currentContent.trailId!,
        );
      } catch (error) {
        currentModule.alreadyFinished = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao finalizar o módulo. ' + errorMessage);
      }
    }

    if (
      !trail.alreadyFinished &&
      trail.courses.every(course => course.alreadyFinished)
    ) {
      try {
        trail.alreadyFinished = true;
        await finishTrailService(currentContent.trailId!);

        if (
          trail.alreadyFinished &&
          trail.info &&
          trail.info.no_certificate === false &&
          trail.exam === undefined
        ) {
          setTrailCertificate(trail.id);
          setShowModalCertificate(true);
        }
      } catch (error) {
        trail.alreadyFinished = false;
        const errorMessage = getErrorMessage(error);
        toast.error('Houve um erro ao finalizar o curso. ' + errorMessage);
      }
    }

    setTrail({ ...trail });
  };

  const updateTrailWatchTime = useCallback((player: PlayerEventListener) => {
    const { event, eventParam } = player;

    if (event === 'onProgress') {
      setTrailProgress(eventParam);
    }
    return null;
  }, []);

  const getTrail = useCallback(async () => {
    setTrail({} as Trail);
    setModuleList([] as Course[]);
    setContentList([] as Content[]);

    const localTrail = await getTrailService(trailId);
    if (localTrail && localTrail.id) {
      const allModules = localTrail.courses;
      setTrail(localTrail);
      setModuleList(allModules);
      setContentList(allModules.map(course => course.contents).flat());

      if (localTrail.exam) {
        const localExam = await getExam(localTrail.exam);
        setExam(localExam);
      }
    }
  }, [trailId]);

  useEffect(() => {
    getTrail();
  }, [getTrail]);

  useEffect(() => {
    redirectURLOnOpen();
  }, [redirectURLOnOpen]);

  useEffect(() => {
    getcurrentContent();
  }, [getcurrentContent]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [contentId]);

  return !isLoading ? (
    <CourseContainer>
      <Modal
        isOpen={showDepositionModal}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <CreateDepositionModal
          onClose={() => setShowDepositionModal(false)}
          setShowEvaluateBox={() => setShowEvaluateBox(false)}
          trail={trail}
        />
      </Modal>
      <>
        <button
          onClick={() => history.push('/trails')}
          style={{ background: 'none', border: 'none', cursor: 'pointer' }}
        >
          <BackButton />
        </button>

        <div className="content">
          <TrailContent
            trail={trail}
            selectedContent={currentContent}
            getEventListeners={getEventListeners}
            progress={trailProgress}
            finishContent={finishContent}
            goToNextContent={goToNextContent}
            startCurrentContent={startCurrentContent}
            startModule={startModule}
          />

          <TrailModules
            trail={trail}
            modules={moduleList}
            selectedContent={currentContent}
            contentList={contentList}
            startTrail={startTrail}
            showDepositionModal={() => setShowDepositionModal(true)}
            exam={exam}
            setShowEvaluateBox={() => setShowEvaluateBox(false)}
            showEvaluateBox={showEvaluateBox}
          />
        </div>
      </>
      <CertificateModal
        open={trail.alreadyFinished && showModalCertificate}
        setVisibility={() => {
          setShowModalCertificate(!showModalCertificate);
        }}
      >
        <div>
          <span>{trail.category}</span>
          <h2 style={{ wordBreak: 'break-word' }}>{trail.name}</h2>
        </div>
        <ModalText>Meus parabéns, você conclui esta trilha.</ModalText>

        <BtnGroup>
          <PrimaryButton onClick={() => history.push('/certificates')}>
            Meus certificados
          </PrimaryButton>

          <SecondaryButton onClick={() => setShowModalCertificate(false)}>
            Voltar para a trilha
          </SecondaryButton>
        </BtnGroup>
      </CertificateModal>
    </CourseContainer>
  ) : (
    <Loading size={160} />
  );
};

export default TrailComponent;
