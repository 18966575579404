import Category from '../models/category';
import CategoryFromResponse from '../models/from-api/category';

const convertCategory = (category: CategoryFromResponse): Category => {
  return {
    id: category.category_id,
    title: category.title,
    description: category.description,
    logo: category.logo,
    thumbnail: category.thumbnail,
    child_categories: category.child_categories,
    info: {
      color: category.info.color,
    },
    createdAt:
      category.created_at && typeof category.created_at === 'string'
        ? new Date(category.created_at)
        : new Date(),
  };
};

export default convertCategory;
