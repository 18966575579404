import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import { addHours, getHours, getMinutes, getSeconds } from 'date-fns';
import background from '../assets/images/back-cert.jpg';

// @ts-ignore
import Jost from '../assets/fonts/Jost-VariableFont_wght.ttf';

// @ts-ignore
import Rambla from '../assets/fonts/Rambla-Regular.ttf';

// @ts-ignore
import RamblaBoldItalic from '../assets/fonts/Rambla-BoldItalic.ttf';
import { CertificateData } from '../../services/certificate';

Font.register({
  family: 'Jost',
  src: Jost,
});

Font.register({
  family: 'Jost',
  src: Jost,
  fontWeight: 'bold',
});

Font.register({
  family: 'Rambla',
  src: Rambla,
});

Font.register({
  family: 'Rambla',
  src: RamblaBoldItalic,
  fontStyle: 'italic',
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  pageBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  certificate: {
    marginTop: '60pt',
    width: '230px',
    height: '45px',
  },
  boldTitle: {
    fontFamily: 'Rambla',
    fontSize: '16pt',
    color: '#7d7d7d',
    marginLeft: '100pt',
    position: 'absolute',
    bottom: '30pt',
  },
  description: {
    color: '#9A9A9A',
    fontSize: '16pt',
  },
  studentName: {
    fontFamily: 'Rambla',
    fontSize: '26pt',
    color: '#4D286D',
    fontWeight: 'bold',
    fontStyle: 'italic',
    marginLeft: '98pt',
  },
  logo: {
    width: '100pt',
    height: '100pt',
  },
  courseNameBase: {
    fontSize: '10pt',
    width: '100%',
    color: '#4D286D',
    position: 'absolute',
    bottom: '140pt',
    left: '49pt',
  },
  courseNameBaseSmaller: {
    fontSize: '10pt',
    width: '100%',
    color: '#4D286D',
    position: 'absolute',
    bottom: '220pt',
    left: '49pt',
  },
  courseName: {
    width: '100%',
    fontFamily: 'Rambla',
    fontSize: '20pt',
    fontWeight: 'bold',
    fontStyle: 'italic',
    marginBottom: '10pt',
  },
  lowerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '200pt',
    marginLeft: '80pt',
    alignItems: 'center',
    width: '50%',
    position: 'relative',
  },
  signatureBase: {
    fontSize: '15pt',
    fontFamily: 'Rambla',
    width: '100%',
    paddingHorizontal: '50pt',
    fontStyle: 'italic',
  },
  projectName: {
    fontSize: '24pt',
    fontFamily: 'Rambla',
    fontStyle: 'italic',
    marginTop: '10pt',
    marginBottom: '0pt',
  },
  info: {
    color: '#831961',
    marginLeft: '7%',
    marginTop: '225pt',

    fontFamily: 'Rambla',
    fontSize: '20pt',
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  luftSignature: {
    width: '100%',
    fontFamily: 'Rambla',
    fontStyle: 'italic',
    fontSize: '11.5pt',
    paddingTop: '18pt',
    color: '#4D286D',
    fontWeight: 'bold',
  },
  time: {
    fontFamily: 'Rambla',
    fontSize: '14pt',
    color: '#4D286D',
    fontWeight: 'bold',
    fontStyle: 'italic',
    textDecoration: 'underline',
    textDecorationColor: '#9A9A9A',
    margin: '10pt',
  },
});

interface NortusCertificateTemplateProps {
  certificateData: CertificateData;
}

const CertificateTemplate: React.FC<NortusCertificateTemplateProps> = ({
  certificateData,
}) => {
  // @ts-ignore

  const convertToDuration = (secondsAmount: number) => {
    const normalizeTime = (time: string): string =>
      time.length === 1 ? `0${time}` : time;

    const SECONDS_TO_MILLISECONDS_COEFF = 1000;
    const MINUTES_IN_HOUR = 60;

    const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

    const date = new Date(milliseconds);
    const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
    const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

    const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
    const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
    const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

    const hoursOutput = hours;

    return `${hoursOutput}:${minutes}:${seconds}`;
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <Image src={background} style={styles.pageBackground} />

        <View style={styles.lowerContent}>
          <View
            style={{
              width: '600pt',
              marginLeft: '80pt',
              marginBottom: '16pt',
            }}
          >
            <Text style={styles.boldTitle}>ORGULHOSAMENTE CERTIFICAMOS</Text>

            <View style={{ width: '700pt' }}>
              <Text style={styles.studentName}>
                {certificateData.user_name}
              </Text>
            </View>
          </View>

          <View
            style={
              certificateData.reference_name.length > 218
                ? styles.courseNameBase
                : styles.courseNameBaseSmaller
            }
          >
            <Text>
              <Text style={styles.description}>
                Pela Conclusão com sucesso no curso de
              </Text>
            </Text>

            <View
              style={{
                width: '550pt',
                borderBottom: '1pt solid #9A9A9A',
                marginBottom: '12pt',
              }}
            >
              <Text style={styles.projectName}>
                {certificateData.reference_name}
              </Text>
            </View>

            <Text>
              <Text style={styles.description}>
                com a carga de dedicação de{' '}
              </Text>
              <Text style={styles.time}>
                {convertToDuration(certificateData.reference_duration)}
              </Text>
            </Text>
          </View>

          <Text style={styles.info}>Diretoria de Recursos Humanos</Text>
        </View>
      </Page>
    </Document>
  );
};

export default CertificateTemplate;
