import CourseUserFromResponse from '../models/from-api/course-user';
import TrailFromResponse from '../models/from-api/trail';
import TrailUserFromResponse from '../models/from-api/trail-user';
import Trail from '../models/trail';
import convertCourse from './convert-course';

const findCourseUser = (
  courseId: string,
  courseUsers: CourseUserFromResponse[],
): CourseUserFromResponse => {
  return (
    courseUsers.find(course => course.course_id === courseId) ||
    ({} as CourseUserFromResponse)
  );
};

const convertTrail = (
  trail: TrailFromResponse,
  trailUser?: TrailUserFromResponse,
  courses?: CourseUserFromResponse[],
): Trail => {
  const courseUsers = courses && courses.length ? courses : [];

  return {
    id: trail.trail_id,
    name: trail.name,
    description: trail.description,
    addedToList: trail.book_mark,
    thumbnail: trail.thumbnail,
    duration: +trail.duration,
    introductionUrl: trail.video_reference,
    createdAt: trail.created_at ? new Date(trail.created_at) : new Date(),
    numberOfLikes: (trail.likes && trail.likes.count) || 0,
    userLiked: (trail.likes && trail.likes.user_like) || false,
    info: trail.info && trail.info,
    courses:
      trail.courses && trail.courses.length
        ? trail.courses
            .sort((courseA, courseB) =>
              courseA.position > courseB.position ? 1 : -1,
            )
            .map(course =>
              convertCourse(
                course,
                findCourseUser(course.course_id, courseUsers),
                trail,
              ),
            )
        : [],
    author: (trail.author && trail.author.name) || '',
    review:
      trailUser && trailUser.review && Object.keys(trailUser.review).length
        ? !!trailUser.review
        : false,
    category:
      trail.category && trail.category.length
        ? trail.category.map((cat: any) => cat.title).join(', ')
        : '',
    categoryDescription:
      (trail.category && trail.category.title && trail.category.title) || '',
    alreadyFinished: trailUser ? !!trailUser.finish_at : false,
    alreadyStarted: trailUser ? !!trailUser.start_at : false,
    categoryId:
      trail.category && trail.category.length
        ? trail.category.map((cat: any) => cat.id).join(', ')
        : '',
    exam: trail.exam,
    startDate:
      trailUser && trailUser.start_at
        ? new Date(trailUser.start_at)
        : undefined,
    deadline: trail.deadline,
  };
};

export default convertTrail;
