import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';

import { getCategories as getCategoriesService } from '../../../../../../services/forum';

import { SelectContainer } from './style';

interface CategoriesSelectProps {
  questionCategory: CategoryOptionsProps;
  setQuestionCategory: React.Dispatch<
    React.SetStateAction<CategoryOptionsProps>
  >;
}

interface CategoryOptionsProps {
  label: string;
  value: string;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#e6e6e6',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(84, 0, 110, 0.2)' : 'inherit',
    color: 'var(--dark-gray)',
    '&:hover': {
      backgroundColor: 'rgba(84, 0, 110, 0.2)',
    },
  }),
};

const CategoriesSelect: React.FC<CategoriesSelectProps> = ({
  questionCategory,
  setQuestionCategory,
}) => {
  const [categoryOptions, setCategoryOptions] = useState<
    CategoryOptionsProps[]
  >([]);

  const formatCategories = (list: any) => {
    return list.map((item: any) => ({
      label: item.description,
      value: item.category_id,
    }));
  };

  const fetchCategories = useCallback(async () => {
    const response = await getCategoriesService();
    const result = formatCategories(response);
    setCategoryOptions(result);
    setQuestionCategory(result[0]);
  }, []);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <SelectContainer>
      <Select
        options={categoryOptions}
        isSearchable={false}
        value={questionCategory}
        onChange={(option: any) => option && setQuestionCategory(option)}
        styles={customStyles}
      />
    </SelectContainer>
  );
};

export default CategoriesSelect;
