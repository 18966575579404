import React, { useEffect, useState } from 'react';
import QuestionAndAnswer from './components/QuestionAndAnswer';
import Form from './components/Form';
import { getQuestions } from '../../services/faq';
import Header from '../../components/Header';
import { Container, MainHeader, Body, Title, SubTitle } from './style';

const FAQ: React.FC = () => {
  const [listQuestions, setListQuestions] = useState([]);
  const hasSigned = localStorage.getItem('telhanorte-api-token');

  const getQuestionsList = async () => {
    setListQuestions(await getQuestions());
  };

  useEffect(() => {
    getQuestionsList();
  }, []);

  return (
    <Container>
      {!!hasSigned && <Header />}
      <div className="content">
        <MainHeader>
          <Title>Perguntas frequentes</Title>
          <SubTitle>
            Tem alguma dúvida? Não se preocupe, vamos tentar respondê-las.
          </SubTitle>
        </MainHeader>
        <Body>
          {!!listQuestions &&
            listQuestions?.length &&
            listQuestions.map((item: any) => (
              <QuestionAndAnswer
                id={item.id}
                question={item.question}
                answer={item.answer}
              />
            ))}
        </Body>
      </div>
      <Form />
    </Container>
  );
};

export default FAQ;
