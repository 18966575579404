import styled from 'styled-components';

const TrailCourseContainer = styled.div`
  margin-bottom: 10px;
`;

const TrailCourseLowerContent = styled.div`
  background: #f2f2f2;
  & > *:not(:last-child) {
    border-bottom: solid 2px #f0f3f6;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TrailCourseUpperContent = styled.div`
  width: 100%;

  padding: 12px 14px;
  border: 1px solid var(--default-gray);
  background-color: var(--default-gray);

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div.duration-content {
    display: flex;
    align-items: center;
  }
  svg {
    color: var(--regular-gray);
  }
  &.module-started {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    color: #fff;
    svg {
      color: var(--secondary-color);
    }
  }
`;

const TrailCollapseIndicatorContainer = styled.div`
  width: 26px;
  height: 26px;
  transition: all 0.3s;
  transform: rotate(180deg);

  &.opened {
    transform: rotate(0deg);
  }
`;

const TrailCourseDuration = styled.div`
  font-size: 12px;
  margin-right: 10px;
  font-size: var(--font-regular);
`;

const TrailCourseTitle = styled.div`
  flex-grow: 1;
  max-width: 260px;
  font-weight: 700;
  font-size: var(--font-default);
`;

const TrailCourseStartButton = styled.button`
  cursor: pointer;
  padding: 10px;
  width: calc(50% - 10px);
  margin: 5px auto;
  border-radius: 5px;
  background: var(--secondary-color);
  border: none;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  transition: background-color 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    margin-right: 5px;
  }
  &:hover {
    background: var(--secondary-color-hover);
  }
`;

export const TrailStartExam = styled(TrailCourseStartButton)``;

const TrailCourseStartButtonContainer = styled.div`
  width: 100%;
`;

const TrailDownloadCertificate = TrailCourseStartButton;

const QuizContainer = styled(TrailCourseStartButton)`
  display: none;

  &.active {
    display: block;
  }

  strong {
    font-size: var(--font-default);
  }
  svg {
    display: block;
    margin: 0 auto;
  }
`;

export {
  TrailCourseContainer,
  TrailCourseLowerContent,
  TrailCourseUpperContent,
  TrailCollapseIndicatorContainer,
  TrailCourseDuration,
  TrailCourseTitle,
  TrailCourseStartButton,
  TrailCourseStartButtonContainer,
  TrailDownloadCertificate,
  QuizContainer,
};
