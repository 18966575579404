import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';

import { ForumPostListRowWrapper } from './style';

const ForumPost = ({ postData }: any) => {
  const history = useHistory();
  const {
    question_id,
    title,
    author,
    categories,
    tags,
    created_at,
    updated_at,
    total_answers,
    isNew,
    user,
  } = postData;
  const formattedPostDate = useMemo(() => {
    return new Intl.DateTimeFormat('pt-BR').format(new Date(created_at));
  }, [created_at]);

  const formattedLastPostDate = useMemo(() => {
    const date = new Intl.DateTimeFormat('pt-BR').format(new Date(updated_at));
    const hour = new Intl.DateTimeFormat('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(updated_at));

    return `${date}, ${hour}`;
  }, [updated_at]);

  const postCategory = useMemo(() => {
    if (!categories) {
      return <></>;
    }

    return (
      <div className="tag">{categories[0] && categories[0].description}</div>
    );
  }, [categories]);

  const goToPost = (id: string) => {
    history.push(`/forum/post/${id}`);
  };

  return (
    <>
      <ForumPostListRowWrapper
        new={isNew}
        onClick={() => goToPost(question_id)}
      >
        <td className="title">
          <span>{title}</span>

          {postCategory}
        </td>

        <td className="createdBy">
          <BiUserCircle size={48} color="#BDBDBD" />
          {user.name}
        </td>

        <td className="date">{formattedPostDate}</td>
        <td className="lastPost">{formattedLastPostDate}</td>
        <td className="answerCount">{total_answers || 'Sem respostas'}</td>
      </ForumPostListRowWrapper>
    </>
  );
};

export default ForumPost;
