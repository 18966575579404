import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  border: none;
  background-color: var(--primary-color);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 14px;
  transition: background-color 0.2s;

  &:hover {
    background: var(--primary-color-hover);
  }

  svg {
    width: 64px;
    height: 64px;
    margin-right: 4px;
  }

  @media (max-width: 768px) {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
