import httpClient from '../../http-client';
import UserFile from '../../models/user-file';

const uploadUserFile = async (newFile: FormData) => {
  const uploadFile = await httpClient.post(`/user-files`, newFile, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return uploadFile;
};

const getUploadedFiles = async (): Promise<UserFile[]> => {
  const uploadedFile = (
    await httpClient.get<UserFile[]>(`/user-files/list/user`)
  ).data;

  return uploadedFile;
};

const deleteUploadedFile = async (fileId: string) => {
  const deletedFile = await httpClient.delete(`/user-files/delete/${fileId}`);

  return deletedFile;
};

export { uploadUserFile, getUploadedFiles, deleteUploadedFile };
