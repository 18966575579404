import styled from 'styled-components';

const DefaultTableContainer = styled.div`
  width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const Thead = styled.thead``;

const Tbody = styled.tbody``;

const Tr = styled.tr`
  color: var(--dark-gray);
  td:first-of-type {
    color: var(--primary-color);
    font-weight: 700;
  }
`;

const Th = styled.th`
  padding: 10px;
  font-size: 10px;
  color: var(--dark-gray);
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.9px;
  &:last-of-type,
  &:nth-last-child(-n + 2) {
    text-align: center;
  }
`;

const Td = styled.td`
  padding: 16px;
  font-size: var(--font-small);
  border-bottom: 1px solid #e9ecef;
  word-break: break-word;
  svg {
    display: block;
    margin: 0 auto;
  }
  > img {
    width: 120px !important;
  }
`;

const DefaultTablePagination = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
  margin-bottom: 10px;

  button {
    width: 30px;
    height: 30px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export {
  DefaultTableContainer,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Table,
  DefaultTablePagination,
};
