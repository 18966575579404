import styled from 'styled-components';

export const Container = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  margin-left: 12px;

  span {
    font-size: var(--font-default);
    font-weight: 500;
  }

  svg {
    margin: 0 12px 4px 0;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;
