import { useContext, useEffect, useRef } from 'react';
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from 'body-scroll-lock';
import Term from './components/Term';
import * as Style from './styles';
import Swal from 'sweetalert2';
import { acceptTerms, getActualUser } from '../../services/user';

interface TermModalProps {
  userHasAccepted: boolean;
  setUserHasAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

const TermModal: React.FC<TermModalProps> = ({
  userHasAccepted,
  setUserHasAccepted,
}) => {
  const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
  const isIphone = navigator.userAgent.indexOf('iPhone') !== -1;
  const isMobileIosSafari = isSafari && isIphone;
  const modalRef: any = useRef(null);

  useEffect(() => {
    if (!isMobileIosSafari) {
      userHasAccepted
        ? enableBodyScroll(modalRef.current)
        : disableBodyScroll(modalRef.current);
    }
  }, [userHasAccepted]);

  const onButtonClick = () => {
    (async () => {
      try {
        await acceptTerms(true);
        setUserHasAccepted(true);

        const user = await getActualUser();
        window.localStorage.setItem('telhanorte-user', JSON.stringify(user));

        clearAllBodyScrollLocks();
      } catch (error) {
        Swal.fire({
          title: 'Algo deu errado!',
          text: error.message,
          icon: 'error',
        });
      }
    })();
  };

  return (
    <Style.Container ref={modalRef}>
      <Style.Modal>
        <Style.Body>
          <Term />
        </Style.Body>
        <Style.Button type="button" onClick={onButtonClick}>
          Aceito
        </Style.Button>
      </Style.Modal>
    </Style.Container>
  );
};

export default TermModal;
