import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  max-width: 1300px;
  padding: 54px 244px 100px 190px;
  position: relative;
  svg {
    cursor: pointer;
    position: absolute;
    left: 60px;
  }
  @media (max-width: 780px) {
    padding: 60px 26px 35px 26px;
    svg {
      top: 0;
      left: 14px;
      margin: 16px 0;
    }
  }
`;

const Title = styled.h1`
  color: var(--primary-color);
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  @media (max-width: 780px) {
    font-size: 16px;
    margin-top: 8px;
  }
`;

const SubTitle = styled.p`
  margin-top: 8px;
  font-weight: bold;
  font-size: 24px;
  line-height: 48px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  @media (max-width: 780px) {
    font-size: 18px;
    margin: 0 0 24px 0;
    padding-bottom: 16px;
  }
`;

const ExamContainer = styled.div`
  width: 100%;
  > div {
    margin-bottom: 48px;
  }
  hr {
    margin: 31px 0 30px;
  }
`;

const Question = styled.h2`
  font-size: 18px;
  margin-bottom: 24px;
  @media (max-width: 780px) {
    font-size: 14px;
  }
`;

const InputBlock = styled.div`
  display: flex;
  padding: 5px 0 5px 12px;
  margin-bottom: 6px;
  margin-left: 10px;

  &.correct,
  &.correct-checked {
    background: #1372e5;

    label {
      color: #fff;
    }

    input {
      border-color: #fff;

      &:checked:before {
        background: #fff;
      }
    }
  }

  &.correct-checked {
    background: #00875a;

    input:checked {
      border-color: #fff;
    }
  }
`;

const Input = styled.input`
  margin-right: 10px;
  & {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #444444;
    border-radius: 50%;
    outline: none;
    box-shadow: 0 0 5px 0px gray initial;
  }
  &:checked {
    border-color: var(--primary-color);
  }
  &:before {
    content: '';
    display: block;
    width: 80%;
    height: 80%;
    margin: 10% 12%;
    border-radius: 50%;
  }
  &.wrong:checked {
    border-color: #d90038;
  }

  &:checked:before {
    background: ${({ disabled }) =>
      disabled ? 'gray' : 'var(--primary-color)'};
  }

  &.wrong:checked:before {
    background: ${({ disabled }) =>
      disabled ? '#D90038' : 'var(--primary-color)'};
  }
  @media (max-width: 780px) {
    &:before {
      margin: 11% 12%;
    }
  }
`;

const Label = styled.label`
  width: 100%;
  color: #444;
  margin-bottom: 0.5rem;
  margin-top: 2px;
  cursor: pointer;
  &.selected {
    font-weight: bold;
    color: #54006e;
  }

  &.wrong {
    font-weight: bold;
    color: #d90038;
  }

  @media (max-width: 780px) {
    font-size: 14px;
  }
`;

const ModalButton = styled.button`
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.4px;
  width: 100%;
  border: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
`;

const PrimaryButton = styled(ModalButton)`
  background-color: #000;
  color: #fff;
  padding: 8.5px 32px;
  border-radius: 6px;
`;
const SecondaryButton = styled(ModalButton)`
  background-color: transparent;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 10px;
`;

const BtnGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px 14px 11px;
`;

const Button = styled.button`
  border: none;
  font-weight: bold;
  background: var(--secondary-color);
  padding: 18px 28px;
  border-radius: 4%;
  margin-top: 20px;
  font-size: 18px;
  letter-spacing: 1px;
  cursor: pointer;
  &:hover {
    background: var(--secondary-color);
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`;

export {
  Container,
  Title,
  SubTitle,
  ExamContainer,
  Question,
  InputBlock,
  Input,
  Label,
  BtnGroup,
  SecondaryButton,
  PrimaryButton,
  Button,
};
