import styled from 'styled-components';

export const Container = styled.div`
  width: 440px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 160px;
    height: 66px;
  }

  h3 {
    margin-top: 52px;
  }

  form {
    width: 100%;
    margin: 52px 0px;

    > div {
      margin-bottom: 24px;
    }

    button[type='submit'] {
      margin-top: 64px;
    }
  }

  @media (max-width: 768px) {
    img {
      width: 119px;
    }

    h3 {
      margin-top: 40px;
      font-size: var(--font-default);
    }

    form {
      width: 70%;

      a {
        text-decoration: none;
        font-size: var(--font-small);
        font-weight: 400;
        color: var(--regular-gray);
      }
    }
  }

  @media (min-width: 1440px) {
    padding-top: 68px;

    h3 {
      margin-top: 75px;
    }

    form {
      margin-top: 56px;
    }
  }
`;
