import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  display: flex;
  margin: 58px 0 auto;
  h2 {
    font-size: 32px;
  }
  div.dropdown {
    display: none;
  }
  @media (max-width: 768px) {
    margin-top: 42px;
    h2 {
      display: none;
    }
    aside {
      display: none;
    }
    > div {
      width: 100%;
    }
    div.dropdown {
      display: block;
    }
  }
`;

export const CertificatesList = styled.div`
  margin-top: 32px;
`;
