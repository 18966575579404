import httpClient from '../../http-client';

export interface IUserReviews {
  content_id: string;
  user_id: string;
  user_name: string;
  user_roles: any[];
  created_at: "2022-02-07T17:01:45.940Z";
  stars: number | null;
  description: string;
}

const getReviews = async () => {
  const depositions = (
    await httpClient.get(
      '/trails/review/trail-and-content?show_to_all_users=true',
    )
  ).data;

  return depositions;
};

const getContentUserReviews = async (contentId: string) => {
  const allReviews = (
    await httpClient.get<IUserReviews[]>(
      `/content-user-reviews/${contentId}`,
    )
  ).data;

  return allReviews;
};

const reviewContent = async (contentId: string, description: string, rating: number) => {
  await httpClient.post(`/content-user-reviews`, {
    content_id: contentId,
    description: description,
    stars: rating,
  });
};

export { reviewContent, getReviews, getContentUserReviews };
