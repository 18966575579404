import httpClient from '../../http-client';
import ForumCategoryFromResponse from '../../models/from-api/forum-category';

interface IQuestionRequestParams {
  title: string;
  description: string;
  categories: {
    category_id: string;
  }[];
}

const getPostsLength = async () => {
  let questionsNumber = await httpClient
    .get('/forum/questions')
    .then(response => response.data.length);

  return questionsNumber;
};

const getPosts = async (page: number) => {
  let questions = await httpClient
    .get('/forum/questions', {
      params: {
        limit: (page - 1) * 10 + 10,
        offset: page > 1 ? (page - 1) * 10 : undefined,
      },
    })
    .then(response => response.data);

  return questions;
};

const getQuestionData = async (question_id: string) => {
  let questions = await httpClient
    .get(`/forum/questions/${question_id}`)
    .then(response => response.data);

  return questions;
};

const getQuestionAnswers = async (
  question_id: string,
  page: number,
  order_by: 'new' | 'best',
) => {
  let answers = await httpClient
    .get(`/forum/answers`, {
      params: {
        question_id,
        limit: (page - 1) * 10 + 10,
        offset: page > 1 ? (page - 1) * 10 : undefined,
        most_liked: order_by === 'best' || undefined,
        most_recent: order_by === 'new' || undefined,
      },
    })
    .then(response => response.data);

  return answers;
};

const createAnswer = async (question_id: string, answer: string) => {
  await httpClient.post(`/forum/answers/`, {
    description: answer,
    question_id: question_id,
  });
};

const createCommentary = async (
  answer_reference_id: string,
  answer: string,
) => {
  await httpClient.post(`/forum/answers/`, {
    description: answer,
    answer_reference_id: answer_reference_id,
  });
};

const updateRating = async (answer_id: string, rating: boolean) => {
  let { data } = await httpClient.put(`/forum/answer-ratings/${answer_id}`, {
    rating,
  });

  return data;
};

const createNewQuestion = async (params: IQuestionRequestParams) => {
  let { data } = await httpClient.post('/forum/questions', params);

  return data;
};

const getCategories = async () => {
  let categories = (
    await httpClient.get<ForumCategoryFromResponse[]>('/forum/categories')
  ).data;
  return categories;
};

export {
  getPosts,
  getPostsLength,
  getQuestionData,
  getQuestionAnswers,
  createAnswer,
  createCommentary,
  updateRating,
  createNewQuestion,
  getCategories,
};
