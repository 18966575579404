import styled from 'styled-components';

export const Container = styled.div`
  max-width: 800px;

  header {
    position: relative;
    height: 255px;

    img {
      width: 800px;
      border-radius: 8px 8px 0 0;
    }

    div {
      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      span {
        font-size: 20px;
      }

      strong {
        font-size: 32px;
      }
    }

    button {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 20px;
      border: none;
      background: none;
    }
  }

  @media (max-width: 780px) {
    max-width: 350px;

    header {
      img {
        width: 350px;
        height: 255px;
        object-fit: cover;
      }
    }
    div {
      strong {
        overflow: auto;
      }
    }
  }
`;

export const ContentItems = styled.div`
  color: var(--regular-gray);
  width: 100%;
  padding: 32px;
  background: #fff;
  border-radius: 0 0 8px 8px;

  div.title {
    text-align: center;
  }

  span {
    font-size: 22px;
    margin-bottom: 8px;
  }

  strong {
    font-size: 22px;
    display: block;
  }

  form {
    display: flex;
    flex-direction: column;

    label {
      font-weight: 500;
      color: #000;
      margin-top: 32px;
    }

    textarea {
      padding: 8px;
      resize: none;
      height: 150px;
      border-radius: 6px;
      background: #f2f2f2;
      outline: none;

      -webkit-user-select: auto;
    }

    .characterCounter {
      font-size: 14px;
    }
  }

  div.buttons {
    margin-top: 50px;
    text-align: end;
  }
`;

export const SubmitButton = styled.button`

  cursor: pointer;
  border: none;
  font-size: var(--font-large);
  font-weight: 500;
  padding: 16px 32px;
  background: none;
  color: var(--dark-gray);
  transition: all 0.2s;

  margin-left: 16px;
  background: ${(props) => props.disabled ? 'var(--light-gray)': 'var(--secondary-color)'};
  border-radius: 6px;
  color: #000;

  &:hover {
    filter: ${(props) => props.disabled ? undefined : 'opacity(0.9)'};
  }
`;