import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import {
  AiOutlineCalendar,
  AiOutlineClockCircle
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';

import CourseImage from '../../assets/banner-image.png';

import { toast } from 'react-toastify';

import {
  Container,
  BookmarkButton,
} from './style';
import {
  addBookMarkLive,
  removeBookMarkLive,
} from '../../services/bookmark';
import Live from '../../models/lives';
import { capitalize } from '../../helpers/capitalize';

interface ICourseProps {
  live: Live;
  hasAction?: boolean;
  onAddToList?: (live_id: string) => void;
}

const LiveCardItem: React.FC<ICourseProps> = ({ live, hasAction = false, onAddToList }) => {
  const history = useHistory();
  const [addedToList, setAddedToList] = useState(live.book_mark);

  const goToLive = useCallback(() => {
    if (hasAction) {
      history.push(`/lives/${live.live_id}`);

    }
  }, [hasAction, history, live.live_id]);

  const addToList = useCallback(async (event: React.MouseEvent) => {
    event.stopPropagation();

    try {
      setAddedToList(!addedToList);

      if (!addedToList) {
        await addBookMarkLive(live.live_id);
      } else {
        await removeBookMarkLive(live.live_id);
      }

      if (onAddToList) {
        onAddToList(live.live_id)
      }

    } catch (e) {
      setAddedToList(!!addedToList);
      toast.error('Erro ao adicionar live aos favoritos.');
    }
  }, [addedToList, live.live_id, onAddToList]);

  return (
    <Container onClick={goToLive}>
      <div>
        <img src={live.thumbnail_url || CourseImage} alt="Live" />

        <h4>{live.name}</h4>

        <BookmarkButton onClick={e => addToList(e)}>
          <BsBookmark
            size={24}
            color="#FFF"
            className={`${addedToList && 'favorite'}`}
          />
        </BookmarkButton>
      </div>

      <footer>
        <div>
          <AiOutlineCalendar />
          <span> {live.start && capitalize(format(new Date(live.start), 'ccc, dd/MM', { locale: pt }))}</span>
        </div>
        <span className="divider" />
        <div>
          <AiOutlineClockCircle />
          <span>{live.start && format(new Date(live.start), "hh'h'mm'm'")}</span>
        </div>
      </footer>
    </Container>
  );
};

export default LiveCardItem;
