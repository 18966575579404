import styled from 'styled-components';

export const MaterialsList = styled.ul`
  > li {
    max-width: 70%;
    height: 65px;
    list-style: none;
    padding: 20px 22px;
    border: 1px solid #f2f2f2;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
      > svg {
        margin-right: 16px;
      }
    }
    span {
      color: #000;
      font-size: 15px;
    }
    & + li {
      margin-top: 32px;
    }
  }
`;
