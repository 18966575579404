import React, { useState } from 'react';
import { FiX } from 'react-icons/fi';
import { toast } from 'react-toastify';

import Banner from '../../../../../../assets/deposition-banner.png';
import checkEmptyString from '../../../../../../helpers/check-empty-string';

import { reviewContent } from '../../../../../../services/content-review';
import { Rating } from 'react-simple-star-rating'
import { Container, ContentItems, SubmitButton } from './style';
import Content from '../../../../../../models/content';
import Textarea from '../../../../../../components/Inputs/Textarea';

interface ICreateDepositionModal {
  onClose: () => void;
  content: Content;
  concludedDeposition: () => void;
}

const DepositionContent: React.FC<ICreateDepositionModal> = ({
  onClose,
  content,
  concludedDeposition,
}) => {
  const [description, setDescription] = useState('');
  const [rating, setRating] = useState(0);

  const handleRating = (rate: number) => {
    console.log(rate);
    
    setRating(rate)
  }

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const stars = rating/20
    try {
      await reviewContent(content.id, description, stars);
      toast.success('Avaliação cadastrada com sucesso!');
      onClose();
      concludedDeposition();
    } catch (err) {
      toast.warning('Por favor nos deixe a sua avaliação do conteúdo!');
    }
  };

  return (
    <Container>
      <header>
        <img src={Banner} alt="Banner" />

        <div>
          <span>Você concluiu:</span>
          <strong>{content.title}</strong>
        </div>
      </header>

      <ContentItems>
        <div className="title">
          <span>Avalie este conteúdo!</span>
          <br />
          <Rating
            onClick={handleRating}
            ratingValue={rating}
            fillColorArray={[
              '#f17a45',
              '#f19745',
              '#f1a545',
              '#f1b345',
              '#f1d045',
            ]}
          />
          <strong className="depo-call">Deixe seu depoimento</strong>
        </div>

        <form onSubmit={onSubmit}>
          <textarea onChange={e => setDescription(e.target.value)} autoFocus  maxLength={1000} />
          <span className="characterCounter">{description.length}/1000</span>
          <div className="buttons">
            <SubmitButton 
              disabled={rating > 0 ? false : true}
            >
              Enviar avaliação
            </SubmitButton>
          </div>
        </form>
      </ContentItems>
    </Container>
  );
};

export default DepositionContent;
