import styled from 'styled-components';

const TrailCourseContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  cursor: pointer;
  min-height: 60px;
  border: 1px solid var(--default-gray);
  &.not-allowed {
    cursor: not-allowed;
  }
`;

const TrailCourseContentDuration = styled.div`
  font-size: 12px;
  padding: 10px 0;
`;

const TrailCourseContentTitle = styled.div`
  font-size: 14px;
  margin: 0 10px;
  flex-grow: 1;
  padding: 10px 0;
  &.selected {
    font-weight: 500;
  }
`;

const TrailCourseContentFluxIndicatorContainer = styled.div`
  max-height: 80px;
  height: 100%;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TrailCourseContentFluxIndicator = styled.div`
  border: solid 2px var(--medium-gray);
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &.selected {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
  }
  &.flux-bar {
    &::before {
      content: '';
      height: 30px;
      position: absolute;
      top: 28px;
      width: 1px;
      background: var(--medium-gray);
    }
  }
`;

export {
  TrailCourseContentContainer,
  TrailCourseContentDuration,
  TrailCourseContentTitle,
  TrailCourseContentFluxIndicator,
  TrailCourseContentFluxIndicatorContainer,
};
