import httpClient from '../../http-client';
import CategoryFromResponse from '../../models/from-api/category';
import Category from '../../models/category';
import convertCategory from '../../converters/convert-category';

const getCategories = async (): Promise<Category[]> => {
  let categories = (await httpClient.get<CategoryFromResponse[]>('/categories'))
    .data;

  let convertedCategories = [] as Category[];
  if (categories && categories.length) {
    categories = categories.filter(category => category.is_active);

    convertedCategories = categories.map(convertCategory);
  }

  return convertedCategories;
};

const getContentsCategories = async (): Promise<Category[]> => {
  let categories = (
    await httpClient.get<CategoryFromResponse[]>('/categories/attach-content')
  ).data;

  let convertedCategories = [] as Category[];
  if (categories && categories.length) {
    categories = categories.filter(category => category.is_active);

    convertedCategories = categories.map(convertCategory);
  }

  return convertedCategories;
};

const getTrailsCategories = async (): Promise<Category[]> => {
  let categories = (
    await httpClient.get<CategoryFromResponse[]>('/categories/attach-trail')
  ).data;

  let convertedCategories = [] as Category[];
  if (categories && categories.length) {
    categories = categories.filter(category => category.is_active);

    convertedCategories = categories.map(convertCategory);
  }

  return convertedCategories;
};

const getCategory = async (categoryId: string) => {
  const category = (await httpClient.get(`/categories/${categoryId}`)).data;

  return convertCategory(category);
};

export {
  getCategories,
  getCategory,
  getContentsCategories,
  getTrailsCategories,
};
