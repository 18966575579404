import download from 'downloadjs';
import httpClient from '../../http-client';
import { generateCertificate } from '../../pdf/generateCertificate';

export interface CertificateData {
  user_name: string;
  certificate_id: string;
  emmited_at: string;
  reference_id: string;
  reference_duration: number;
  trail_finish_at: string;
  trail_id: string;
  reference_name: string;
  trail_category: string;
}

export interface ICertificate {
  certificate_id: string;
  reference_id: string;
  reference_type: string;
  reference_name: string;
  reference_duration: number,
  reference_finish_at:string;
  emitted_at: string;
}

const downloadCertificate = async (trailId: string, isTrail?: boolean) => {
  let certificateData = undefined as CertificateData | undefined;

  try {
    certificateData = (
      await httpClient.get<CertificateData>(`certificates/${trailId}`)
    ).data;
  } catch (e) {}

  if (!certificateData) {
    if (isTrail) {
      certificateData = await setTrailCertificate(trailId);
    } else {
      certificateData = await setContentCertificate(trailId);
    }
  }

  const certificate = await generateCertificate(certificateData!);

  download(certificate, 'certificado');
};

const tryToSetCertificate = async (id: string, isTrail?: boolean) => {
  try {
    if (isTrail) {
      await setTrailCertificate(id);
    } else {
      await setContentCertificate(id);
    }
  } catch (e) {}
};

const getAllCertificates = async () => {
  const certificates = (
    await httpClient.get<CertificateData[]>('/certificates')
  ).data;

  return certificates;
};

const setTrailCertificate = async (trailId: string) => {
  const certificateResponse = (
    await httpClient.post<CertificateData>(`certificates`, {
      trail_id: trailId,
    })
  ).data;

  return certificateResponse;
};

const checkCertificates = async () => {
  const checkCertificateResponse = (
    await httpClient.get<ICertificate[]>(`certificates/check`)
  ).data;

  return checkCertificateResponse;
};

const setContentCertificate = async (contentId: string) => {
  const certificateResponse = (
    await httpClient.post<CertificateData>(`certificates`, {
      content_id: contentId,
    })
  ).data;
  
  return certificateResponse;
};

export {
  downloadCertificate,
  getAllCertificates,
  setTrailCertificate,
  setContentCertificate,
  tryToSetCertificate,
  checkCertificates
};
