import React, { useState, useCallback, useEffect } from 'react';

import OngoingCourseCard from '../../../../components/OngoingCourseCard';
import ProfileMenu from '../../components/ProfileMenu';

import OngoingCourse from '../../../../services/course/models/ongoing-course-interface';
import {
  getOngoingContents,
  getOngoingCourses,
} from '../../../../services/course';

import { Container, OngoingList, SelectContainer } from './style';
import MobileMenuDropdown from '../../components/MobileMenuDropdown';
import GridShimmer from '../../../../components/GridShimmer';
import { IoIosArrowDown } from 'react-icons/io';
import OngoingContentCard from '../../../../components/OngoingContentCard';

const OngoingCourses: React.FC = () => {
  const [ongoingCourses, setOngoingCourses] = useState([] as OngoingCourse[]);
  const [ongoingContents, setOngoingContents] = useState([] as any[]);
  const [contentSelected, setContentSelected] = useState('contents');
  const [isLoading, setIsLoading] = useState(false);

  const refreshOngoingCourses = useCallback(async () => {
    setIsLoading(true);
    const ongoingFilters = {};

    const localOngoingCourses = await getOngoingCourses(ongoingFilters);
    if (localOngoingCourses && localOngoingCourses.length) {
      setOngoingCourses(localOngoingCourses);
    }
    setIsLoading(false);
  }, []);

  const refreshOngoingContents = useCallback(async () => {
    setIsLoading(true);
    const ongoingFilters = {};

    const localOngoingContents = await getOngoingContents(ongoingFilters);

    if (localOngoingContents && localOngoingContents.length) {
      setOngoingContents(localOngoingContents);
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await refreshOngoingCourses();
      await refreshOngoingContents();
    })();
  }, [refreshOngoingCourses]);

  return (
    <Container className="content">
      <ProfileMenu />

      <div>
        <MobileMenuDropdown className="dropdown" />

        <header>
          <h2>
            {contentSelected === 'contents'
              ? 'Cursos em andamento'
              : 'Trilhas em andamento'}
          </h2>

          <SelectContainer>
            <select onChange={e => setContentSelected(e.target.value)}>
              <option value="contents">Cursos em andamento</option>
              <option value="trails">Trilhas em andamento</option>
            </select>
            <IoIosArrowDown size={20} />
          </SelectContainer>
        </header>
        {isLoading ? (
          <div className="shimmer">
            <GridShimmer />
          </div>
        ) : (
          <>
            {contentSelected === 'trails' ? (
              <OngoingList>
                {ongoingCourses && ongoingCourses.length ? (
                  ongoingCourses.map(course => (
                    <OngoingCourseCard key={course.trail_id} course={course} />
                  ))
                ) : (
                  <h4 style={{ width: '100%', padding: '15px' }}>
                    Não foram encontradas trilhas em andamento.
                  </h4>
                )}
              </OngoingList>
            ) : (
              <OngoingList>
                {ongoingContents && ongoingContents.length ? (
                  ongoingContents.map(content => (
                    <OngoingContentCard
                      key={content.content_id}
                      course={content}
                    />
                  ))
                ) : (
                  <h4 style={{ width: '100%', padding: '15px' }}>
                    Não foram encontrados cursos em andamento.
                  </h4>
                )}
              </OngoingList>
            )}
          </>
        )}
      </div>
    </Container>
  );
};

export default OngoingCourses;
