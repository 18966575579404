import styled from 'styled-components';

export const Container = styled.div`
  padding-bottom: 48px;
  margin-bottom: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  h2 {
    font-size: var(--font-largest);
    margin-bottom: 32px;
  }

  button.slick-arrow {
    width: 36px;
    height: 36px;
    top: -74px;

    &.slick-prev {
      right: 78px;
    }

    svg {
      width: 16px;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 32px;
    margin-bottom: 24px;

    h2 {
      font-size: var(--font-default);
      margin-bottom: 16px;
    }
  }
`;
