import React, { useState, useEffect } from 'react';
import { getCategories, getContentsCategories } from '../../services/category';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

import Shimmer from './Shimmer';

import {
  Container,
  CategoryItem,
  SubCategoriesItems,
  CategoryItemContainer,
} from './style';

import { toast } from 'react-toastify';
import CategoryItemOnly from './CategoryItemOnly';

interface IMenuCategories {
  onClick: (categoryId: string) => void;
  selectedCategory: string;
}

const MenuCategories: React.FC<IMenuCategories> = ({
  onClick,
  selectedCategory,
}) => {
  const [categories, setCategories] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [showSubCategories, setShowSubCategories] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const categories = await getContentsCategories();

        if (categories && categories.length) {
          setCategories(categories);
        }
      } catch (e) {
        toast.error('Erro ao carregar as categorias disponíveis.');
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <Container>
      <h3>Categorias</h3>

      {isLoading ? (
        <Shimmer />
      ) : (
        <>
          {categories &&
            categories.map(category => (
              <CategoryItemOnly
                category={category}
                onClick={onClick}
                selectedCategory={selectedCategory}
              />
            ))}
        </>
      )}
    </Container>
  );
};

export default MenuCategories;
