import styled from 'styled-components';

export const Container = styled.div`
  width: 440px;
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 160px;
    height: 66px;
  }

  h3 {
    margin-top: 52px;
  }

  form {
    width: 100%;
    margin: 52px 0px;

    > div {
      margin-bottom: 24px;
    }

    button[type='submit'] {
      margin-top: 64px;
    }
  }

  p.resend {
    font-size: var(--font-regular);
    color: var(--regular-gray);

    button {
      cursor: pointer;
      font-size: var(--font-regular);
      color: var(--regular-gray);
      background: none;
      border: none;
      text-decoration: underline;
    }
  }

  @media (max-width: 768px) {
    img {
      width: 119px;
    }

    h3 {
      margin-top: 40px;
      font-size: var(--font-default);
    }

    form {
      width: 70%;

      a {
        text-decoration: none;
        font-size: var(--font-small);
        font-weight: 400;
        color: var(--regular-gray);
      }
    }
  }

  @media (min-width: 1440px) {
    padding-top: 68px;

    h3 {
      margin-top: 75px;
    }

    form {
      margin-top: 56px;
    }
  }
`;

export const TooltipInfo = styled.div`
  span {
    width: 208px;
    font-size: var(--font-small);
    padding: 10px;
    position: absolute;
    margin: 28px 0px 0px 44px;
    background: var(--primary-color);
    color: var(--white-font-color);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;

    &::before {
      content: '';
      border-style: solid;
      border-color: var(--primary-color) transparent;
      border-width: 0px 8px 8px 8px;
      position: absolute;
      bottom: 100%;
      left: 10%;
      box-shadow: 4px 5px 35px rgba(0, 0, 0, 0.05);
    }
  }

  svg {
    cursor: pointer;
    position: absolute;
    margin-left: 64px;
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
