import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiFillLike,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';

import CourseImage from '../../assets/banner-image.png';

import { toast } from 'react-toastify';

import {
  Container,
  Coursefooter,
  CourseTime,
  CourseLikes,
  BookmarkButton,
} from './style';
import {
  addContentBookmark,
  removeContentBookmark,
} from '../../services/bookmark';
import Content from '../../models/content';
import { secondsToTimeText } from '../../helpers/time';
import { Rating } from 'react-simple-star-rating'
import { getContentUserReviews, getReviews, IUserReviews } from '../../services/content-review';
import { average, evaluationGrade } from '../../utils/getReviews';

interface ICourseProps {
  course: Content;
  onAddToList?: () => void;
}

const CourseCardItem: React.FC<ICourseProps> = ({ course, onAddToList }) => {
  const history = useHistory();
  const [addedToList, setAddedToList] = useState(course.addedToList);
  const [addedToLike, setAddedToLike] = useState(course.userLiked);
  const [rating, setRating] = useState(0);
  
  const goToCourse = () => {
    history.push(`/courses/${course.id}`);
  };

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (course) {
      try {
        const shouldAddToList = !course.addedToList;

        if (shouldAddToList) {
          await addContentBookmark(course.id);
        } else {
          await removeContentBookmark(course.id);
        }

        course.addedToList = shouldAddToList;
        setAddedToList(course.addedToList);

        if (!!onAddToList) {
          onAddToList();
        }
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  const reviews = useCallback(async () => {
    const allReviews = await getContentUserReviews(course.id);    
    const qtdReviews = allReviews.length;
    const averageOfStarts = average(allReviews, qtdReviews);
    const stars = evaluationGrade(averageOfStarts) || 0;

    setRating(stars);

  },[course.id]);

  useEffect(() => {
    reviews();
  },[reviews]);

  return (
    <Container onClick={goToCourse}>
      <div>
        <img src={course.thumbnail} alt="Course" />
        <Rating
            ratingValue={rating}
            fillColorArray={[
              '#f17a45',
              '#f19745',
              '#f1a545',
              '#f1b345',
              '#f1d045',
            ]}
            size={20}
            allowHalfIcon
            readonly
          />

        <h4>{course.title}</h4>

        <BookmarkButton onClick={e => addToList(e)}>
          <BsBookmark
            size={24}
            color="#FFF"
            className={`${addedToList && 'favorite'}`}
          />
        </BookmarkButton>
      </div>

      <Coursefooter>
        <CourseTime>
          <AiOutlineClockCircle />
          <span>{`${secondsToTimeText(course.duration)}`}</span>
        </CourseTime>
        <span className="divider" />
        <CourseLikes>
          {addedToLike ? (
            <AiFillLike color="#555" />
          ) : (
            <AiOutlineLike color="#555" />
          )}

          <span>
            {course.numberOfLikes &&
              `${course.numberOfLikes} ${
                course.numberOfLikes === 1 ? 'curtida' : 'curtidas'
              }`}
          </span>
        </CourseLikes>
      </Coursefooter>
    </Container>
  );
};

export default CourseCardItem;
