import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  margin-bottom: 32px;
  width: 100%;

  header {
    width: 320px;
    width: 100%;
    height: 48px;
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    border: 1px solid var(--light-gray);
    border-radius: 6px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    &.open {
      border-radius: 6px 6px 0 0;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  border: 1px solid var(--default-gray);
  border-radius: 0 0 8px 8px;

  a {
    text-decoration: none;
    display: block;
    font-size: var(--font-regular);
    color: var(--dark-gray);
    line-height: 22px;
    padding: 12px 32px;
    transition: all 0.2s;

    &:first-child {
      margin-top: 12px;
    }

    &:hover,
    &.active {
      background: rgba(84, 0, 110, 0.2);
      font-weight: bold;
    }
  }
`;

export const MenuItem = styled(Link)``;
