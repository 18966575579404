import React from 'react';
import { FiDownload } from 'react-icons/fi';
import { downloadCertificate as downloadCertificateService } from '../../services/certificate';
import Certificado from '../../assets/certificado.png';
import { toast } from 'react-toastify';
import {
  Container,
  CertificateImage,
  Content,
  ButtonsContainer,
} from './style';
import getErrorMessage from '../../helpers/get-error-message';

interface ICertificateCardProps {
  course: any;
}

const CertificateCard: React.FC<ICertificateCardProps> = ({ course }) => {
  const downloadCertificate = async (courseId: string) => {
    try {
      await downloadCertificateService(
        courseId,
        course.reference_type !== 'content',
      );
    } catch (e) {
      const errorMessage = getErrorMessage(e);
      toast.error('Erro ao baixar certificado. ' + errorMessage);
    }
  };

  return (
    <Container>
      <CertificateImage>
        <img src={Certificado} alt="Certificate" />
      </CertificateImage>

      <Content>
        <div className='test'>
          <h1>{course.reference_name}</h1>
        </div>
        <p>
          Concluído em{' '}
          <span>
            {new Intl.DateTimeFormat('pt-BR').format(
              new Date(course.reference_finish_at),
            )}
          </span>
        </p>

        <ButtonsContainer>
          <button onClick={() => downloadCertificate(course.reference_id)}>
            <FiDownload />
            Baixar
          </button>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default CertificateCard;
