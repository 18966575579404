import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BiUserCircle } from 'react-icons/bi';

import HeaderLogo from '../../assets/logo.svg';

import SearchInput from '../Inputs/SearchInput';
import Menu from '../Menu';

import PerfilAvatar from '../../assets/perfil.png';
import ProfileDropdown from './components/ProfileDropdown';

import {
  HeaderContainer,
  HeaderContent,
  ProfileContainer,
  HeaderUtilities,
} from './style';
import User from '../../models/user';

const Header: React.FC = () => {
  const [user, setUser] = useState<User>();

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const localUser = localStorage.getItem('telhanorte-user');

    if (localUser) {
      const parsedLocalUser = JSON.parse(localUser);

      setUser(parsedLocalUser);
    }
  }, []);

  return (
    <HeaderContainer>
      <HeaderContent className="content">
        <Link to="/home" className="logo">
          <img src={HeaderLogo} alt="logo" />
        </Link>

        <Menu />

        <HeaderUtilities>
          <SearchInput />

          <ProfileContainer
            className={`
          ${
            location.pathname === '/profile' ||
            location.pathname === '/favorite-courses' ||
            location.pathname === '/ongoing-courses' ||
            location.pathname === '/certificates'
              ? 'active'
              : ''
          }
        `}
          >
            <button onClick={() => history.push('/profile')}>
              <span>Perfil</span>

              {user?.info.avatar && user.info.avatar !== '' ? (
                <img src={user.info.avatar} alt="Avatar" className="avatar" />
              ) : (
                <BiUserCircle color="#BDBDBD" />
              )}
            </button>

            <ProfileDropdown />
          </ProfileContainer>
        </HeaderUtilities>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
