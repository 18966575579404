import styled from 'styled-components';

export const Container = styled.div`
  width: 98%;
  margin: 0 auto 72px;
  padding: 40px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  form {
    margin-top: 8px;
  }

  div.column {
    display: flex;
    justify-content: space-between;

    label {
      width: 45%;
    }
  }
`;

export const InputContainer = styled.label`
  display: flex;
  flex-direction: column;
  font-size: var(--font-default);
  font-weight: 500;
  margin-top: 32px;

  input,
  textarea {
    width: 100%;
    height: 48px;
    padding: 16px;
    border-radius: 6px;
    border: none;
    background-color: #e6e6e6;
    display: flex;
    align-items: center;
    margin-top: 4px;
    font-size: var(--font-default);
    outline: none;
  }

  textarea {
    resize: none;
    height: 164px;
  }
`;

export const ButtonsContainer = styled.div`
  margin-top: 32px;
  text-align: right;

  button {
    cursor: pointer;
    background: var(--secondary-color);
    border: none;
    padding: 16px 42px;
    border-radius: 6px;
    font-weight: bold;
    font-size: 16px;
    transition: all 0.2s;

    & + button {
      margin-left: 34px;
    }

    &.cancel {
      border: 1px solid #7d8073;
      background: transparent;
      color: #7d8073;
    }

    &:hover {
      filter: opacity(0.9);
    }

    &[disabled] {
      background: #c8cac3;
      cursor: default;
    }
  }

  @media (max-width: 780px) {
    display: flex;
    flex-direction: column;

    button + button {
      margin-top: 32px;
      margin-left: 0%;
    }
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 8px;
  margin-bottom: 20px;
  color: #e52629;
  font-size: 14px;
`;
