import styled from 'styled-components';

export const HeaderContainer = styled.header`
  background-color: var(--backgroundWhite);
  width: 100vw;
  height: 96px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  @media (max-width: 768px) {
    height: 72px;
    position: fixed;
    bottom: 0;
    z-index: 10;
    padding: 0 2px;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  img {
    width: 116px;
  }

  img.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }

  @media (max-width: 992px) {
    a {
      img {
        display: none;
      }
    }
  }
`;

export const HeaderUtilities = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 100%;
  width: 40%;

  @media (max-width: 768px) {
    width: unset;

    form.search-input {
      display: none;
    }
  }

  @media (min-width: 769px) and (max-width: 992px) {
    width: 50%;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  margin-left: 48px;
  transition: all 0.3s ease-in-out;
  border-width: 0%;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background: none;
    border: none;
  }

  &:after {
    content: '';
    width: 0%;
    opacity: 0;
    height: 7px;
    bottom: 0;
    left: 0;
    position: absolute;

    background: #ffab00;
  }

  &.active {
    span {
      font-weight: 500;
    }

    &:after {
      width: 100%;
      opacity: 1;
    }
  }

  span {
    font-size: var(--font-large);
    margin-right: 10px;
  }

  svg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  nav {
    visibility: hidden;
    transition: visibility 0.1s;
  }

  &:hover {
    nav {
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    margin-left: unset;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column-reverse;
    }

    img {
      width: 24px;
      height: 24px;
    }

    span {
      font-size: var(--font-smallest);
      color: var(--regular-gray);
      font-weight: 400;
      margin: 12px auto 0;
    }

    &.active {
      span {
        color: #000;
      }

      &:after {
        height: 4px;
        top: 0;
      }
    }
  }
`;
