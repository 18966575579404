import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  margin-top: 56px;
  padding: 0 32px;

  h2 {
    font-size: var(--font-largest);
  }

  @media (max-width: 768px) {
    padding: 0 16px;
    margin-top: 32px;

    h2 {
      margin-top: 0px;
      font-size: 16px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  margin: 72px 0px 92px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 36px;
  }

  h3 {
    font-size: 21px;
  }
`;

export const TrailsContent = styled.div`
  width: 80%;
  height: 100%;
  margin-left: 80px;
  display: flex;
  flex-wrap: wrap;

  > div {
    margin: 0 32px 56px 0;
  }

  @media (max-width: 768px) {
    column-gap: 32px;
    justify-content: center;
    margin-top: 46px;
    margin-left: 0;
    width: 100%;

    > div {
      margin: 0 0 32px 0;
      width: 100%;
      max-width: 320px;

      img {
        height: 120px;
        object-fit: cover;
      }

      h4 {
        font-size: var(--font-large);
      }

      div:last-child {
        align-items: center;

        span {
          margin-top: 2px;
          font-size: var(--font-regular);
        }

        svg {
          margin-right: 6px;
          width: 20px;
          height: 20px;
        }

        button {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;
