import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .course-progress-bar {
    margin-top: 8px;
    flex-grow: 1;
    height: 5px;
    background-color: var(--default-gray);

    .progress {
      background-color: #ffb905;
      height: 100%;
    }
  }
`;

export { ProgressBarContainer };
