import 'scorm-again';
import { useEffect } from 'react';
import Content from '../../../../models/content';
declare let window: any;

interface PlayScormProps {
  selectedContent: Content;
  finishCourseOrTrail: () => Promise<void>;
  startCourseOrTrail: () => Promise<void>;
  goToNextContent?: () => void;
  startModule?: () => Promise<void>;
}

const PlayScorm: React.FC<PlayScormProps> = ({
  selectedContent,
  finishCourseOrTrail,
  startCourseOrTrail,
  goToNextContent,
  startModule,
}) => {
  useEffect(() => {
    window.API = new window.Scorm12API({});

    window.API.on('LMSInitialize', async function () {
      if (startModule !== undefined) {
        await startModule();
      }
      await startCourseOrTrail();
    });

    window.API.on(
      'LMSSetValue.cmi.core.lesson_status',
      async (CMIElement: any, value: string) => {
        if (value === 'completed') {
          try {
            await finishCourseOrTrail();
          } catch (error) {
            console.error(error);
          }
          if (goToNextContent) {
            goToNextContent();
          }
        }
      },
    );
  }, [finishCourseOrTrail, goToNextContent, startCourseOrTrail, startModule]);

  useEffect(() => {
    return () => {
      window.API.clear('LMSInitialize');
      window.API.clear('LMSSetValue.cmi.core.lesson_status');
    };
  }, []);

  return (
    <iframe
      title="referenced-scorm"
      height="100%"
      width="100%"
      src={selectedContent.contentUrl}
      allowFullScreen
    ></iframe>
  );
};

export default PlayScorm;
