import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FiUser,
  FiPlayCircle,
  FiBookmark,
  FiAward,
  FiCheckCircle,
} from 'react-icons/fi';

import { Container } from './style';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const ProfileMenu: React.FC = () => {
  const location = useLocation();

  const routes = [
    {
      path: '/profile',
      description: 'Meus dados',
      Icon: () => <FiUser size={24} />,
    },
    {
      path: '/ongoing-courses',
      description: 'Em andamento',
      Icon: () => <FiPlayCircle size={24} />,
    },
    {
      path: '/favorite-courses',
      description: 'Favoritos',
      Icon: () => <FiBookmark size={24} />,
    },
    {
      path: '/finished-contents-and-trails',
      description: 'Finalizados',
      Icon: () => <FiCheckCircle size={24} />,
    },
    {
      path: '/certificates',
      description: 'Certificados',
      Icon: () => <FiAward size={24} />,
    },
    {
      path: '/uploaded-files',
      description: 'Arquivos enviados',
      Icon: () => <AiOutlineCloudUpload size={24} />,
    },
  ];

  return (
    <Container>
      {routes.map(route => (
        <Link
          key={route.path}
          to={route.path}
          className={`${location.pathname === route.path && 'active'}`}
        >
          <route.Icon />
          {route.description}
        </Link>
      ))}
    </Container>
  );
};

export default ProfileMenu;
