import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import BannerCard from '../../../../components/BannerCard';

import { baneerSliderSettings } from '../../../../config/sliderSettings';
import Content from '../../../../models/content';
import { getContents } from '../../../../services/content';

const BannerCardContainer = styled.div`
  width: 100vw;
  max-width: 1300px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Banner: React.FC = () => {
  const [bannerCourses, setBannerCourses] = useState<Content[]>([]);

  useEffect(() => {
    (async () => {
      const courses = await getContents({
        is_library: true,
        is_highlighted: true,
      });
      setBannerCourses(courses);
    })();
  }, []);

  if (bannerCourses && bannerCourses.length) {
    if (bannerCourses.length > 1) {
      return (
        <Slider {...baneerSliderSettings}>
          {bannerCourses &&
            bannerCourses.length &&
            bannerCourses.map(course => (
              <BannerCard key={course.id} course={course} />
            ))}
        </Slider>
      );
    } else {
      const firstCourse = bannerCourses.find(ban => ban.id) || ({} as Content);
      return (
        <BannerCardContainer>
          <BannerCard
            key={firstCourse.id}
            course={firstCourse}
            style={{ transform: 'inherit' }}
          />
        </BannerCardContainer>
      );
    }
  } else {
    return <></>;
  }
};

export default Banner;
