import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';

import { Container } from './style';

import HeaderLogo from '../../../../assets/logo.svg';
import DefaultInput from '../../../../components/Inputs/DefaultInput/DefaultInput';
import DefaultButton from '../../../../components/Buttons/DefaultButton';
import AuthBackButton from '../../../../components/Buttons/AuthBackButton';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../../helpers/getValidationErrors';
import { forgotPassword as forgotPasswordService } from '../../../../services/forgot-password';
import InputWithMask from '../../../../components/Inputs/InputWithMask';

const schema = Yup.object().shape({
  registration: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.'),
});

interface IUserData {
  registration: string;
  cell: string;
}

const ConfirmData: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const handleSubmit = async (data: IUserData) => {
    const { registration, cell } = data;

    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await forgotPasswordService(data).then(() => {
        history.push('/forgot-password/reset-password', { cell, registration });
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <Container>
      <AuthBackButton />
      <img src={HeaderLogo} alt="logo" />

      <h3>Redefinir a senha</h3>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <DefaultInput
          name="registration"
          type="text"
          label="Matrícula"
          description="No campo abaixo, insira sua matrícula com TN ou TU antes dos
            números para ter acesso à plataforma. Ex: TN2222 / TU2222"
          required
        />

        <InputWithMask
          name="cell"
          type="text"
          label="Celular cadastrado"
          mask={'(99) 99999-9999'}
        />

        <DefaultButton type="submit">
          Enviar código para o celular
        </DefaultButton>
      </Form>
    </Container>
  );
};

export default ConfirmData;
