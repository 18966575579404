import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoArrowBackOutline } from 'react-icons/io5';

import { Container } from './style';

const BackButton: React.FC = () => {
  const history = useHistory();

  return (
    <Container onClick={() => history.goBack()}>
      <IoArrowBackOutline size={18} color="#54006E" />
      <span>Voltar</span>
    </Container>
  );
};

export default BackButton;
