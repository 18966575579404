import React, { useState, useCallback, useEffect } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import CourseCardItem from '../../../../components/CourseCardItem';
import ProfileMenu from '../../components/ProfileMenu';

import { Container, FavoritesList as FinishedList, SelectContainer } from './style';
import MobileMenuDropdown from '../../components/MobileMenuDropdown';
import TrailCard from '../../../../components/TrailCard';
import Trail from '../../../../models/trail';
import { getTrails } from '../../../../services/trail';
import { getContents } from '../../../../services/content';
import Content from '../../../../models/content';
import GridShimmer from '../../../../components/GridShimmer';

const FinishedCourses: React.FC = () => {
  const [finishedTrails, setFinishedTrails] = useState([] as Trail[]);
  const [finishedContents, setFinishedContents] = useState([] as Content[]);
  const [contentSelected, setContentSelected] = useState('contents');
  const [isLoading, setIsLoading] = useState(true);


  const refreshFinishedTrails = useCallback(async () => {
    setIsLoading(true);
    const finishedFilters = {
      is_finished: true,
    };

    const localFinishedTrails = await getTrails(finishedFilters);

    if (localFinishedTrails && localFinishedTrails.length) {
      setFinishedTrails(localFinishedTrails);
    }
    setIsLoading(false);
  }, []);

  const refreshFinishedContents = useCallback(async () => {
    setIsLoading(true);
    const finishedFilters = {
      is_finished: true,
    };

    const localFinishedContents = await getContents(finishedFilters);

    if (localFinishedContents && localFinishedContents.length) {
      setFinishedContents(localFinishedContents);
    }
    setIsLoading(false);
  }, []);


  useEffect(() => {
    (async () => {
      await refreshFinishedTrails();
      await refreshFinishedContents();
    })();
  }, [refreshFinishedTrails, refreshFinishedContents]);


  return (
    <Container className="content">
      <ProfileMenu />

      <div>
        <MobileMenuDropdown className="dropdown" />

        <header>
          <h2>
            {contentSelected === 'contents'
              ? 'Cursos Finalizados'
              : 'Trilhas Finalizadas'}
          </h2>

          <SelectContainer>
            <select onChange={e => setContentSelected(e.target.value)}>
              <option value="contents">Cursos Finalizados</option>
              <option value="trails">Trilhas Finalizadas</option>
            </select>
            <IoIosArrowDown size={20} />
          </SelectContainer>
        </header>
        {isLoading ? (
          <div className="shimmer">
            <GridShimmer />
          </div>
        ) : (
          <>
            {contentSelected === 'contents' && (
              <div>
                <FinishedList>
                  {finishedContents && finishedContents.length ? (
                    finishedContents.map(course => (
                      <>
                        <CourseCardItem
                          key={course.id}
                          course={course}
                          onAddToList={refreshFinishedContents}
                        />
                        <div />
                      </>
                    ))
                  ) : (
                    <h4 style={{ padding: '15px' }}>
                      Não foram encontrados cursos finalizados.
                    </h4>
                  )}
                </FinishedList>
              </div>
            )}

            {contentSelected === 'trails' && (
              <div>
                <FinishedList>
                  {finishedTrails && finishedTrails.length ? (
                    finishedTrails.map(trail => (
                      <>
                        <TrailCard
                          key={trail.id}
                          trail={trail}
                          onAddToList={refreshFinishedTrails}
                        />
                        <div />
                      </>
                    ))
                  ) : (
                    <h4 style={{ padding: '15px' }}>
                      Não foram encontradas trilhas finalizados.
                    </h4>
                  )}
                </FinishedList>
              </div>
            )}

          </>
        )}
      </div>
    </Container>
  );
};

export default FinishedCourses;
