import styled, { css } from 'styled-components';

interface IPostAnswer {
  isComment: boolean;
}

export const PostAnswerItemWrapper = styled.div<IPostAnswer>`
  ${({ isComment }) => css`
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding-left: ${isComment ? 32 : 0}px;
    margin-bottom: ${isComment ? 64 : 32}px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .postData {
      display: grid;
      grid-template-columns: 80px 1fr;
      grid-column-gap: 40px;

      .userData {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__icon {
          fill: #bdbdbd;
          margin-bottom: 8px;
        }

        &__name {
          font-weight: 700;
          color: var(--primary-color);
          font-size: var(--font-medium);
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        &__date,
        &__time {
          text-align: center;
          font-size: var(--font-regular);
        }
      }

      p {
        padding-top: 8px;
        line-height: 22px;
        font-weight: 400;
        font-size: var(--font-regular);
      }
    }

    .postActions {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      @media (max-width: 768px) {
        justify-content: flex-end;
      }

      .action {
        margin-right: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        button {
          background: none;
        }

        &:hover {
          svg {
            fill: var(--primary-color);
          }
        }

        svg {
          transition: 0.5s ease;
          fill: var(--primary-color);
        }

        span {
          margin-bottom: 4px;
          font-size: var(--font-smallest);
          color: #505f79;
        }
      }
    }
  `}
`;

export const AnswerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0px 16px;
  border: 1px solid #f0f0f0;

  & + & {
    border-top: none;
  }
`;

export const TextareaWrapper = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-direction: column;

  margin-bottom: 32px;

  textarea {
    resize: none;
    height: 177px;
    border-radius: 6px;
    background: #f2f2f2;
    font-size: var(--font-default);
  }

  button {
    background: var(--secondary-color);
    padding: 0px 48px;
    height: 48px;
    color: #000;
    margin-left: auto;
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;

    &:hover {
      filter: opacity(0.9);
    }
  }
`;
