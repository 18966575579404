import axios from 'axios';
import { toast } from 'react-toastify';

import { resendCode as resendCodeService } from '../../services/verifyCode';

interface IFormSignUp {
  registration: string;
  cell: string;
  password: string;
}

const signUp = async ({
  registration,
  password,
  cell,
}: IFormSignUp): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/signup`, {
        registration,
        password,
        phone_number: cell,
      })
      .then(response => {});

    return Promise.resolve();
  } catch (err) {
    if (
      err.response.data.message ===
      'Usuário já cadastrado no cognito. Verifique o código recebido em seu telefone ou solicite o reenvio.'
    ) {
      toast.info(err.response.data.message);

      await resendCodeService({ phone_number: cell });
      return Promise.resolve();
    } else {
      toast.error(err.response.data.message);
      return Promise.reject();
    }
  }
};

export { signUp };
