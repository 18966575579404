import styled from 'styled-components';

const FileUploadContainer = styled.div`
  margin: 24px 0px 20px 20px;
`;

const FileUploadUpperContent = styled.div`
  width: 100%;

  padding: 12px 14px;
  border: 1px solid var(--primary-color);
  background-color: var(--primary-color);

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div.duration-content {
    display: flex;
    align-items: center;
  }

  strong {
    color: #fff;
  }

  svg {
    color: var(--secondary-color);
  }
`;

const FileUploadCollapseIndicatorContainer = styled.div`
  width: 26px;
  height: 26px;
  transition: all 0.3s ease;
  transform: rotate(180deg);

  &.opened {
    transform: rotate(0deg);
  }
`;

const FileUploadLowerContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  min-height: 60px;
  border: 1px solid var(--default-gray);

  span {
    font-size: 14px;
  }

  span:nth-child(2) {
    color: #999999;
    padding: 10px 0;
  }

  button {
    margin: 20px 0;
  }
`;

export {
  FileUploadContainer,
  FileUploadUpperContent,
  FileUploadCollapseIndicatorContainer,
  FileUploadLowerContent,
};
