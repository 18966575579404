import React, { useRef } from 'react';
import Textarea from '../../../../components/Inputs/Textarea';
import * as Yup from 'yup';

import {
  Container,
  Content,
  Title,
  ContentForm,
  InputGroup,
  Input,
  Button,
} from './style';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../../../helpers/getValidationErrors';
import { sendEmail } from '../../../../services/faq';
import { toast } from 'react-toastify';

const schema = Yup.object().shape({
  name: Yup.string().trim().required('Preencha corretamente este campo.'),
  email: Yup.string()
    .email()
    .trim()
    .required('Preencha corretamente este campo.'),
  text: Yup.string().trim().required('Preencha corretamente este campo.'),
});

interface IFormData {
  name: string;
  email: string;
  text: string;
}

const Form = () => {
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = async (data: IFormData, { reset }: any) => {
    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await sendEmail(data).then(() => {
        toast.success('FAQ enviado com sucesso.');
        reset();
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      } else {
        toast.error('Não foi possível enviar o FAQ.');
      }
    }
  };

  return (
    <Container>
      <Content className="content">
        <div>
          <Title>Ou entre em contato através do formulário.</Title>
          <ContentForm onSubmit={handleSubmit} ref={formRef}>
            <InputGroup>
              <Input type="text" name="name" label="Nome" />
            </InputGroup>

            <InputGroup>
              <Input type="email" name="email" label="Email" />
            </InputGroup>

            <InputGroup>
              <Textarea type="text" name="text" label="Escreva a sua dúvida" />
            </InputGroup>

            <Button>Enviar</Button>
          </ContentForm>
        </div>
      </Content>
    </Container>
  );
};

export default Form;
