import styled from 'styled-components';

export const Container = styled.div`
  width: 360px;
  padding: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;

  > img {
    margin-bottom: 16px;
  }

  p {
    width: 300px;
    line-height: 20px;
    color: var(--regular-gray);
    overflow: auto;
    text-overflow: ellipsis;
    height: 160px;
    word-wrap: break-word;

    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-moz-scrollbar {
      width: 8px;
    }
    :-ms-input-scrollbar {
      width: 8px;
    }

    ::-webkit-scrollbar-thumb {
      background: #cecece;
      border-radius: 10px;
    }
  }

  @media (max-width: 768px) {
    width: 258px;
    padding: 18px 20px;

    > img {
      width: 20px;
    }

    p {
      font-size: var(--font-small);
      line-height: 16px;
      width: 220px;
    }
  }
`;

export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 29px;
  min-height: 70px;

  svg {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin: 0px 8px 4px 0px;
    object-fit: cover;
    flex-shrink: 0;
  }

  @media (max-width: 768px) {
    margin-top: 14px;

    img {
      width: 32px;
      height: 32px;
    }
  }
`;

export const DepositionAuthor = styled.span`
  font-weight: bold;
  font-size: var(--font-regular);
  display: block;
  margin-bottom: 0;

  @media (max-width: 768px) {
    font-size: var(--font-min);
  }
`;

export const DepositionOffice = styled.span`
  font-size: var(--font-small);
  color: var(--dark-gray);

  @media (max-width: 768px) {
    font-size: var(--font-smallest);
  }
`;

export const DepositionCourses = styled.div`
  font-size: var(--font-small);
  color: var(--dark-gray);
  word-wrap: break-word;
  height: 50px;
  overflow: hidden;
`;
