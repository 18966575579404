import React, { useState } from 'react';

import { BsBookmark } from 'react-icons/bs';
import { AiOutlineClockCircle, AiOutlineLike } from 'react-icons/ai';

import {
  Container,
  BookmarkButton,
  CompletionPercentage,
  Coursefooter,
  CourseLikes,
  CourseTime,
} from './style';
import ProgressBar from '../ProgressBar';
import { useHistory } from 'react-router-dom';
import { addBookMark, removeBookMark } from '../../services/bookmark';
import { toast } from 'react-toastify';
import {
  addLike,
  dislikeCourse,
  likeCourse,
  removeLike,
} from '../../services/like';
import { secondsToTimeText } from '../../helpers/time';

interface IOngoingCourseProps {
  course: any;
}

const OngoingContentCard: React.FC<IOngoingCourseProps> = ({ course }) => {
  const history = useHistory();
  const [addedToList, setAddedToList] = useState(course.book_mark);
  const [addedToLike, setAddedToLike] = useState(course.addedToLike);

  const goToCourse = () => {
    history.push(`/courses/${course.content_id}`);
  };

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (course) {
      try {
        const shouldAddToList = !course.book_mark;

        if (shouldAddToList) {
          await addBookMark(course.content_id);
        } else {
          await removeBookMark(course.content_id);
        }

        course.book_mark = shouldAddToList;
        setAddedToList(course.book_mark);
      } catch (e) {
        toast.error('Erro ao adicionar curso aos favoritos.');
      }
    }
  };

  const addToLike = async (event: React.MouseEvent) => {
    event.stopPropagation();

    if (course) {
      try {
        const shouldAddToLikes = !course.addedToLike;

        if (shouldAddToLikes) {
          await likeCourse(course.content_id);
        } else {
          await dislikeCourse(course.content_id);
        }

        course.addedToLike = shouldAddToLikes;
        setAddedToLike(course.addedToLike);
      } catch (e) {
        toast.error('Erro ao curtir o curso.');
      }
    }
  };

  return (
    <Container onClick={goToCourse}>
      <div>
        {course.thumbnail ? (
          <img src={course.thumbnail} alt="Course" />
        ) : (
          <div className="image" />
        )}

        <ProgressBar
          completionPercentage={Math.floor(
            (course.content_view * 100) / course.duration,
          )}
          width={294.4}
        />

        <h4>{course.title}</h4>

        <BookmarkButton onClick={addToList}>
          <BsBookmark
            size={24}
            color="#FFF"
            className={`${addedToList && 'favorite'}`}
          />
        </BookmarkButton>
        <CompletionPercentage>{`${Math.floor(
          (course.content_view * 100) / course.duration,
        )}%`}</CompletionPercentage>
      </div>

      <Coursefooter>
        <CourseTime>
          <AiOutlineClockCircle size={20} />
          <span>{secondsToTimeText(course.duration)}</span>
        </CourseTime>
        <span className="divider" />
        <CourseLikes>
          <button onClick={e => addToLike(e)}>
            <AiOutlineLike
              color={`${addedToLike ? '#54006e' : '#555'}`}
              size={20}
            />
          </button>
          <span>{course.likes && `${course.likes.count} curtidas`}</span>
        </CourseLikes>
      </Coursefooter>
    </Container>
  );
};

export default OngoingContentCard;
