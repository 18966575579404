import React, { ReactElement, useEffect } from 'react';
import {
  ModalContainer,
  ModalBox,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from './style';

interface IModal {
  children: ReactElement[];
  open: boolean;
  setVisibility: (visible: boolean) => void;
  bgColor?: string;
  background?: string;
}

const ModalExam: React.FC<IModal> = ({
  children,
  open,
  setVisibility,
  bgColor = '#fff',
  background,
}) => {
  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'initial';
  }, [open]);

  const [header, body, footer] = children;

  function handleOutterClick() {
    setVisibility(false);
  }

  return (
    <ModalContainer
      className="fixed-modal"
      onClick={() => handleOutterClick()}
      open={open}
    >
      <ModalBox bgColor={bgColor} onClick={e => e.stopPropagation()}>
        <ModalHeader>
          {background && <img src={background} alt="Course" />}

          {header}
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>{footer}</ModalFooter>
      </ModalBox>
    </ModalContainer>
  );
};

export default ModalExam;
