import styled from 'styled-components';

export const ForumPageWrapper = styled.div`
  width: 100vw;
  margin-bottom: auto;
`;

export const ForumPageHeader = styled.div`
  padding: 38px 0;

  div.buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      cursor: pointer;
    }
  }

  div + div {
    margin-top: 12px;
    padding-left: 112px;
  }

  h1 {
    font-size: var(--font-title);
    font-weight: 700;
    margin-bottom: 24px;
  }

  h2 {
    font-size: 22px;
    font-weight: 400;
    color: var(--regular-gray);
  }

  @media (max-width: 768px) {
    div.buttons-container {
      button {
        font-size: 12px;
      }
    }

    div + div {
      padding-left: 20px;
    }

    h1 {
      margin-top: 16px;
      font-size: 18px;
    }

    h2 {
      font-size: 18px;
    }
  }
`;

export const NewPostButton = styled.button`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 500;
  background-color: var(--secondary-color);
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  height: 52px;
  transition: 0.5s ease;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const ForumPostList = styled.table`
  width: 100%;
  margin-top: 56px;

  tr {
    &.header {
      padding: 0 24px;
      height: 64px;
      border: none;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 104px 104px 104px 104px;
      grid-column-gap: 112px;

      th {
        text-align: center;
      }
    }
  }

  @media (max-width: 768px) {
    margin-top: 0px;

    tr.header {
      display: none;
    }
  }
`;

export const EmptyListWrapper = styled.tr`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  margin-top: 28px;

  h4 {
    font-weight: bold;
    margin: 32px 0;
  }
`;

export const ForumPostFooterWrapper = styled.div`
  height: 124px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 15px;
    color: #a9a9a9;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    margin-left: 8px;
    font-size: 15px;

    &.selected {
      font-weight: bold;
      color: var(--primary-color);
    }
  }
`;
