import React, { useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import Home from '../pages/Home';
import Courses from '../pages/Courses';
import Course from '../pages/Course';
import Annotations from '../pages/Annotations';
import FAQ from '../pages/FAQ';
import Initial from '../pages/Initial';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import Trails from '../pages/Trails';
import AuthLayout from '../components/Layouts/AuthLayout';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import UserData from '../pages/Profile/Routes/UserData';
import OngoingCourses from '../pages/Profile/Routes/OngoingCourses';
import FavoriteCourses from '../pages/Profile/Routes/FavoriteCourses';
import Certificates from '../pages/Profile/Routes/Certificates';
import FinishedCourses from '../pages/Profile/Routes/FinishedCourses';
import Search from '../pages/Search';
import Forum from '../pages/Forum';
import ForumTopic from '../pages/ForumTopic';
import VerifyCode from '../pages/VerifyCode';
// import Lives from '../pages/Lives';
import Exam from '../pages/Exam';
import TrailComponent from '../pages/Trail';
import CourseExam from '../pages/CourseExam';
import UploadedFiles from '../pages/Profile/Routes/UploadedFiles';
import Quiz from '../pages/Quiz';

const Routes: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const fixedModals = Array.from(
      document.getElementsByClassName('fixed-modal'),
    );
    if (!fixedModals || !fixedModals.length) {
      document.body.style.overflow = 'initial';
    }
  }, [location]);
  return (
    <Switch>
      <Route path="/" exact component={Initial} />

      <Route
        path={[
          '/signIn',
          '/signUp',
          '/forgot-password',
          '/verify-code',
          '/faq',
        ]}
      >
        <AuthLayout>
          <Route path="/signIn" component={SignIn} />
          <Route path="/signUp" component={SignUp} />
          <Route path="/verify-code" component={VerifyCode} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/faq" exact component={FAQ} />
        </AuthLayout>
      </Route>

      <DefaultLayout>
        <Route path="/home" component={Home} />
        <Route path="/profile" component={UserData} />
        <Route path="/ongoing-courses" component={OngoingCourses} />
        <Route path="/favorite-courses" component={FavoriteCourses} />
        <Route path="/certificates" component={Certificates} />
        <Route path="/uploaded-files" component={UploadedFiles} />
        <Route
          path="/finished-contents-and-trails"
          component={FinishedCourses}
        />
        <Route path="/annotations" exact component={Annotations} />

        <Route path="/search" component={Search} />
        {/* <Route path="/lives" exact component={Lives} /> */}
        {/* <Route path="/lives/:id" exact component={Lives} /> */}
        <Route path="/forum" exact component={Forum} />
        <Route path="/forum/post/:questionId" exact component={ForumTopic} />
        <Route>
          <Route path="/courses" exact component={Courses} />
          <Route path="/courses/:courseId" component={Course} />
        </Route>
        <Route path="/exam/:courseId/:examId/:type/" component={Exam} />
        <Route
          path="/course-exam/:courseId/:examId/:type/"
          component={CourseExam}
        />
        <Route>
          <Route path="/quiz/:courseId/:examId/:type/:id" component={Quiz} />
          <Route path="/trails" exact component={Trails} />
          <Route>
            <Route path="/trails/:trailId" exact component={TrailComponent} />
            <Route
              path="/trails/:trailId/modules/:moduleId/contents/:contentId"
              component={TrailComponent}
            />
          </Route>
        </Route>
      </DefaultLayout>
    </Switch>
  );
};

export default Routes;
