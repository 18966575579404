import axios from 'axios';
import { toast } from 'react-toastify';

interface IFormSignIn {
  registration: string;
  password: string;
}

const signIn = async ({
  registration,
  password,
}: IFormSignIn): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/login`, {
        username: registration,
        password,
      })
      .then(response => {
        window.localStorage.setItem(
          'telhanorte-access-token',
          response.data.access_token,
        );
        window.localStorage.setItem(
          'telhanorte-api-token',
          response.data.api_token,
        );
        window.localStorage.setItem(
          'telhanorte-user',
          JSON.stringify(response.data.user),
        );
      });
    return Promise.resolve();
  } catch (err) {
    if (err.response.data.message === 'Incorrect username or password.') {
      toast.error(
        'A matrícula e senha informadas estão incorretas ou não existem.',
      );
    } else {
      toast.error(err.response.data.message);
    }

    return Promise.reject();
  }
};

export { signIn };
