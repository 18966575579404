import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/Modal';
import { IAttempts, IExam, IUserExam } from '../../../../services/exam';
import Trail from '../../../../models/trail';

import { useHistory, useParams } from 'react-router-dom';

import {
  BodyTitle,
  BodyText,
  PrimaryButton,
  SecondaryButton,
  BtnGroup,
} from '../../style';
import Content from '../../../../models/content';

type ExamMoment = 'start' | 'end';

interface IModalExam {
  Exam: IExam;
  Attempts: IAttempts;
  open: boolean;
  setVisibility: (visible: boolean) => void;
  goToCerts: () => void;
  seeAnswers: () => void;
  restart: () => void;
  goBack: () => void;
  course?: Content;
  result: number;
  seeExam: () => void;
}

interface IModalType {
  [index: string]: {
    title: string;
    text: string;
    primaryBtn: {
      text: string;
      action: () => void;
    };
    secondaryBtn?: {
      text: string;
      action: () => void;
    };
  };
}

const ResultModal: React.FC<IModalExam> = ({
  Exam,
  Attempts,
  open,
  setVisibility,
  goToCerts,
  seeAnswers,
  restart,
  goBack,
  course,
  result,
  seeExam,
}) => {
  const history = useHistory();

  function isEmpty(obj: any): Boolean {
    if (!obj) return true;
    return !Object.entries(obj).length;
  }

  function isBeforeTrailExam(): Boolean {
    if (isEmpty(course)) return false;
    return !course?.alreadyStarted;
  }

  const { amount_questions, average } = Exam;
  const { amount } = amount_questions[0];
  const minValue =
    amount === 1 ? 1 : Math.ceil((amount * Number(average)) / 100);

  const status: string = isBeforeTrailExam()
    ? 'none'
    : result >= minValue && course?.info && course.info.no_certificate === true
    ? 'none'
    : result >= minValue
    ? 'approved'
    : 'disapproved';

  const noAttempts = Attempts.exam_user.length === Attempts.user_total_attempts;
  const ModalText: IModalType = {
    approved: {
      title: 'Parabéns!',
      text: `Você acertou ${result} de ${amount} questões e está apto a receber o certificado. Ele estará disponível em alguns instantes na aba “Meus Certificados”`,
      primaryBtn: {
        text: 'Ir para Meus Certificados',
        action: () => {
          goToCerts();
        },
      },
      secondaryBtn: {
        text: 'Ver prova',
        action: () => seeAnswers(),
      },
    },
    disapproved: {
      title: 'Que pena!',
      text: noAttempts
        ? 'Você não atingiu o mínimo de acerto nas questões do curso.'
        : `Você não atingiu o mínimo de acerto nas questões do curso. Não poderemos te dar o certificado agora, mas não desista, você tem mais ${
            Attempts.user_total_attempts - Attempts.exam_user.length
          } tentativa(s).`,
      primaryBtn: {
        text: noAttempts ? 'Voltar para o curso' : 'Iniciar avaliação',
        action: noAttempts
          ? () => history.push(`/courses/${course?.id}`)
          : () => window.location.reload(),
      },
      secondaryBtn: {
        text: 'Ver prova',
        action: () => seeExam(),
      },
    },
    none: {
      title: 'Questionário',
      text: `Você acertou ${result} de ${amount} questões`,
      primaryBtn: {
        text: 'Voltar para o curso',
        action: () => history.push(`/courses/${course?.id}`),
      },
      secondaryBtn: {
        text: 'Ver prova',
        action: () => seeAnswers(),
      },
    },
  };

  return (
    <Modal
      open={open}
      bgColor={
        status === 'approved'
          ? '#8AFAB7'
          : status === 'disapproved'
          ? '#FA8A8A'
          : '#FFFFFF'
      }
      setVisibility={open => setVisibility(open)}
      background={course?.thumbnail}
    >
      <h3>{Exam.title}</h3>
      <div>
        <BodyTitle>{ModalText[status]?.title}</BodyTitle>
        <BodyText>{ModalText[status]?.text}</BodyText>
      </div>
      <BtnGroup>
        <PrimaryButton
          onClick={() => {
            ModalText[status]?.primaryBtn.action();
            setVisibility(false);
          }}
        >
          {ModalText[status]?.primaryBtn.text}
        </PrimaryButton>
        {ModalText[status]?.secondaryBtn && (
          <SecondaryButton
            onClick={() => {
              ModalText[status]?.secondaryBtn?.action();
              setVisibility(false);
            }}
          >
            {ModalText[status]?.secondaryBtn?.text}
          </SecondaryButton>
        )}
      </BtnGroup>
    </Modal>
  );
};

export default ResultModal;
