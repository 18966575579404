import React from 'react';
import ExtraMaterial from '../../../../../../models/extra-material';
import { AiOutlineFile } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { MaterialsList } from './style';

interface ExtraMaterialsProps {
  extra_material: ExtraMaterial[];
}

const ExtraMaterials: React.FC<ExtraMaterialsProps> = ({ extra_material }) => {
  return (
    <>
      <MaterialsList>
        {extra_material.map(material => (
          <li key={material.id}>
            <div>
              <AiOutlineFile size={20} color="#000" />
              <span>{material.name.split('-')[1]}</span>
            </div>
            <a
              download
              target="_blank"
              rel="noopener noreferrer"
              href={material.reference}
            >
              <FiDownload size={20} color="#000" />
            </a>
          </li>
        ))}

        {extra_material.length === 0 && (
          <span>Nenhum material de apoio disponível</span>
        )}
      </MaterialsList>
    </>
  );
};

export default ExtraMaterials;
