import styled, { css } from 'styled-components';

interface IPost {
  new: boolean;
}

export const ForumPostListRowWrapper = styled.tr<IPost>`
  ${props => css`
    display: grid;
    grid-template-columns: 1fr 104px 104px 104px 104px;
    grid-column-gap: 112px;
    min-height: 64px;
    align-items: center;
    border: 1px solid #f0f0f0;
    padding: 0 24px;
    transition: 0.5s ease;
    text-align: center;
    cursor: pointer;
    transition: all 0.2s;
    font-weight: ${props.new ? 700 : 400};

    .title {
      text-align: left;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 120%;

      span {
        margin-left: auto;
        padding-right: 20px;
      }

      .tag {
        margin-left: auto;
        padding: 4px 4px;
        background-color: var(--primary-color);
        color: white;
        font-weight: 700;
        text-align: center;
        font-size: var(--font-min);
      }
    }

    .lastPost {
      width: 130%;
    }

    .createdBy {
      display: flex;
      align-items: center;

      svg {
        margin-right: 14px;
      }
    }

    td {
      text-align: center;
      color: #212631;
      padding: 24px 0;
      font-size: var(--font-regular);
    }

    &:hover {
      font-weight: 700;
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;

      .title {
        width: 110%;
        justify-content: space-between;
      }
    }
  `}
`;
