import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Category from '../../models/category';
import { getContentsCategories } from '../../services/category';

import { SelectContainer } from './style';

interface IMenuCategories {
  onClick: (categoryId: string) => void;
  selectedCategory: string;
}

interface CategoryOptionsProps {
  label: string;
  value: string;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    backgroundColor: '#e6e6e6',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'rgba(84, 0, 110, 0.2)' : 'inherit',
    color: 'var(--dark-gray)',
    '&:hover': {
      backgroundColor: 'rgba(84, 0, 110, 0.2)',
    },
  }),
};

const MobileCategories: React.FC<IMenuCategories> = ({
  onClick,
  selectedCategory,
}) => {
  const [categoryOptions, setCategoryOptions] = useState<
    CategoryOptionsProps[]
  >([]);

  const [subCategoryOptions, setSubCategoryOptions] = useState<any>();

  const [selectCategory, setSelectCategory] = useState<any>();

  const [categories, setCategories] = useState([] as Category[]);

  useEffect(() => {
    (async () => {
      try {
        const categories = await getContentsCategories();

        if (categories && categories.length) {
          const result = formatCategories(categories);
          setCategoryOptions(result);
          setCategories(categories);
        }
      } catch (e) {
        toast.error('Erro ao carregar as categorias disponíveis.');
      }
    })();
  }, []);

  const formatCategories = (list: any) => {
    return list.map((item: any) => ({
      label: item.title,
      value: item.id,
    }));
  };

  const formatSubCategories = (list: any) => {
    return list.map((item: any) => ({
      label: item.title,
      value: item.category_id,
    }));
  };

  const onChangeSelect = useCallback(
    option => {
      onClick(option.value);

      if (categories && categories.length) {
        const categorySelected: Category[] = categories.filter(
          category => category.id === option.value,
        );

        setSelectCategory(categorySelected);

        if (
          categorySelected[0] &&
          categorySelected[0].child_categories &&
          categorySelected[0].child_categories.length > 0
        ) {
          const result = formatSubCategories(
            categorySelected[0].child_categories,
          );

          setSubCategoryOptions(result);
        } else {
          setSubCategoryOptions(null);
        }
      }
    },
    [selectCategory, categories],
  );

  return (
    <SelectContainer>
      <Select
        options={categoryOptions}
        isSearchable={false}
        placeholder="Filtrar por categoria"
        onChange={(option: any) => option && onChangeSelect(option)}
        styles={customStyles}
      />

      {subCategoryOptions && subCategoryOptions.length ? (
        <div style={{ marginTop: '24px' }}>
          <Select
            options={subCategoryOptions}
            isSearchable={false}
            placeholder="Filtrar por subcategoria"
            onChange={(option: any) => option && onClick(option.value)}
            styles={customStyles}
          />
        </div>
      ) : null}
    </SelectContainer>
  );
};

export default MobileCategories;
