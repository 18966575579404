import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { IoIosHelpCircleOutline } from 'react-icons/io';
import * as Yup from 'yup';
import { Container, TooltipInfo } from './style';
import { signUp as SignUpService } from '../../services/signUp';
import HeaderLogo from '../../assets/logo.svg';
import DefaultInput from '../../components/Inputs/DefaultInput/DefaultInput';
import DefaultButton from '../../components/Buttons/DefaultButton';
import InputPassword from '../../components/Inputs/InputPassword';
import AuthBackButton from '../../components/Buttons/AuthBackButton';
import { FormHandles } from '@unform/core';
import getValidationErrors from '../../helpers/getValidationErrors';
import InputWithMask from '../../components/Inputs/InputWithMask';

const schema = Yup.object().shape({
  registration: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.'),
  cell: Yup.string().trim().required('Preencha corretamente este campo.'),
  cellConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('cell'), null],
      'Os celulares informados não correspondem. Por favor, verifique.',
    )
    .trim()
    .required('Preencha corretamente este campo.'),
  password: Yup.string()
    .trim()
    .required('Preencha corretamente este campo.')
    .min(8, 'A senha deve conter no mínimo 8 caracteres.'),
  passwordConfirmation: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'As senhas informadas não correspondem. Por favor, verifique.',
    )
    .trim()
    .required('Preencha corretamente este campo.'),
});

interface ISignUpProps {
  registration: string;
  password: string;
  cell: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [cellNumber, setCellNumber] = useState('');
  const [registration, setRegistration] = useState('');

  const handleSubmit = async (data: ISignUpProps) => {
    const { registration, password, cell } = data;
    const formattedPhone = cell
      .replaceAll('-', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(' ', '');

    try {
      formRef.current?.setErrors({});

      await schema.validate(data, { abortEarly: false });

      await SignUpService({
        registration,
        password,
        cell: `+55${formattedPhone}`,
      }).then(() => {
        history.push('/verify-code', {
          registration,
          phoneNumber: `+55${formattedPhone}`,
        });
      });
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  };

  return (
    <Container>
      <AuthBackButton />
      <img src={HeaderLogo} alt="logo" />

      <h3>Cadastrar</h3>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <DefaultInput
          name="registration"
          type="text"
          label="Matrícula"
          description="No campo abaixo, insira sua matrícula com TN ou TU antes dos
              números para ter acesso à plataforma. Ex: TN2222 / TU2222"
          value={registration}
          onChange={e =>
            setRegistration((e.target.value || '').trim().toUpperCase())
          }
        />

        <div>
          <TooltipInfo>
            <IoIosHelpCircleOutline size={20} />
            <span>
              Utilizamos o telefone para te ajudar a recuperar o acesso a
              plataforma caso você perca sua senha.
            </span>
          </TooltipInfo>
          <InputWithMask
            name="cell"
            type="text"
            label="Celular"
            mask={'(99) 99999-9999'}
            onChange={e => setCellNumber(e.target.value)}
          />
        </div>

        <div>
          <InputWithMask
            name="cellConfirmation"
            type="text"
            label="Confirmar celular"
            mask={'(99) 99999-9999'}
          />
        </div>

        <InputPassword name="password" label="Senha" />
        <InputPassword name="passwordConfirmation" label="Confirmar senha" />

        <DefaultButton type="submit">Cadastrar</DefaultButton>
      </Form>
    </Container>
  );
};

export default SignUp;
