import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BiChevronUp } from 'react-icons/bi';
import { BsPlay, BsFillAwardFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import Content from '../../../../../../models/content';
import Course from '../../../../../../models/course';
import TrailModuleContent from './components/TrailModuleContent';
import { format, isBefore, parseISO } from 'date-fns';
import Trail from '../../../../../../models/trail';
import {
  QuizContainer,
  TrailCollapseIndicatorContainer,
  TrailCourseContainer,
  TrailCourseDuration,
  TrailCourseLowerContent,
  TrailCourseStartButton,
  TrailCourseStartButtonContainer,
  TrailCourseTitle,
  TrailCourseUpperContent,
  TrailDownloadCertificate,
  TrailStartExam,
} from './style';
import IntroductionContent from './components/IntroductionContent';
import { convertToDuration } from '../../../../../../helpers/seconds-to-time';
import { toast } from 'react-toastify';
import { AiOutlineDownload } from 'react-icons/ai';
import { downloadCertificate } from '../../../../../../services/certificate';
import { getModule as getModuleService } from '../../../../../../services/course';
import ModuleTrail from '../../../../../../models/module-trail';
import { getAttempts, getExam, IExam } from '../../../../../../services/exam';
import Modal from '../../../../../../components/Modal';
import {
  BtnGroup,
  ModalText,
  PrimaryButton,
  SecondaryButton,
} from '../../../../style';

interface TrailModuleProps {
  module: Course;
  selectedContent: Content;
  trailAlreadyStarted: boolean;
  trailAlreadyFinished: boolean;
  startTrail: () => void;
  ableToExam: boolean;
  trail: Trail;
  nextAttemptsDate: any;
  userIsApproved: boolean;
  visible: boolean;
}

const TrailModule: React.FC<TrailModuleProps> = ({
  module,
  selectedContent,
  trailAlreadyStarted,
  trailAlreadyFinished,
  startTrail,
  ableToExam,
  trail,
  nextAttemptsDate,
  userIsApproved,
  visible,
}) => {
  const [opened, setOpened] = useState(module.isFirstCourse);
  const history = useHistory();
  const location = useLocation();
  const [moduleInfo, setModuleInfo] = useState({} as ModuleTrail);
  const [showModuleExam, setShowModuleExam] = useState(false);
  const [exam, setExam] = useState<IExam>({} as IExam);
  const [showModal, setShowModal] = useState(true);

  const startExam = () => {
    const { id, exam } = trail;
    history.push(`/exam/${id}/${exam}/trail`);
  };

  const startQuiz = () => {
    const { course_id, exam_id } = moduleInfo;
    const { id } = trail;
    history.push(`/quiz/${course_id}/${exam_id}/trail/${id}`);
  };

  const checkIfIsSelected = (content: Content): boolean => {
    return !!(
      content.id === selectedContent.id &&
      content.courseId === selectedContent.courseId &&
      content.trailId &&
      selectedContent.trailId
    );
  };

  const checkIfIntroductionContent = (introductionContent: string): boolean => {
    const url = location.pathname.split('/');

    return url[2] === trail.id && url[3] === undefined;
  };

  useEffect(() => {
    if (
      module.contents &&
      module.contents.some(
        content =>
          content.id === selectedContent.id &&
          content.courseId === selectedContent.courseId &&
          content.trailId === selectedContent.trailId,
      )
    ) {
      setOpened(true);
    } else if (selectedContent.id) {
      setOpened(false);
    }
  }, [selectedContent]);

  useEffect(() => {
    if (ableToExam && module.isFirstCourse) {
      setOpened(true);
    }
  }, [trailAlreadyFinished, ableToExam]);

  useEffect(() => {
    const getModule = async () => {
      try {
        const moduleService = await getModuleService(module.id);
        setModuleInfo(moduleService);
        if (moduleService.exam_id) {
          const localExam = await getAttempts(
            moduleService.exam_id,
            moduleService.course_id,
          );
          setExam(localExam);
          if (
            localExam.exam_user[0] &&
            localExam.exam_user[0].exam_id === moduleService.exam_id
          ) {
            setShowModuleExam(false);
          } else {
            setShowModuleExam(true);
          }
        }
      } catch (err) {
        toast.error(err.message);
      }
    };
    getModule();
  }, [module.id]);

  return (
    <TrailCourseContainer>
      <TrailCourseUpperContent
        onClick={() => setOpened(!opened)}
        className={`${
          (module.alreadyStarted || module.isFirstCourse) && 'module-started'
        }`}
      >
        <TrailCourseTitle>{module.title}</TrailCourseTitle>
        <div className="duration-content">
          <TrailCourseDuration>{`${convertToDuration(
            module.duration,
          )}`}</TrailCourseDuration>
          <TrailCollapseIndicatorContainer
            className={`${opened ? 'opened' : ''}`}
          >
            <BiChevronUp size={26} />
          </TrailCollapseIndicatorContainer>
        </div>
      </TrailCourseUpperContent>
      {opened && (
        <TrailCourseLowerContent>
          {module.isFirstCourse && (
            <IntroductionContent
              content={trail.introductionUrl}
              isSelected={checkIfIntroductionContent(trail.introductionUrl)}
              trail={trail}
            />
          )}
          <TrailCourseStartButtonContainer>
            {module.isFirstCourse && !trailAlreadyStarted && !ableToExam && (
              <TrailCourseStartButton onClick={startTrail}>
                <span>Iniciar Trilha</span> <BsPlay size={24} />
              </TrailCourseStartButton>
            )}
            {module.isFirstCourse && !trailAlreadyStarted && ableToExam && (
              <Modal
                open={ableToExam && visible}
                setVisibility={() => {
                  setShowModal(!showModal);
                }}
              >
                <div>
                  <span>{trail.category}</span>
                  <h2 style={{ wordBreak: 'break-word' }}>{trail.name}</h2>
                </div>
                <ModalText>
                  Para iniciar a trilha é necessário realizar uma pequena
                  avaliação, ao concluir a avaliação, a trilha poderá ser
                  iniciada.
                </ModalText>
                <BtnGroup>
                  <PrimaryButton onClick={startExam}>
                    Iniciar Avaliação
                  </PrimaryButton>
                </BtnGroup>
              </Modal>
            )}

            {module.isFirstCourse && trailAlreadyFinished && ableToExam ? (
              <Modal
                open={ableToExam && visible && showModal}
                setVisibility={() => {
                  setShowModal(!showModal);
                }}
              >
                <div>
                  <span>{trail.category}</span>
                  <h2 style={{ wordBreak: 'break-word' }}>{trail.name}</h2>
                </div>

                <ModalText>
                  {trail.info.no_certificate
                    ? 'Meus parabéns, você chegou ao fim desta trilha! Realize uma rápida avaliação sobre o que aprendeu.'
                    : 'Meus parabéns, você chegou ao fim desta trilha! Para receber seu certificado, você precisa realizar uma rápida avaliação sobre o que aprendeu.'}
                </ModalText>

                <BtnGroup>
                  <PrimaryButton onClick={startExam}>
                    Iniciar Avaliação
                  </PrimaryButton>
                  <SecondaryButton
                    onClick={() => {
                      setShowModal(!showModal);
                    }}
                  >
                    Fazer Depois
                  </SecondaryButton>
                </BtnGroup>
              </Modal>
            ) : ((userIsApproved && !trail.info?.no_certificate) || (!trail.info?.no_certificate && !trail?.exam && trail?.alreadyFinished)) && module.isFirstCourse ? (
              <TrailDownloadCertificate
                onClick={() => downloadCertificate(trail.id, true)}
              >
                <span>Baixar Certificado</span> <AiOutlineDownload size={24} />
              </TrailDownloadCertificate>
            ) : (
              <></>
            )}

            {!ableToExam &&
              nextAttemptsDate &&
              isBefore(new Date(), parseISO(nextAttemptsDate)) && (
                <TrailStartExam
                  onClick={() =>
                    toast.error(
                      `Você receberá novas tentativas no dia ${format(
                        parseISO(nextAttemptsDate),
                        "dd/MM/yyyy 'às' HH:mm",
                      )}.`,
                    )
                  }
                >
                  <span>Responder Questionário</span>{' '}
                  <BsFillAwardFill size={24} />
                </TrailStartExam>
              )}
          </TrailCourseStartButtonContainer>

          {module.contents && module.contents.length ? (
            module.contents.map(content => (
              <TrailModuleContent
                key={content.id}
                content={content}
                isSelected={checkIfIsSelected(content)}
                trailAlreadyStarted={trailAlreadyStarted}
              />
            ))
          ) : (
            <></>
          )}
        </TrailCourseLowerContent>
      )}
      {showModuleExam ? (
        module.alreadyFinished && moduleInfo.exam_id ? (
          <QuizContainer
            onClick={startQuiz}
            className={`${showModuleExam ? 'active' : ''}`}
          >
            <div>
              <strong>
                Para prosseguir clique aqui e responda ao questionário
              </strong>
              <BsFillAwardFill size={24} />
            </div>
          </QuizContainer>
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </TrailCourseContainer>
  );
};

export default TrailModule;
