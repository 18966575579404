import axios from 'axios';
import { toast } from 'react-toastify';

interface IVerifyCode {
  code: string;
  registration: string;
}

interface IResendCode {
  phone_number: string;
}

const verifyCode = async ({
  code,
  registration,
}: IVerifyCode): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/verify-code`, {
        registration: registration,
        code: code,
      })
      .then(response => {
        toast.success(
          'Cadastro validado com sucesso, Você já pode realizar seu login!',
        );
      });
    return Promise.resolve();
  } catch (err) {
    if (
      err.response.data.message ===
      'Já existe uma conta com o mesmo número de telefone'
    ) {
      toast.error('Já existe uma conta com o mesmo número de telefone.');
    } else {
      toast.error(
        'O código informado está incorreto. Por favor, informe um código válido.',
      );
    }

    return Promise.reject();
  }
};

const resendCode = async ({ phone_number }: IResendCode): Promise<void> => {
  try {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cognito/resend-code`, {
        phone_number,
      })
      .then(response => {
        toast.success('Código reenviado com sucesso!');
      });
  } catch (err) {
    toast.error('Erro ao reenviar o código. Por favor, tente novamente.');

    return Promise.reject();
  }
};

export { verifyCode, resendCode };
