import ExtraMaterial from '../models/extra-material';
import ExtraMaterialFromResponse from '../models/from-api/extra-material';

const convertExtraMaterial = (
  extraMaterial: ExtraMaterialFromResponse,
): ExtraMaterial => {
  return {
    id: extraMaterial.extra_material_id,
    name: extraMaterial.name,
    reference: extraMaterial.reference,
    thumbnail: extraMaterial.thumbnail,
    created_at: extraMaterial.created_at,
    info: extraMaterial.info,
  };
};

export default convertExtraMaterial;
