import React, { useEffect, useState } from 'react';
import { HomeContainer } from './style';
import Depositions from './components/Depositions';
import SuggestedCourses from './components/SuggestedCourses';
import RecentelyCourses from './components/RecentelyCourses';
import MoreWatchedCourses from './components/MoreWatchedCourses';
import Banner from './components/Banner';
import PreFooter from '../../components/PreFooter';
import TermModal from '../../components/TermModal';

const Home: React.FC = () => {
  const [userHasAccepted, setUserHasAccepted] = useState(false);
  const [isLogged, setIsLogged] = useState(
    !!localStorage.getItem('telhanorte-user'),
  );

  useEffect(() => {
    const localUser = localStorage.getItem('telhanorte-user');

    if (localUser) {
      const parsedLocalUser = JSON.parse(localUser);

      if (
        parsedLocalUser &&
        parsedLocalUser.info &&
        parsedLocalUser.info.accept_conditions
      ) {
        setUserHasAccepted(!!parsedLocalUser.info.accept_conditions);
      } else {
        setUserHasAccepted(false);
      }
    }
  }, []);

  return (
    <HomeContainer>
      <div className="content">
        {!userHasAccepted && isLogged && (
          <TermModal
            userHasAccepted={userHasAccepted}
            setUserHasAccepted={setUserHasAccepted}
          />
        )}
        <Banner />
        <SuggestedCourses />
        <RecentelyCourses />
        <MoreWatchedCourses />
        <Depositions />
      </div>
      <PreFooter />
    </HomeContainer>
  );
};

export default Home;
