import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AiOutlineClockCircle,
  AiOutlineLike,
  AiFillLike,
} from 'react-icons/ai';
import { BsBookmark } from 'react-icons/bs';

import Trail from '../../models/trail';
import { addBookMark, removeBookMark } from '../../services/bookmark';

import CourseImage from '../../assets/banner-image.png';

import {
  Container,
  Trailfooter,
  TrailTime,
  TrailLikes,
  BookmarkButton,
} from './style';

import { toast } from 'react-toastify';
import { secondsToTimeText } from '../../helpers/time';
import { Rating } from 'react-simple-star-rating';
import { average, evaluationGrade } from '../../utils/getReviews';
import { getTrailUserReviews } from '../../services/trail-review';

interface ITrailProps {
  trail: Trail;
  onAddToList?: () => void;
}

const TrailCard: React.FC<ITrailProps> = ({ trail, onAddToList }) => {
  const [addedToList, setAddedToList] = useState(trail.addedToList);
  const [addedToLike, setAddedToLike] = useState(trail.userLiked);
  const [rating, setRating] = useState(0);

  const history = useHistory();

  const goToTrail = () => {
    history.push(`/trails/${trail.id}`);
  };

  const addToList = async (event: React.MouseEvent) => {
    event.stopPropagation();

    try {
      const shouldAddToList = !trail.addedToList;

      if (shouldAddToList) {
        await addBookMark(trail.id);
      } else {
        await removeBookMark(trail.id);
      }

      if (!!onAddToList) {
        onAddToList();
      }

      trail.addedToList = shouldAddToList;
      setAddedToList(trail.addedToList);
    } catch (e) {
      toast.error('Erro ao adicionar curso aos favoritos.');
    }
  };

  const reviews = useCallback(async () => {
    const allReviews = await getTrailUserReviews(trail.id);
    const qtdReviews = allReviews.length;
    const averageOfStarts = average(allReviews, qtdReviews);
    const stars = evaluationGrade(averageOfStarts) || 0;

    setRating(stars);

  },[trail.id]);

  useEffect(() => {
    reviews();
  },[reviews]);

  return (
    <Container onClick={goToTrail}>
      <div>
        <img src={trail.thumbnail} alt="Trail" />
        <Rating
            ratingValue={rating}
            fillColorArray={[
              '#f17a45',
              '#f19745',
              '#f1a545',
              '#f1b345',
              '#f1d045',
            ]}
            size={20}
            allowHalfIcon
            readonly
          />

        <h4>{trail.name}</h4>

        <BookmarkButton onClick={e => addToList(e)}>
          <BsBookmark
            size={24}
            color="#FFF"
            className={`${addedToList && 'favorite'}`}
          />
        </BookmarkButton>
      </div>

      <Trailfooter>
        <TrailTime>
          <AiOutlineClockCircle />
          <span>{`${secondsToTimeText(trail.duration)}`}</span>
        </TrailTime>

        <span className="divider" />

        <TrailLikes>
          {addedToLike ? (
            <AiFillLike color="#555" />
          ) : (
            <AiOutlineLike color="#555" />
          )}

          <span>
            {trail.numberOfLikes &&
              `${trail.numberOfLikes} ${
                trail.numberOfLikes === 1 ? 'curtida' : 'curtidas'
              }`}
          </span>
        </TrailLikes>
      </Trailfooter>
    </Container>
  );
};

export default TrailCard;
