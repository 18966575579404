const baneerSliderSettings = {
  infinite: true,
  speed: 3000,
  slidesToShow: 1.2,
  slidesToScroll: 1,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
};

const depositionsSliderSettings = {
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: false,
  draggable: false,
  swipeToSlide: false,
  speed: 1500,
  responsive: [
    {
      breakpoint: 1240,
      settings: {
        slidesToShow: 2.8,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1160,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1030,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 880,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2.2,
        slidesToScroll: 1,
        speed: 500,
        arrows: false,
      },
    },
    {
      breakpoint: 660,
      settings: {
        arrows: false,
        slidesToShow: 1.6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 520,
      settings: {
        arrows: false,
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 470,
      settings: {
        arrows: false,
        slidesToShow: 1.3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 410,
      settings: {
        arrows: false,
        slidesToShow: 1.08,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 340,
      settings: {
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const coursesSliderSettings = {
  slidesToShow: 4.5,
  slidesToScroll: 4,
  infinite: false,
  draggable: false,
  swipeToSlide: false,
  speed: 1500,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3.1,
        slidesToScroll: 2,
        speed: 500,
        arrows: false,
      },
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 440,
      settings: {
        slidesToShow: 1.8,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 390,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
        arrows: false,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        arrows: false,
      },
    },
  ],
};

export {
  baneerSliderSettings,
  depositionsSliderSettings,
  coursesSliderSettings,
};
