import React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Container, ProfileLinks, ExitButton } from './style';

const ProfileDropdown: React.FC = () => {
  const history = useHistory();

  const logout = () => {
    window.localStorage.removeItem('telhanorte-api-token');
    window.localStorage.removeItem('telhanorte-user');
    history.push('/');
    document.location.reload();
  };

  return (
    <Container>
      <ProfileLinks to="/profile">Meus dados</ProfileLinks>

      <ProfileLinks to="/ongoing-courses">Em andamento</ProfileLinks>

      <ProfileLinks to="/favorite-courses">Favoritos</ProfileLinks>

      <ProfileLinks to="/finished-contents-and-trails">
        Finalizados
      </ProfileLinks>

      <ProfileLinks to="/certificates">Meus Certificados</ProfileLinks>
      <ProfileLinks to="/uploaded-files">Arquivos enviados</ProfileLinks>

      <ExitButton type="button" onClick={logout}>
        <FiLogOut size={16} color="#54006E" /> Sair
      </ExitButton>
    </Container>
  );
};

export default ProfileDropdown;
