import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import Swal from 'sweetalert2';
import DefaultButton from '../../../../components/Buttons/DefaultButton';
import DefaultTable from '../../../../components/DefaultTable';
import formatDataFromString from '../../../../helpers/convert-date';
import UserFile from '../../../../models/user-file';
import {
  deleteUploadedFile,
  getUploadedFiles,
} from '../../../../services/user-file';
import PostListLoader from '../../../Forum/components/PostListLoader';
import ProfileMenu from '../../components/ProfileMenu';
import { Container, UploadedFilesContainer, UploadedFilesList } from './style';

const UploadedFiles: React.FC = () => {
  const [allUploadedFiles, setAllUploadedFiles] = useState<UserFile[]>();
  const [isLoading, setIsLoading] = useState(true);

  const getAllUploadedFiles = useCallback(async () => {
    setIsLoading(true);
    const data = await getUploadedFiles();
    setAllUploadedFiles(data);
    setIsLoading(false);
  }, []);

  const deleteFile = useCallback(async (fileId: string) => {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja deletar este arquivo?`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      focusConfirm: false,
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteUploadedFile(fileId);
          getAllUploadedFiles();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: `Erro ao deletar o arquivo` + error.message,
          });
        }
      }
    });
  }, []);

  const uploadedFilesToBeShow = useMemo(() => {
    return allUploadedFiles
      ? allUploadedFiles.map(file => ({
          title: (
            <a
              style={{ textDecoration: 'none', color: '#54006e' }}
              href={file.file_reference}
            >
              {file.file_name}
            </a>
          ),
          course: file.trail_name ? file.trail_name : file.content_name,
          date: formatDataFromString(file.created_at),
          status:
            file.file_situation === 'pending'
              ? 'Pendente'
              : file.file_situation === 'approved'
              ? 'Aprovado'
              : 'Reprovado',
          actions: (
            <DefaultButton onClick={() => deleteFile(file.user_file_id)}>
              <AiOutlineDelete />
            </DefaultButton>
          ),
        }))
      : [];
  }, [allUploadedFiles, deleteFile]);

  useEffect(() => {
    getAllUploadedFiles();
  }, []);

  return (
    <Container className="content">
      <ProfileMenu />
      <UploadedFilesContainer>
        <h2>Arquivos enviados</h2>
        <UploadedFilesList>
          {isLoading ? (
            <PostListLoader />
          ) : (
            <DefaultTable
              headersConfig={[
                {
                  headerLabel: <span>Arquivo enviado</span>,
                  propName: 'title',
                  attributes: { style: { width: '20%' } },
                },
                {
                  headerLabel: <span>Trilha/Curso</span>,
                  propName: 'course',
                  attributes: { style: { width: '15%' } },
                },
                {
                  headerLabel: <span>Data de envio</span>,
                  propName: 'date',
                  attributes: { style: { width: '10%' } },
                },
                {
                  headerLabel: <span>Situação</span>,
                  propName: 'status',
                  attributes: { style: { textAlign: 'center', width: '10%' } },
                },
                {
                  headerLabel: <span>Deletar</span>,
                  propName: 'actions',
                  attributes: { style: { width: '10%' } },
                },
              ]}
              items={uploadedFilesToBeShow}
              emptyListMessage={'Não foram encontrados arquivos enviadas!'}
            />
          )}
        </UploadedFilesList>
      </UploadedFilesContainer>
    </Container>
  );
};

export default UploadedFiles;
