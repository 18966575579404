import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import SliderNextButton from '../../../../components/SliderButtons/SliderNextButton';
import SliderPrevButton from '../../../../components/SliderButtons/SliderPrevButton';
import CourseCardItem from '../../../../components/CourseCardItem';

import { coursesSliderSettings } from '../../../../config/sliderSettings';

import { Container } from './style';
import { toast } from 'react-toastify';
import { getContents } from '../../../../services/content';
import Content from '../../../../models/content';
import ModalSkeleton from '../Skeleton/Cards';

const SuggestedCourses: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [suggestedCourses, setSuggestedCoursesCourses] = useState<Content[]>(
    [],
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const suggestedCourses = { is_library: true, recommended : true };
        const localCourses = await getContents(suggestedCourses);

        if (localCourses && localCourses.length) {
          setSuggestedCoursesCourses(localCourses);
        }
      } catch (e) {
        toast.error('Erro ao carregar os cursos sugeridos.');
      }
      setIsLoading(false);
    })();
  }, []);

  return (
    <Container>
      <h2>Cursos sugeridos para você</h2>

      <Slider
        {...coursesSliderSettings}
        prevArrow={<SliderPrevButton />}
        nextArrow={<SliderNextButton />}
      >
        {isLoading ? (
          <ModalSkeleton />
        ) : suggestedCourses && suggestedCourses.length ? (
          suggestedCourses.map(course => (
            <CourseCardItem key={course.id} course={course} />
          ))
        ) : (
          <h4 style={{ width: '100%', padding: '15px' }}>
            Não foram encontrados cursos sugeridos.
          </h4>
        )}
      </Slider>
    </Container>
  );
};

export default SuggestedCourses;
