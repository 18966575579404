import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.nav`
  min-width: 220px;
  background-color: var(--backgroundWhite);
  box-shadow: 1px 1px 14px 2px rgba(0, 0, 0, 0.15);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.15));
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  position: absolute;
  right: -32px;
  top: 96px;
  padding: 28px;
  z-index: 2;

  &::before {
    content: '';
    border-style: solid;

    border-color: #fff transparent;
    border-width: 0px 8px 8px 8px;
    position: absolute;
    bottom: 100%;
    left: 70%;
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ProfileLinks = styled(Link)`
  text-decoration: none;

  font-size: var(--font-medium);
  color: var(--dark-gray);

  & + a {
    margin-top: 28px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const ExitButton = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: var(--font-medium);
  color: var(--primary-color);
  background: var(--light-gray);
  border: none;
  border-radius: 30px;

  max-width: 96px;
  margin-top: 38px;
  padding: 8px 20px;
  align-self: center;

  svg {
    margin: 0 12px 4px 0;
  }
`;
